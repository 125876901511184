import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateUser } from '../../../app/slices/user.slice';
import userService from '../../../api/services/userService';

import { Formik, Form, Field } from 'formik';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, TextField, Button, Autocomplete, Chip } from '@mui/material';
import EditSectionHeader from '../EditSectionHeader';
import { PersonalityForm } from '../../forms/createFormComponent';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    black: '#121212',
  },
});

function EditPersonality({ setEdit }) {
  const mobileLayout = useSelector(state => state.global.isMobile);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const initialValues = {
    personalityType: user.personality.personalityType || '',
    introExtroVert: user.personality.introExtroVert || [],
    morningNight: user.personality.morningNight || [],
    favoriteEmojis: user.personality.favoriteEmojis || [],
    relationshipIntent: user.personality.relationshipIntent || '',
    friendIntent: user.personality.friendIntent || '',
    idealFD8: user.personality.idealFD8 || [],
    idealRD8: user.personality.idealRD8 || [],
    coreValues: user.personality.coreValues || [],
  };

  const [formValues, setFormValues] = useState(initialValues);

  const handleSave = async (values) => {
    const personality = values
    try {
      const response = await userService.modifyUserInfo({ personality });

      if (response) {
        dispatch(updateUser({ ...user, personality }));
      }

      setEdit(null);
    } catch (error) {
      console.error('Error updating personality info:', error);
    }
  };

  const handleSubmit = (values) => {
    setFormValues(values);
  };

  return (
    <Box className={`profile-edit${mobileLayout ? '-mobile' : ""}`}>
      <PersonalityForm 
        initialValues={formValues} 
        handleSave={handleSave} 
        onSubmit={handleSubmit}
        isEditMode={true} 
        setEdit={setEdit}
        margin={"normal"}
      />
    </Box>
  );
}

export default EditPersonality;
