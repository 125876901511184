export const imageStyle = {
    borderRadius: '12px',
    pointerEvents: 'none'
}

export const slideStyle = {
    height: '550px',
    width: '700px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

}   
export const slideStyle_mobile = {

    height: '600px',
    width: 'inherit',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}