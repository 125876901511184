import React, { useState, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';

const filter = createFilterOptions();

const CustomSingleAutocomplete = ({ fieldConfig, values, setFieldValue, margin }) => {
  const [options, setOptions] = useState([]);

  const handleDynamicSearch = debounce(async (searchTerm) => {
    if (fieldConfig.fetchOptions) {
      const data = await fieldConfig.fetchOptions(searchTerm);
      setOptions(data);
    }
  }, 300);

  useEffect(() => {
    if (fieldConfig.options) {
      setOptions(fieldConfig.options);
    } else if (fieldConfig.fetchOptions) {
      handleDynamicSearch('');
    }
  }, []);

  return (
    <Autocomplete
      value={values[fieldConfig.name] || ''}
      onChange={(event, newValue) => {
        setFieldValue(fieldConfig.name, newValue);
      }}
      onInputChange={(event, value) => fieldConfig.fetchOptions ? handleDynamicSearch(value) : fieldConfig.options ? fieldConfig.options : null}
      /* filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
      
        const isExisting = options.includes(inputValue);
        if (inputValue !== '' && !isExisting) {
          filtered.push(inputValue);
        }
      
        return filtered;
      }} */
      options={options}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => <li {...props}>{option}</li>}
      //freeSolo
      renderInput={(params) => (
        <TextField {...params} label={fieldConfig.label} margin="normal" size="small" fullWidth />
      )}
    />
  );
};

export default CustomSingleAutocomplete;
