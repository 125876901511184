// React and Redux essentials
import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI components
import { 
    Button, 
    IconButton,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Menu,
    MenuItem,
    Snackbar,
    SnackbarContent,
} from "@mui/material";

// MUI icons
import PersonIcon from '@mui/icons-material/Person';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import CloseIcon from '@mui/icons-material/Close';

// Backend service call
import userService from "api/services/userService";
import groupChatService from "api/services/groupchatService";

// Custom styles
import { dialogButtonRegular } from "styles/dialogButtonStyles";
import { dialogButtonFocus } from "styles/dialogButtonStyles";

// Custom scripts
import { delay } from "scripts/delay";
import { Person } from "@mui/icons-material";


export default function AddNewMemberDialog() {
  const navigate = useNavigate();

  const groupChat = useSelector(state => state.groupchats.groupChatDetail);
  const categories = useSelector(state => state.chats.categories);

  // Snackbar states
  const [snackbarShow, setSnackbarShow] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('No message');

  // Dropdown handles
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  // Add Member dialog handlers & category/user list states
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false);
  const [categoryListVisible, setCategoryListVisible] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [userList, setUserList] = useState([]);
  const handleAddMemberClick = () => {
    handleClose();
    setAddMemberDialogOpen(true);
  };
  const handleAddMemberClose = async () => {
    setAddMemberDialogOpen(false);
    await delay(100);
    setSelectedCategoryId(null);
    setUserList([]);
    setCategoryListVisible(true);
  };

  // Redirect to Search page handler
  const handleSearchForM8Click = () => {
    navigate('/search');
  }

  // Add Member dialog back button
  const handleBackButtonClick = () => {
    setCategoryListVisible(true);
    setSelectedCategoryId(null);
  }

  // Add Member item click handle
  const handleMemberListItemClick = async (friendId) => {
    await groupChatService.addUserToGroupChat({ groupChatId: groupChat._id, userId: friendId })
    .then(res => {
        if (res.status === 200) {
            setSnackbarMessage("Invite sent to M8");
            setSnackbarShow(true);
        }
        if (res.status === 204) {
            setSnackbarMessage("M8 already in group or invited");
            setSnackbarShow(true);
        }
        setAddMemberDialogOpen(false);
    })
    .catch(error => {
        void(0);
    })
}

  /* Add/Invite members button */
  const AddNewMemberButton = (
    <IconButton onClick={handleClick}>
      <GroupAddRoundedIcon sx={{ color: 'var(--color-main)', mr: 1 }}/>
    </IconButton>
  )

  // Update user list on category select.
  const updateUserList = async () => {
    userService.getUserInfosByCategoryId({ categoryId: selectedCategoryId })
    .then((obtainedUserList) => {
      setUserList(obtainedUserList);
      setCategoryListVisible(false);
    })
    .catch((error) => {
      //console.log(error)
      void(0);
    })
  };
  useEffect(() => {
    if (selectedCategoryId !== null) {
      updateUserList();
    }
  },[selectedCategoryId])
  // Categegory click handler
  const handleCategoryClick = (categoryId) => {
    setSelectedCategoryId(categoryId); 
  };

  /* Action menu */
  // Action menu options 
  const moreActionMenuOptions = [
    {text: "Invite from Chats", onClick: handleAddMemberClick, icon: <FormatListBulletedRoundedIcon fontSize="inherit"/>},
    {text: "Search M8s to invite", onClick: handleSearchForM8Click, icon: <PersonSearchRoundedIcon fontSize="inherit"/>},
  ]
  // Action menu element
  const AddNewMemberMenu = (
      <Menu
      id="chat-main-menu"
      anchorEl={anchorEl}
      elevation={1}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      >
          {moreActionMenuOptions.map(option => (
            <MenuItem onClick={option.onClick}>
              {option.icon}&nbsp;&nbsp;{option.text}
            </MenuItem>
          ))}
      </Menu>
  )

  /* Add Member dialog */
  const AddNewMemberDialog = (
    <Dialog open={addMemberDialogOpen} onClose={handleAddMemberClose}>
      <Box sx={{ width: "500px", mt: 1}}>
        <DialogTitle sx={{ fontSize: "1.1rem", fontWeight: 'bold'}}>
          Invite M8 to group
        </DialogTitle>

        <DialogContent sx={{mt: 1}}>
          <DialogContentText id="alert-dialog-description">
            {categoryListVisible ? 
              "Select a category..." 
            : 
              userList.length > 0 ? 
                "Choose a M8 to invite..." 
              : 
                "No M8s in category"
            }
          </DialogContentText>
        </DialogContent>

        <List sx={{ pt: 0, width: "100%" }}>
          {categoryListVisible && Object.values(categories).map((category) => (
            <ListItem disableGutters key={category._id}>
              <ListItemButton onClick={() => handleCategoryClick(category._id)} sx={{ padding: "0 24px" }}>
                <ListItemAvatar>
                  <Avatar /*variant='rounded'*/ sx={{ width: 30, height: 30 }}> 
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={category.name} />
              </ListItemButton>
            </ListItem>
          ))}
          {!categoryListVisible && userList.length > 0 && userList.map((user) => (
            <ListItem disableGutters key={user._id}>
              <ListItemButton onClick={() => { handleMemberListItemClick(user.userId) }} sx={{ padding: "0 24px" }}>
                <ListItemAvatar>
                  <Avatar src={user.images.length > 0 ? user.images[0].mediaUrl : ""} alt={user.name} sx={{ width: 30, height: 30 }}> 
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <DialogActions>
          {!categoryListVisible && 
          <Button size="small" onClick={handleBackButtonClick} sx={dialogButtonRegular}>
            Back
          </Button>
          }
          <Button size="small" onClick={handleAddMemberClose} sx={dialogButtonFocus}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )

  /* Add to group status (snackbar) */
  const AddNewMemberSnackbarAction = (
    <Fragment>
      {/*
      <Button color="secondary" size="small" onClick={() => {setSnackbarShow(false)}}>
        CLOSE
      </Button>
      */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {setSnackbarShow(false)}}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  )
  const AddNewMemberSnackbar = (
      <Snackbar
          open={snackbarShow}
          autoHideDuration={1500}
          onClose={() => {setSnackbarShow(false)}}
          
      >
          <SnackbarContent 
              style={{
                  backgroundColor:'var(--color-accent)',
                  color:'var(--color-text-invert)',
              }}
              message={snackbarMessage}
              action={AddNewMemberSnackbarAction}
          />
      </Snackbar>
  )

  return (<>
    {AddNewMemberButton}
    {AddNewMemberMenu}

    {AddNewMemberDialog}

    {AddNewMemberSnackbar}
  </>)
}