import { delay } from "scripts/delay";

let slideIndex = 1;

// Show one slide n (Ex. Slide 1, slide 2....)
export async function showSlide(n) {
    let i;
    let slides = document.getElementsByClassName("popup-slide"); if (slides.length < 1) {return void(0)}
    let dots = document.getElementsByClassName("dot"); if (dots.length < 1) {return void(0)}
    if (n > slides.length) {slideIndex = 1}    
    if (n < 1) {slideIndex = slides.length}

    // Set slide opacity first
    for (i = 0; i < slides.length; i++) {
        slides[i].style.opacity = "0";
    }
    for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }

    // Wait for opacity transition to finish
    await delay(300);

    // After transition, make display none
    for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }

    // Make new slide display block, then transition opacity 
    slides[slideIndex-1].style.display= "block";
    await delay(100); // For some reason opacity transition is abrupt. Add delay here fixes it.
    slides[slideIndex-1].style.opacity = "1";
    dots[slideIndex-1].className += " active";
}

export function moveToSlide(n) {
    showSlide(slideIndex += n);
}

export function resetSlide() {
    slideIndex = 1;
    
}