// React, Redux, DOM essentials
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";

// MUI componenets
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

// MUI icons
import EmailIcon from '@mui/icons-material/Email';

// Redux store management
import { updateReferredCount } from 'app/slices/user.slice';

// Components
import { SectionContainer, SectionTitle, InfoItem } from 'components/userProfile/StyledComponents';

// Backend service calls
import userService from 'api/services/userService';



export default function ReferralPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [referralParams] = useSearchParams();

    const user = useSelector((state) => state.user);
    const { referredCount, referralCode} = user;

    // Snackbar (bottom left notification box) visibility control 
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // Copy referral link w/ code
    const copyCode = () => {
        let copyReferralLink = window.location.host + `/refer?referralID=` + referralCode;
        navigator.clipboard.writeText(copyReferralLink);
      
        handleClick()
        setTimeout(() => {
            handleClose()
        }, 6000); // Set snackbar auto hide timeout
    }

    // Referred count update
    const updateReferred = async () => {
        await userService.getReferredUserCount({referralCode: user.referralCode})
        .then((res) => {
            dispatch(updateReferredCount(res.data.count));
        });
    }
    useEffect(() => {
        updateReferred();
    },[])

    return (
        <Box sx={{
            position: 'fixed', 
            top: 0, 
            left: 0, 
            width: '100vw', 
            height: '100vh', 
            display: 'flex', 
            justifyContent: 'center', 
            alignContent: 'center',
            flexDirection: 'column',
            textAlign: 'center'
            }}
        >
            {/* Email icon */}
            <Box sx={{fontSize: 40}}>
                <EmailIcon fontSize='inherit'/>
            </Box>

            {/* Title */}
            <Typography variant="h5" fontWeight={'bold'} mb={0}>Referral</Typography>
            {/* Description */}
            <Typography sx={{width: '100vw'}} mb={2}>
                Invite friends, family, followers, fans to join M8!
            </Typography>

            {/* Total referral */}
            <Box >
                <Typography variant="body1" mb={5}><b>M8s Referred:</b> {referredCount}</Typography>
            </Box>

            <Box sx={{width: '30px', opacity: 0.2, mx: 'auto', mb: 5}}>
                <Divider orientation="horizontal" sx={{width: '100%', border: '2px var(--color-accent) solid', borderRadius: '999px'}} flexItem/>
            </Box>

            <Box>
                <Typography variant="body2" mb={1}>Referral Code: <b>{referralCode}</b></Typography>
                <Button 
                    variant="outlined" 
                    size="small" 
                    onClick={copyCode}
                    sx={{ 
                        backgroundColor: 'var(--color-main)', 
                        '&:hover': {
                            backgroundColor: 'var(--color-accent)',
                            borderColor: 'var(--color-accent)',
          
                            color: 'var(--color-main)',
                        },
                        borderColor: 'var(--color-accent)',
                        borderRadius: '9999px',
                        color: 'var(--color-accent)',
                        textTransform: 'none'
                      }}
                >
                    Click here to copy referral link
                </Button>
            </Box>
            <Snackbar
                open={open}
                //autoHideDuration={6000} Defined in copyCode()
                onClose={handleClose}
                message="Referral URL copied to clipboard"
            />
        </Box>
    )
}
