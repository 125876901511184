import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Formik, Form } from 'formik';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, TextField, Button, Autocomplete, Chip } from '@mui/material';
import EditSectionHeader from '../EditSectionHeader';
import { TheTeaForm } from '../../forms/createFormComponent';

import userService from '../../../api/services/userService';
import { updateUser } from '../../../app/slices/user.slice';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    black: '#121212',
  },
});

function EditTheTea({ setEdit }) {
  const mobileLayout = useSelector(state => state.global.isMobile);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const initialValues = {
    interests: user.theTea.interests || [],
    drugsAlcohol: user.theTea.drugsAlcohol || '',
    music: user.theTea.music || [],
    tvMovies: user.theTea.tvMovies || [],
    comedians: user.theTea.comedians || [],
    sportsTeams: user.theTea.sportsTeams || [],
    citiesVisitedLived: user.theTea.citiesVisitedLived || [],
    politics: user.theTea.politics || '',
    sexuality: user.theTea.sexuality || '',
    roommatePreference: user.theTea.roommatePreference || '',
  };

  const [formValues, setFormValues] = useState(initialValues);

  const handleSave = async (values) => {
    try {
      const response = await userService.modifyUserInfo({ theTea: values });

      if (response) {
        dispatch(updateUser({ ...user, theTea: values }));
      }

      setEdit(null);
    } catch (error) {
      console.error('Error updating the tea info:', error);
    }
  };

  const handleSubmit = (values) => {
    setFormValues(values);
  };

  return (
    <Box className={`profile-edit${mobileLayout ? '-mobile' : ""}`}>
      <TheTeaForm 
        initialValues={formValues} 
        handleSave={handleSave} 
        onSubmit={handleSubmit}
        isEditMode={true} 
        setEdit={setEdit}
        margin={"normal"}
      />
    </Box>
  );
}

export default EditTheTea;
