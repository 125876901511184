import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, TextField, Button, Typography, Box, Modal, Backdrop, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import '../../../App.css';
import './PopupSlideshow.css';

import { showSlide, moveToSlide, resetSlide } from './slideshowController';
import { delay } from 'scripts/delay';


const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
});


export default function PopupSlideshow({content, visible, setVisible, backdrop, association}) {
    // Mobile layout state
    const mobileLayout = useSelector(state => state.global.isMobile);
    
    // For working around the issue where all slides appear at once on first component render.
    // On first render, all slides appear even with showSlide(), so we render and call showSlide() again to show the first slide as intended.
    const [refresh, setRefresh] = useState(1);
    useEffect(() => {
        if (visible === true) { // Only set refresh state when popup is visible. Other style error occurs
            
            setRefresh(1);
        }
    },[visible])
    useEffect((e) => {
        refresh === 1 && setRefresh(2);
        refresh === 2 && showSlide(1);
    },[refresh])

    // Popup style
    const popupStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: mobileLayout ? '80vh' : '615px',
        overflowY: 'auto', // Enable vertical scrolling
        maxWidth: mobileLayout ? 300 : 'fit-content',
        bgcolor: '#fff',
        border: '2px solid #000',
        borderRadius: '12px',
        boxShadow: 24,
        p: 4,
        position: 'relative',
    }

    const handleClose = () => {
        setVisible(false);
        if (association !== undefined) {
            localStorage.removeItem(association);
        }
        
    }

    return (
    <ThemeProvider theme={lightTheme}>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={visible}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            hideBackdrop={backdrop ? false : true}
        >
            <Fade in={visible}>
                <Box sx={popupStyle}>

                    {/*Close button (top right)
                        <Box sx={{ position: 'fixed', top: 10, right: 10, zIndex: 1 }}>
                            <Button
                                onClick={() => setopenPopup(false)}
                                sx={{
                                    fontSize: '30px', 
                                    fontWeight: 'bold', 
                                    color: 'var(--color-accent)', 
                                    opacity: 0.3,
                                    "&:hover": {
                                        opacity: 1
                                    }
                                }}
                            >
                                <CloseIcon fontSize='inherit'/>
                            </Button>

                        </Box>
                    */}

                    {/* Slide navigation */}
                    <Box sx={{ position: 'fixed', top: "50%", transform: "translateY(-50%)", left: 10, zIndex: 1 }}>
                        <Button
                            onClick={() => moveToSlide(-1)}
                            sx={
                                mobileLayout ?
                                {
                                    fontSize: '20px', 
                                    fontWeight: 'bold', 
                                    color: 'black', 

                                    transition: '0.3s',

                                    opacity: '0.2',
                                    '&:active': {
                                        opacity: '1'
                                    },
                                }
                                :
                                {
                                    fontSize: '20px', 
                                    fontWeight: 'bold', 
                                    color: 'black', 

                                    transition: '0.3s',

                                    opacity: '0.2',
                                    '&:hover': {
                                        opacity: '1'
                                    },
                            }}
                        >
                            ◀
                        </Button>

                    </Box>
                    <Box sx={{ position: 'fixed', top: "50%", transform: "translateY(-50%)", right: 10, zIndex: 1 }}>
                        <Button
                            onClick={() => moveToSlide(1)}
                            sx={
                                mobileLayout ?
                                {
                                    fontSize: '20px', 
                                    fontWeight: 'bold', 
                                    color: 'black', 

                                    transition: '0.3s',

                                    opacity: '0.2',
                                    '&:active': {
                                        opacity: '1'
                                    },
                                }
                                :
                                {
                                    fontSize: '20px', 
                                    fontWeight: 'bold', 
                                    color: 'black', 

                                    transition: '0.3s',

                                    opacity: '0.2',
                                    '&:hover': {
                                        opacity: '1'
                                    },
                            }}
                        >
                            ▶
                        </Button>

                    </Box>

                    {/* Display content */}
                    {content}
                </Box>
            </Fade>
        </Modal>

    </ThemeProvider>
    )
}