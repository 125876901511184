// React and Redux essentials
import React, { useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI components
import {
  Box,
  Avatar,
  AvatarGroup,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ButtonBase
} from "@mui/material";

// MUI icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Components
import AddNewMemberDialog from "./dialogs/AddNewMemberDialog";
import GroupMemberList from "./dialogs/GroupMemberList";

// Redux state management 
import {
  setGroupChatSessionId
} from 'app/slices/groupchat.slice'

// Custom styles
import { ThemeProvider, createTheme } from "@mui/material";
import { rippleEffectOverrides } from "styles/MUIOverriders/rippleEffectOverrides";

// Custom scripts
import { delay } from "scripts/delay";

const customTheme = createTheme({
  components: {
    ...rippleEffectOverrides,
  },
});

export default function GroupChatHeader({ groupChat, groupChatMembers }) {
  const mobileLayout = useSelector(state => state.global.isMobile);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Dropdown handles
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /* Group Chat avatar and name */
  const GroupChatInfo = (
    <Box sx={ { display: "flex", alignItems: "center", color: "white" } } >
      {/* Back to chat list button for mobile */ }
      { mobileLayout && // Show close chat button for mobile only
        <IconButton onClick={ () => dispatch(setGroupChatSessionId(null)) } >
          <CloseRoundedIcon fontSize='small' sx={ { ml: 1, color: '#b3b3b3' } } />
        </IconButton>
      }
      {/* Avatar */ }
      <Avatar
        sx={ { height: "34px", width: "34px", my: 0, ml: 1 } }
        alt={ groupChat && groupChat.name ? groupChat.name : '' }
        src={ "none" }

      >
        <PeopleAltRoundedIcon fontSize='inherit' />
      </Avatar>

      {/* User name */ }
      <Typography variant="body2" component="div" sx={ { ml: 1.25, } } >
        { groupChat && groupChat.name ? groupChat.name : '' }
      </Typography>
    </Box>
  )

  /* Member list button */
  const MemberListButton = (
    <GroupMemberList />
  )
  useEffect(() => {
  }, [groupChatMembers])

  /* Action menu */
  const MoreActionMenuButton = (
    <IconButton onClick={ handleClick }>
      <MoreVertIcon sx={ { color: '#b3b3b3', mr: 1 } } />
    </IconButton>
  )
  // Action menu options 
  const MoreActionMenuOptions = (
    <>
      {/* <MenuItem >Leave Group</MenuItem> */}
    </>
  )
  const MoreActionMenu = (
    <Menu
      id="groupchat-main-menu"
      anchorEl={ anchorEl }
      elevation={ 1 }
      keepMounted
      open={ Boolean(anchorEl) }
      onClose={ handleClose }
    >
      { MoreActionMenuOptions }
    </Menu>
  )

  // Left side //
  const LeftSide = (
    <Box sx={ { display: 'flex' } }>
      { GroupChatInfo }
      <Box mr={ 4.5 }></Box>
      { MemberListButton }
    </Box>
  )
  // Right side //
  const RightSide = (
    <Box>
      <AddNewMemberDialog />
      { MoreActionMenuButton } { MoreActionMenu }
    </Box>
  )

  /* Header container */
  const ChatHeader = (
    <Box
      className="chat-main-header"
      elevation={ 0 }
      sx={ mobileLayout ?
        { // Mobile layout
          display: "flex",
          justifyContent: "space-between",
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: '#121212',
          borderBottom: 0.5, p: 0.5
        }
        :
        { // PC layout
          display: "flex",
          justifyContent: "space-between",
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: '#121212',
          borderBottom: 0.5, p: 0.5
        }
      }
    >
      { LeftSide }

      { RightSide }

    </Box>
  )

  return <ThemeProvider theme={ customTheme }>{ ChatHeader }</ThemeProvider>;
}