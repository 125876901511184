import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import adminService from 'api/services/adminService';

import { Box, Tabs, Tab, Container, ThemeProvider, createTheme, Button, Typography, TextField } from '@mui/material';

export default function AdminUserSearch() {
    const navigate = useNavigate();

    // Prohibit normal users from using admin features
    const role = useSelector(state => state.user.role);
    useEffect(() => {
        if (role !== 'admin') navigate(-1);
      }, [role]);

    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');

    // Text field handlers
    const onNameFieldChange = (e) => {
        setUserId(''); // Clear the other field 
        setUserName(e.target.value);
    }
    const onIDFieldChange = (e) => {
        setUserName(''); // Clear the other field 
        setUserId(e.target.value);
    }

    // Form handler
    const onSubmit = async (event) => {
        event.preventDefault();

        try {
            if (userId !== '') {
                const response = await adminService.getUserById({ userId });
            }
            if (userName !== '') {
                const response = await adminService.getUserByName({ userName });
            }
            
        } catch (error) {
            console.error(error); 
        }
        
    }

    /* Search Text Fields */
    const userSearchStyle = {
        variant: "standard",
        size: "small",
        sx: { width: "300px", ml: 2, mt: 1 },
        autoFocus: true
    }
    const SearchForm = (
        <Box 
            component="form"
            onSubmit={onSubmit}
        >
            <TextField id="search-username" label="Search by Name" value={userName} {...userSearchStyle} onChange={onNameFieldChange}/> <br/>
            <TextField id="search-userid" label="Search by user ID" value={userId} {...userSearchStyle} onChange={onIDFieldChange}/>

            <Button
                type="submit"
                variant="contained"
                sx={{
                    textTransform: 'none',
                    mt: 2,
                    ml: 4,
                    backgroundColor: 'var(--color-accent)', 
                    color: 'var(--color-main)', 
                    border: '2px solid var(--color-accent)', 
                    '&:hover': {
                        backgroundColor: 'var(--color-main)', 
                        color: 'var(--color-accent)', 
                    }
                }}
            >
                Submit
            </Button>

         </Box>
    )

    return (<>
        {SearchForm}
    </>)
}