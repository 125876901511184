import * as React from 'react';
import {
    AppBar, Box, Toolbar, IconButton, Typography, Menu, Modal,
    Avatar, Button, Tooltip, MenuItem, Badge, Container, ThemeProvider, createTheme
} from '@mui/material';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import SearchIcon from '@mui/icons-material/Search';  // Commented out SearchIcon
import { Link } from 'react-router-dom';
import How2M8Content from '../components/help/How2M8';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});


function How2M8() {
    return (
        <ThemeProvider theme={lightTheme}>
            <Container maxWidth='xl'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <div>
                        <How2M8Content/>
                    </div>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default How2M8;
