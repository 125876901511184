import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { ListItem, ListItemAvatar, Avatar, ListItemText, AvatarGroup, Box, IconButton, Menu, MenuItem, Typography  } from "@mui/material";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhotoRoundedIcon from '@mui/icons-material/PhotoRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import chatService from 'api/services/chatService';

import { setSelectedCategoryId } from "app/slices/chat.slice";

import socket from "socketio/socket";

export default function CategoryListItem({category}) {
  const dispatch = useDispatch();

  // Handle dropdown menu button
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Get unread message count for individual chat in list.
  const [unreadCount, setUnReadCount] = React.useState(0);
  const user = useSelector(state => state.user);
  const getUnreadCount = async () =>{
    try {
      // serviceCode = 1: Count unread messages in ALL chat
      // serviceCode = 2: Count unread messages in SPECIFIC chat 
      // serviceCode = 3: Count unread messages across ALL chats in SPECFIC category (In this case)
      const counter = await chatService.getUnreadMessageCount( {userId: user.userId, categoryId: category._id, serviceCode: 3} );
      setUnReadCount(counter.data.unreadCounter);
    } catch (error) {
      throw error;
    }
  };
  // Get unread message count Socket.io signal listeners
  useEffect(() => {
    getUnreadCount(); // Check for unread messages on app launch first

    const unreadCountFunctionCall = () => {
      getUnreadCount();
    }
    
    socket.on('newChat', unreadCountFunctionCall);
    socket.on("receiveMessage", unreadCountFunctionCall);
    socket.on('chatRead', unreadCountFunctionCall);

    return () => {
      socket.off('newChat', unreadCountFunctionCall);
      socket.off('receiveMessage', unreadCountFunctionCall);
      socket.off('chatRead', unreadCountFunctionCall);
    };
  }, [socket]);

  /* Unread message notification */
  const UnreadMessageIndicator = (<>
    {unreadCount !== 0 ?
        <Box 
        sx={{
          backgroundColor : "var(--color-accent)", 
          color : "var(--color-main)", 
          borderRadius : "50%", 
          padding: "0px", 
          paddingLeft: "6px",
          paddingRight: "6px",
          marginLeft: "15px", 
          textAlign : "center",
        }}
      > 
        <Typography fontWeight={'bold'} fontSize={12}>{unreadCount}</Typography>
      </Box>
      :
      <Box 
        sx={{
          backgroundColor : "var(--color-main)", 
          color : "var(--color-main)", 
          borderRadius : "50%", 
          padding: "0px", 
          paddingLeft: "6px",
          paddingRight: "6px",
          marginLeft: "80px", 
          textAlign : "center",
        }}
      > 
        <Typography fontWeight={'bold'} fontSize={12}>{unreadCount}</Typography>
      </Box>
    }
    </>)

  /* Drop down menu */
  const categoryOptions = [
    { icon: <PhotoRoundedIcon fontSize='inherit'/>, text: 'Add category picture' },
    { icon: <BorderColorRoundedIcon fontSize='inherit'/>, text: 'Change category name' },
    { icon: <DeleteRoundedIcon fontSize='inherit'/>, text: 'Delete category' }
  ];
  const ITEM_HEIGHT = 48;
  const DropDownMenu = (<>
    <Menu
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      keepMounted
      elevation={1}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 'auto'
        },
      }}
    >
      {categoryOptions.length && categoryOptions.map((option, index) => {
        if (option.text === 'Delete Category') {
          if (!category.default) {
            return (
              <MenuItem key={index} onClick={handleClose}>
                <Typography textAlign="center">{option.icon}&nbsp; &nbsp;{option.text}</Typography>
              </MenuItem>
            )
          } else {
            return (
              <></>
            )
          }
        } else {
          return (
            <MenuItem key={index} onClick={handleClose}>
              <Typography textAlign="center">{option.icon}&nbsp; &nbsp;{option.text}</Typography>
            </MenuItem>
          )
        }
        
      })}
    </Menu>
  </>)

  return (
    <>
      <ListItem /*component={Link} to={`/category/${category._id}`}*/ sx={{"&:hover": {cursor: 'pointer'}}} >
        <AvatarGroup max={3} spacing={10} onClick={() => dispatch(setSelectedCategoryId(category._id))}>
          <Avatar alt={category.name} variant='round' src={category.avatar} >
            <QuestionAnswerRoundedIcon />
          </Avatar>
        </AvatarGroup>
        <Box ml={2}></Box>
        <ListItemText
          onClick={() => dispatch(setSelectedCategoryId(category._id))}
          primary={category.name} 
        />

        {/* Unread messages in category count */}
        {UnreadMessageIndicator}

        {/* Drop down menu */}
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{
            opacity: 0,
            "&:hover": {
              opacity: 0,
              //backgroundColor: 'transparent'
            }
          }}
        >
          <MoreVertIcon />
        </IconButton>
        {DropDownMenu}
      </ListItem>
  </>)
}