import React, { useState } from 'react';
import Picker, { IEmojiData } from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Typography, IconButton, Box } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CustomEmojiPicker = ({ fieldConfig, values, setFieldValue }) => {
  const emojiValues = values[fieldConfig.name]
  const [showPicker, setShowPicker] = useState(false);

  const addEmoji = (emoji) => {
    const { id, native } = emoji
    const emojiObj = { id, native }
    const newEmojis = [...emojiValues, emojiObj];
    if (newEmojis.length <= 5) {
      setFieldValue(fieldConfig.name, newEmojis);
    }
    setShowPicker(false)
  };

  const removeEmoji = (index) => {
    const newEmojis = [...emojiValues];
    newEmojis.splice(index, 1);
    setFieldValue(fieldConfig.name, newEmojis);
  };

  return (
    <Box sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
      <Box sx={{ width: "100%" }}>
        <Typography ml={1} variant="caption">{fieldConfig.label}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly', width: "100%" }}>
          {emojiValues.map((emoji, index) => (
            <Box key={index} position="relative" display="inline-block">
              <IconButton onClick={() => setShowPicker(true)} size='large'>{emoji.native}</IconButton>
              <IconButton 
                size="small" 
                style={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => removeEmoji(index)}
              >
                <HighlightOffIcon fontSize="inherit" />
              </IconButton>
            </Box>
          ))}
          {emojiValues.length < 5 && (
            <IconButton onClick={() => setShowPicker(!showPicker)} size='large'>
              <InsertEmoticonIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      {showPicker && (
          <Picker
            data={data}
            maxFrequentRows={1}
            perLine={8}
            onEmojiSelect={addEmoji} 
            theme="auto" // You can change the theme
          />
        )}
    </Box>
  );
};

export default CustomEmojiPicker;
