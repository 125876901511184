import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    AppBar, Box, Toolbar, IconButton, Typography, Menu,
    Avatar, Button, Tooltip, MenuItem, Badge, Container, ThemeProvider, createTheme
} from '@mui/material';

function WhatIsM8Content() {
    return (
        <>
            <Typography variant="h3" gutterBottom sx={{ marginTop: 3, fontWeight: 'bold', textAlign: 'center'  }}>
                What is M8?
            </Typography>
            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                M8 is a platform that is designed to help you find friends or activities without wasting your time and bombarding you with targeted ads and push notifications.
            </Typography>
            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                Sign up today!
            </Typography>
        </>
    );
}

export default WhatIsM8Content;