import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { imageStyle, slideStyle, slideStyle_mobile } from '../TutorialStyles';
import { filledButtonStyle } from 'styles/buttonStyles';

import { resetSlide } from '../../popup/Slideshow/slideshowController';

import slide2_1 from './slide2-1.png'
import slide2_2 from './slide2-2.png'
import slide4_1 from './slide4-1.png'
import slide5_1 from './slide5-1.png'

// This is the 2nd tutorial of the sequence
// The sequence goes as follows: UserProfileTutorial -> HomePageTutorial -> M8sPageTutorial & D8sPageTutorial & SearchPageTutorial

export default function HomePageTutorial({tutorialVisible, setTutorialVisible}) {
    const mobile = useSelector(state => state.global.isMobile);

    // Reset slide to slide 1 on popup show
    useEffect(() => {
        if (tutorialVisible) {
            resetSlide();
        }
    },[tutorialVisible])

    const dots = Array(6).fill(null); // For dots below the slideshow, change number of dots Here when you add/remove slides from return()

    return (
        <div className="tutorial-home slideshow-container">

            <div className="popup-slide fade tutorial-2-1">
                
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={300} width={700}> 
                    <Box mb={4} fontSize={80}>
                        {/*
                        <img 
                            src="/icons/home.svg" 
                            height={50}
                            style={imageStyle}
                            
                        />
                        */}
                        <HomeRoundedIcon fontSize='inherit'/>
                    </Box>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 0 : 7} mb={2}>
                        This is your <b>Home page</b>, where you can see mates (<b>M8s</b>) and date coupons (<b>D8s</b>).
                    </Typography>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 0 : 7} mb={2}>
                        This will be your landing page on login.
                    </Typography>
                    {/*
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7}>
                        Press &emsp; ▶ &emsp; to continue...
                    </Typography>
                    */}
                </Box>
            </div>
            

            <div className="popup-slide fade tutorial-2-2">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        To check out a M8's profile, simply click on their card. 
                    </Typography>
                    
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide2_1}
                            height={300}
                            style={imageStyle}
                        />
                       
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-2-2b">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        If you find a interested M8, you can send them a message from their profile.
                    </Typography>
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        
                        <img 
                            src={slide2_2}
                            height={mobile ? 430 : 390}
                            style={imageStyle}
                        />
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-2-3">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700}>
  
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        Click on a D8 to learn more about its details. 
                    </Typography>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        If you are interested, you can <b>CLAIM</b> the D8.
                    </Typography>
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        {"<Insert screenshots here when test D8s is added>"}
                    </Box>

                </Box>
            </div>

            <div className="popup-slide fade tutorial-2-4">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700}>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={6} >
                        To visit & edit your profile at any point, click on your avatar at the top right corner.
                    </Typography>
                    <img 
                            src={slide4_1}
                            height={270}
                            style={imageStyle}
                        />
                </Box>
            </div>
            
            <div className="popup-slide fade tutorial-2-5">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        <b>There is more to M8!</b>
                    </Typography>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        When you're ready, start exploring other functionalities from the {mobile ? `menu` : 'header'} {mobile && <MenuIcon fontSize='medium'/>}  above .
                    </Typography>
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                        mb={!mobile && 8}
                    >
                        <img 
                            src={slide5_1}
                            height={mobile ? 0 : 50}
                            style={imageStyle}
                        />
                    </Box>

                    <Button 
                        onClick={() => 
                            {
                                setTutorialVisible(false);
                                localStorage.removeItem('first-time-home');
                                resetSlide();
                                
                            }
                        }
                        sx={[filledButtonStyle, {width: 180}]}
                        mt={mobile ? '50px' : ''}
                        >
                            Start Exploring
                    </Button>
                </Box>
                </div>
    
            {/* The indicator dots below slides*/}
            <Box 
                className='popup-slide-dot-container' 
                sx={{textAlign: 'center'}}
            >
                {dots.map((_, index) => (
                    <span key={index} className="dot"></span>
                ))}
            </Box>

        </div>
    );
}