import axios from "../axios";

const getOccupations = async (term) => {
  try {
    const response = await axios.get(`/formOptions/occupation?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getClasses = async (term) => {
    try {
        const response = await axios.get(`/formOptions/classes?term=${term}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getCurrentCity = async (term) => {
  try {
    const response = await axios.get(`/formOptions/currentCity?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSchool = async (term) => {
  try {
    const response = await axios.get(`/formOptions/school?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getInterests = async (term) => {
  try {
    const response = await axios.get(`/formOptions/interests?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getMusic = async (term) => {
  try {
    const response = await axios.get(`/formOptions/music?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getFavoriteFoods = async (term) => {
    try {
        const response = await axios.get(`/formOptions/favoriteFoods?term=${term}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getMedia = async (term) => {
  try {
    const response = await axios.get(`/formOptions/media?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getComedians = async (term) => {
  try {
    const response = await axios.get(`/formOptions/comedians?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSports = async (term) => {
  try {
    const response = await axios.get(`/formOptions/sports?term=${term}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const formService = {
  getOccupations,
  getClasses,
  getCurrentCity,
  getSchool,
  getInterests,
  getMusic,
  getFavoriteFoods,
  getMedia,
  getComedians,
  getSports,
};

export default formService;
