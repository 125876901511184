import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateAppOnFocus } from "app/slices/global.slice";

// Credit: Assaf (https://stackoverflow.com/users/4252799/assaf)
// https://stackoverflow.com/a/54820741

const WindowFocusHandler = () => {
    const dispatch = useDispatch();
    let onFocus = true;

    useEffect(() => {
        // When tab is on focus
        window.addEventListener("focus", () => {
            onFocus = true;
            dispatch(updateAppOnFocus({ onFocus }));
        });

        // When tab is hidden or closed
        window.addEventListener("blur", () => {
            onFocus = false;
            dispatch(updateAppOnFocus({ onFocus }));
        });
        
        return () => {
            window.removeEventListener("focus", () => {
                onFocus = true;
                dispatch(updateAppOnFocus({ onFocus }));
            });
            window.removeEventListener("blur", () => {
                onFocus = false;
                dispatch(updateAppOnFocus({ onFocus }));
            });
        };
  }, []);

    return <></>;
};

export default WindowFocusHandler;