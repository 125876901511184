import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Stack, Grid, Avatar, Box, Paper, Card, CardMedia, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import eventService from '../../api/services/eventService';

import EventDrawerContent from './EventDrawerContent';
import BottomDrawer from '../drawer/Drawer';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // If handling UTC dates
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function EventCard(props) {
  const { events, setEvents, smallIcon } = props

  const mobileLayout = useSelector(state => state.global.isMobile);

  const role = useSelector(state => state.user.role)
  const { name, image, cost, discountedCost, eventDateTime } = props.event;
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to handle the click event on the card
  const handleCardClick = (event) => {
    event.preventDefault();
    setDrawerOpen(true);
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).utc().local().format("MMMM D YYYY h:mm A");
  };

  const openDeleteModal = () => setIsDeleteModalOpen(true);
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const confirmDelete = async () => {
    closeDeleteModal();
    try {
      const response = await eventService.deleteEvent(props.event._id);
      if (response === "OK") {
        const newEvents = events.filter(event => event._id !== props.event._id);
        setEvents(newEvents);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderCost = () => (
    discountedCost ? (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption" component="span" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
          ${cost.toFixed(2)}
        </Typography>
        <Typography variant="caption" component="span" color="primary">
          ${discountedCost.toFixed(2)}
        </Typography>
      </Stack>
    ) : (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption" component="span" color="primary">
          ${cost.toFixed(2)}
        </Typography>
      </Stack>
    )
  );

  // Function to handle closing the Drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Style variables
  const largeIconSize = 80;
  const smallIconSize = 50;

  return (
    <ThemeProvider theme={lightTheme}>
      <Box 
        style={
          { 
            cursor: 'pointer',
            border: '0px solid var(--color-accent)',
            borderRadius: '12px',
            width: 'inherit',
            display: "flex",
            flexDirection: "column",
          }
        } 
      >
        <Card 
          onClick={handleCardClick} 
          sx={
            { 
              backgroundColor: 'var(--color-main)',
              transition: '0.2s',
              display: 'flex', 
              flexDirection: 'row', 
              flexWrap: 'nowrap',
              alignItems: 'center', 
              p: 1, 
              border: '0px solid var(--color-accent)',
              borderRadius: '12px',
              '&:hover': {
                backgroundColor: 'grey.100'
              }
            }
          }
          variant="outlined"
        >
          <Avatar 
            alt={image} 
            src={image} 
            sx={ !mobileLayout ?
              // PC layout small icon for list view
              ( smallIcon ?
                {
                  width: smallIconSize, 
                  height: smallIconSize, 
                  margin: "10px auto" 
                }
                :
                {
                  width: largeIconSize, 
                  height: largeIconSize, 
                  margin: "10px auto" 
                }

              )
              :
              { // Mobile layout
                width: smallIconSize, 
                height: smallIconSize, 
                margin: "10px auto" 
              }
            }
            variant="rounded"
          />
          <Box sx={{ ml: 2, width: '100%', display: "flex", flexDirection: "column" }}>
            <Grid container alignItems="center">
              <Grid item xs={12} textAlign="right">
                {renderCost()}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" textWrap={'nowrap'} overflow={'hidden'} textOverflow={"ellipsis"} >
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.secondary">
                  {formatDate(eventDateTime)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Card>
        {role === "admin" && (
          <Button onClick={openDeleteModal} variant="text" size="small" sx={{ color: "red", justifyContent: "flex-end", mr: 1 }}>Delete</Button>
        )}
      </Box>


      <Dialog
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this event? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal}>Cancel</Button>
          <Button onClick={confirmDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <BottomDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} content={<EventDrawerContent event={props.event}/>} height={windowHeight * 0.7} buttonAbsolute={true} />
    </ThemeProvider>
  );
}
