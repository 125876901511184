import React, { useState } from 'react';
import { Box, Avatar, IconButton, CircularProgress } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import imageCompression from 'browser-image-compression';

function EditEventMedia({ image, setImage }) {
  const existingImage = image ? image : null
  const [displayImage, setDisplayImage] = useState(existingImage);
  const [isCompressing, setIsCompressing] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setIsCompressing(true)
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1920, // (Compressed file maximum dimension)
        useWebWorker: true // (Use multi-threading for better performance)
      };

      // Compress the image file
      const compressedBlob = await imageCompression(file, options);
      setIsCompressing(false)

    // Convert the Blob into a File
      const compressedFile = new File([compressedBlob], file.name.replace(/\s/g, '_'), {
        type: compressedBlob.type,
        lastModified: Date.now(),
      });

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const newImage = fileReader.result;
        setDisplayImage({ name: compressedFile.name.replace(/\s/g, '_'), mediaUrl: newImage });
        setImage({ name: compressedFile.name, file: compressedFile, mediaUrl: newImage });
      };
      fileReader.readAsDataURL(compressedFile);
      e.target.value = ''
    } catch (error) {
      console.error('Error during image compression', error);
    } e.target.value = ''
  };

  const handleDeleteImage = () => {
    setImage("");
    setDisplayImage("");
  };

  return (
    <Box my={2}>
      {image !== "" ? (
        <Box position="relative" sx={{ height: 120 }}>
          <img src={image.mediaUrl} alt="Selected" style={{ width: "100%", height: "100%" }} />
          <IconButton size="small" style={{ position: 'absolute', top: -12, right: -12 }} onClick={handleDeleteImage}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      ) : (
        <label htmlFor="event-image-upload">
          <input accept="image/*" style={{ display: 'none' }} id="event-image-upload" type="file" onChange={handleImageChange} />
          <IconButton sx={{ height: 120 }} color="primary" aria-label="upload picture" component="span">
            {isCompressing ? <CircularProgress /> : <PhotoCameraIcon />}
          </IconButton>
        </label>
      )}
    </Box>
  );
}

export default EditEventMedia;
