import React, { useEffect, useState } from 'react';
import { Box, TextField, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default function GroupChatInputBox({ newMessage, setNewMessage, onSendMessage }) {
  //const [newMessage, setNewMessage] = useState('');

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      onSendMessage(newMessage);
      setNewMessage(''); // Clear the input field
    }
  };

  const keyPress = (e) => {
    if(e.keyCode == 13){
        e.preventDefault()
        handleSendMessage()
    }
 }

  return (
    <Box sx={{ mx: 2, my: 1 }} display="flex" alignItems="center">
      <TextField
        size='small'
        multiline
        maxRows={6}
        variant="outlined"
        placeholder='Type message'
        fullWidth
        value={newMessage}
        onChange={handleInputChange}  
        onKeyDown={keyPress}
        InputProps={{
            style: {
              borderRadius: "10px",
              fontSize: "14px",
            }
        }}
      />
        <IconButton
          sx={{ color: 'var(--color-accent)', paddingRight: 0 }}
          onClick={handleSendMessage}
        >
          <ArrowCircleRightIcon fontSize="large" />
        </IconButton>
    </Box>
  );
}