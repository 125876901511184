import React, { useState, useCallback } from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import debounce from 'lodash/debounce';

const CustomSlider = ({ fieldConfig, values, setFieldValue }) => {
  // Determine if it's a range or single value slider
  const isRange = fieldConfig.isRange;
  const initialValue = isRange 
    ? values[fieldConfig.name] || [fieldConfig.min, fieldConfig.max]
    : values[fieldConfig.name] || values.height;

  const [sliderValue, setSliderValue] = useState(initialValue);

  const debouncedChangeHandler = useCallback(
    debounce((newValue) => {
      setFieldValue(fieldConfig.name, newValue);
    }, 300),
    [] // Dependency array - empty means this function is created once per component instance
  );

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    debouncedChangeHandler(newValue);
  };

  const formatSliderLabel = (value) => {
    if (fieldConfig.formatLabel) {
      return fieldConfig.formatLabel(value);
    }
    return value;
  };

  const valueLabel = isRange 
    ? `${formatSliderLabel(sliderValue[0])} to ${formatSliderLabel(sliderValue[1])}`
    : formatSliderLabel(sliderValue);

  return (
    <Box
      mt={2}
      px={2}
      pt={2}
      pb={1}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: 1, 
        borderColor: 'grey.400',
        borderRadius: '4px'
      }}
    >
      <Typography variant="body2" sx={{ color: 'grey.750' }}>
        {fieldConfig.label}: {valueLabel}
      </Typography>
      <Box px={2} width={'90%'}>
        <Slider
          size='small'
          value={sliderValue}
          name={fieldConfig.name}
          onChange={handleSliderChange}
          valueLabelDisplay="off"
          min={fieldConfig.min}
          max={fieldConfig.max}
          step={isRange ? fieldConfig.step : undefined}
        />
      </Box>
    </Box>
  );
};

export default CustomSlider;
