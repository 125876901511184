import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Chat
  chats: {},
  lastMessages: {},
  categories: {},
  chatUserAssociation: {},
  defaultCategory: "",

  // Chat session states
  selectedCategoryId: null,
  chatSessionId: null,
  friendId: null,
  friendUserInfo: {},

  // Other shared states
  messageEditMode: false,
  profileDrawerVisible: false,

}

const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    
    // Category
    addCategory: (state, action) => {
      const category = action.payload
      const categoryId = category._id
      state.categories[categoryId] = category 
    },
    updateCategories: (state, action) => {
      const categories = action.payload
      state.categories = categories 
    },
    updateDefaultCategory: (state, action) => {
      const defaultCategory = action.payload
      state.defaultCategory = defaultCategory 
    },

    // Chat
    addNewChat: (state, action) => {
      const { newChatUserAssociation, newChat, newMessage } = action.payload
      state.chatUserAssociation[newChat._id] = newChatUserAssociation
      state.chats[newChatUserAssociation.categoryId][newChat._id] = newChat
      state.lastMessages[newChat._id] = newMessage
    },
    updateChats: (state, action) => {
      const chats = action.payload
      state.chats = chats
    }, 
    deleteChat: (state, action) => {
      const { chatId, categoryId } = action.payload
      delete state.chats[categoryId][chatId]
    },
    updateChatCategory: (state, action) => {
      const { newChatUserAssociation, oldCategoryId } = action.payload
      state.chatUserAssociation[newChatUserAssociation.chatId] = newChatUserAssociation
      let chat = state.chats[oldCategoryId][newChatUserAssociation.chatId]
      delete state.chats[oldCategoryId][newChatUserAssociation.chatId]
      state.chats[newChatUserAssociation.categoryId][newChatUserAssociation.chatId] = chat
    },
    updateLastMessages: (state, action) => {
      const lastMessages = action.payload
      state.lastMessages = lastMessages
    },
    updateLastMessage: (state, action) => {
      const newLastMessage = action.payload
      state.lastMessages[newLastMessage.chatId] = newLastMessage
    },
    
    // Chat & User link (association)
    updateChatUserAssociation: (state, action) => {
      const chatUserAssociation = action.payload
      state.chatUserAssociation = chatUserAssociation
    },

    // Chat session state setters
    setSelectedCategoryId: (state, action) => {
      const categoryId = action.payload
      state.selectedCategoryId = categoryId 
    },
    setChatSessionId: (state, action) => {
      const chatId = action.payload
      state.chatSessionId = chatId 
    },
    setFriendId: (state, action) => {
      const friendId = action.payload
      state.friendId = friendId 
    },
    setFriendUserInfo: (state, action) => {
      const friendUserInfo = action.payload
      state.friendUserInfo = friendUserInfo
    },

    // Chat message edit mode toggle
    setMessageEditMode: (state, action) => {
      const editModeBool = action.payload
      state.messageEditMode = editModeBool
    },

    // Profile drawer visibility toggle (mobile only)
    setProfileDrawerVisible: (state, action) => {
      const profileDrawerVisible = action.payload
      state.profileDrawerVisible = profileDrawerVisible
    },

    // Unread message count
    incrementUnreadCount: (state, action) => {
      const { chatId } = action.payload
      state.chatUserAssociation[chatId] += 1
    },
  },
  
});

export const { 
  addCategory, 
  updateCategories, 
  updateChatCategory,
  updateDefaultCategory,  
  
  addNewChat,
  deleteChat,
  updateChatUserAssociation, 
  updateChats, 
  updateLastMessage,
  updateLastMessages,

  setSelectedCategoryId,
  setChatSessionId,
  setFriendId,
  setFriendUserInfo,

  setMessageEditMode,
  setProfileDrawerVisible,

  incrementUnreadCount,
  
} = chatSlice.actions;
export default chatSlice.reducer;