import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Tab, Tabs, Typography, Box, Divider } from '@mui/material';

import NotificationSettings from 'components/settings/NotificationSettings';
import AccountSettings from 'components/settings/AccountSettings';

export default function SettingsPage() {
    const mobileLayout = useSelector(state => state.global.isMobile);

    /* Side menu */
    const [optionIndex, setOptionIndex] = React.useState(0);
    const handleChange = (event, newValue) => {
        setOptionIndex(newValue);
    };
    const menuOptions = [
        {label: "General"},
        {label: "Account"},
        {label: "Notification"},  
    ]
    const SideMenu = (<>
        <Tabs
        orientation="vertical"
        variant="scrollable"
        value={optionIndex}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        textColor="var(--color-separator)"
        indicatorColor="var(--color-separator)"
        sx={
            { 
                color: 'var(--color-text)',
                borderRight: 0, 
                borderColor: 'var(--color-accent)',
                '.MuiTab-textColorPrimary': { color: 'var(--color-separator)' },
                '.MuiTabs-indicator': { backgroundColor: 'var(--color-separator)' },
                maxWidth: 250,
                width: 250
            }
        }
      >
        {menuOptions.length > 0 && menuOptions.map((option, index) => 
                <Tab key={index} label={option.label} sx={{alignItems: 'start', textTransform: "none",}} />
        )}
      </Tabs>
    </>)

    /* General settings */
    const GeneralSettings = (<>
        
    </>)

    /* Settings area */
    const SettingsArea = () => {
        
        switch(optionIndex) {
            case 0: return GeneralSettings;
            case 1: return <AccountSettings />;
            case 2: return <NotificationSettings />;
            default: return GeneralSettings;
        }
    }

    return (
    <Container maxWidth="xl">
        <Typography variant='h4' fontWeight={'bold'} mt={3}>Settings</Typography>
        <Box sx={{width: '100%', opacity: 0.2, mx: 'auto', mt: 2}}>
            <Divider orientation="horizontal" sx={{width: '100%', border: '1px var(--color-accent) solid', borderRadius: '999px'}} />
        </Box>
        <Box mb={3}></Box>

        <Box 
            sx={mobileLayout ? 
                {} 
                : 
                {
                    height: '100%', 
                    display: 'flex', 
                    flexDirection: 'row'
                }
            }
        >
            
            {SideMenu}
            <Box
                sx={{
                    
                    width: '80%',
                    height: '100%',
                    overflowY: 'scroll',
                    ml: 3,

                }}
            >
                {SettingsArea(optionIndex)} 
            </Box>
        </Box>
    </Container>
    )
}
