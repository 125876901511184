// React, Redux, DOM essentials
import React, { useState, useEffect, useReducer } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ChatProfile from 'components/chats/chatProfile/ChatProfile';

// Redux state management
import { 
  setProfileDrawerVisible,
} from 'app/slices/chat.slice';

export default function ChatProfileDrawer() {
    const dispatch = useDispatch();

    // Profile view visibility control states (mobile only)
    const profileDrawerVisible = useSelector(state => state.chats.profileDrawerVisible);

    /* Profile window */
    // Function to handle Drawer (mobile only)
    const toggleProfileDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }
        dispatch(setProfileDrawerVisible(open));
    };
    
    return <>
        <ChatProfile profileDrawerVisible={profileDrawerVisible} toggleProfileDrawer={toggleProfileDrawer}/>
    </>
}