import { createSlice } from '@reduxjs/toolkit';
import { isMobile } from 'scripts/mobileCheck';

const initialState = {
    isMobile: isMobile(),
    appOnFocus: true,
    //publicVapidKey: 'BDyB4y5bV_R-yi4frjsUA30cGGZsygg46BRtWjV7bIAMy6CnVQEcHj1Tgn1MgYRdntqPLiIqW_UMKjkEJl5cv2c'
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
      updateIsMobile: (state, action) => {
        const { isMobile } = action.payload;
      
        state.isMobile = isMobile ?? state.isMobile;
      },
      updateAppOnFocus: (state, action) => {
        const { onFocus } = action.payload;
      
        state.appOnFocus = onFocus ?? state.appOnFocus;
      }
    },
});
export const { updateIsMobile, updateAppOnFocus } = globalSlice.actions;
export default globalSlice.reducer;