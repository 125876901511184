// Essentials
import React, { useState, useRef, useEffect, useReducer} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'; 

// MUI components
import { 
  Avatar, 
  Box, 
  CircularProgress, 
  Typography, 
  IconButton, 
  Menu, 
  MenuItem, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  DialogActions, 
  DialogContentText, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemButton, 
  ListItemAvatar, 
  Button, 
  AvatarGroup 
} from '@mui/material';

// MUI styles
import { ThemeProvider, createTheme } from '@mui/material/styles';

// MUI icons
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Backend services
import chatService from 'api/services/chatService';
import groupChatService from 'api/services/groupchatService';
import userService from 'api/services/userService';

// Socket.io
import socket from 'socketio/socket';

// Redux state management 
import { 
  deleteChat, 
  updateChatCategory,

  setChatSessionId, 
  setFriendId, 
  setFriendUserInfo,
  setSelectedCategoryId
} from 'app/slices/chat.slice';

import {
  setGroupChatSessionId,

  updateGroupSessionAssociation,
  updateGroupChats,
  updateGroupChatMembers,
  updateGroupChatDetail
} from 'app/slices/groupchat.slice'

// Custom scripts
import { delay } from 'scripts/delay';

// Custom styles
import { dialogButtonFocus, dialogButtonRegular } from 'styles/dialogButtonStyles';

// Custom components
import GroupChatHeader from './GroupChatHeader';
import GroupChatMessage from '../groupChatMessage/GroupChatMessage';
import GroupChatInputBox from './GroupChatInputBox';
//import BottomDrawer from 'components/drawer/Drawer';
//import MemberCard from 'components/memberCard/MemberCard';
import AcceptGroupInvitePrompt from './prompt/AcceptGroupInvitePrompt';


const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function GroupChatWindow({setProfileDrawerVisible}) {
  const mobileLayout = useSelector(state => state.global.isMobile);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFocus = useSelector(state => state.global.appOnFocus);

  // Visibility control states for mobile layout
  const [chatVisible, setChatVisible] = useState(true); 

  const userId = useSelector(state => state.user.userId);
  const groupChat = useSelector(state => state.groupchats.groupChatDetail);
  const groupChatId = useSelector(state => state.groupchats.groupChatSessionId); 
  const groupChatUserAssociation = useSelector(state => state.groupchats.groupSessionAssociation);
  const groupChatMembers = useSelector(state => state.groupchats.groupChatMembers);


  const [chatMessages, setChatMessages] = useState([]);
  
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
  const messageListRef = useRef(null);

  // Chat window layout
  const mobileMode = {
    width: '100%',
    height: '91vh',
    display: groupChatId !== null && chatVisible ? "visible" : "none"
  }
  const pcMode = {
    mt: 2,

    border: '2px solid var(--color-accent)',
    borderRadius: 'var(--border-radius-main)',

    width: '780px',
    height: '85vh',
    '@media (max-height: 650px)': {
      height: '80vh'
    },
    '@media (max-height: 508px)': {
      height: '75vh'
    },

    overflowY: 'hidden'
  }


  // Execute on initial render
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Initialize chats and user profiles base on chat ID
  useEffect(() => {
    Promise.all([
      groupChatService.getGroupChatDetail({ groupChatId: groupChatId }),
      groupChatService.getGroupChatMembers({ groupChatId: groupChatId }),
      groupChatService.getGroupChatMessages(groupChatId)
    ])
    .then(async ([groupChatDetailRes, groupChatMemberRes, groupChatMessageRes]) => {
      dispatch(updateGroupChatDetail(groupChatDetailRes.data));

      dispatch(updateGroupChatMembers(groupChatMemberRes.data));

      setChatMessages(groupChatMessageRes.data); 

      setIsLoading(false);
    })
    .catch((err) => {
      //console.log(err);
      setIsLoading(false);
    });
    
    
  }, [groupChatId]);

  // Refresh Group Chat and user association on change
  useEffect(() => {
    void(0);    
  },[groupChatUserAssociation])

  // Refresh Group Chat member list on change
  useEffect(() => {
    void(0);
  },[groupChatMembers])

  // Refresh Group Chat detail on change
  useEffect(() => {
    void(0);
  },[groupChat])

  // For scrolling to bottom of message window to show latest message
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    };
  }, [chatMessages]);

  // Real time chat update only works when app instances runs from the same server.
  // It does NOT work with localhost on separete machines.
  // In order to test real time chat, either open a incognito window and login with your second account,  
  // or host the app on a testing server service such as ngrok.
  useEffect(() => {
    const messageListener = (newChatMessage) => {
      if (newChatMessage.groupChatId === groupChatId) {
        setChatMessages(prevMessages => [...prevMessages, newChatMessage]);
        ChatMessageWindow(messageListRef, userId, chatMessages, onFocus); // Update displayed message

        // Scroll to bottom of message window to show latest message
        if (messageListRef.current) {
          messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
      }
    };

    socket.on("receiveGroupMessage", messageListener);

    return () => {
      socket.off("receiveGroupMessage", messageListener);
    };
  }, [groupChatId, socket]); 

  const handleSendMessage = (newMessage) => {
    if (newMessage.trim() !== '') {
      const newGroupChatMessage = {
        groupChat: groupChat,
        content: newMessage,
        senderId: userId,
      };
      socket.emit("sendGroupMessage", {...newGroupChatMessage, userId});
    }
  };



  // Friend profile dawer (mobile only)
  const handleProfileDrawer = () => {
    //handleClose();
    setProfileDrawerVisible(true);
  }

  // Chat delete handle
  const handleDeleteChat = () => {
    /*
    const categoryId = chatUserAssociationCategoryId;
    dispatch(setChatSessionId(null));
    chatService.removeChat({ chatId, chatUserAssociationId, categoryId }).then(res => {
      const chatId = res.data.chatId
      const categoryId = res.data.categoryId
      dispatch(deleteChat({ chatId, categoryId }));
      //TODO
    }).catch(err => {
      console.error(err);
    });
    */
  } 

  // Chat category change handle
  const handleMoveChat = (categoryId) => {
    /* 
    handlePopupClose();
    chatService.changeChatCategory({ chatUserAssociationId, newCategoryId: categoryId, chatId }).then(res => {
      const newChatUserAssociation = res.data;
      const oldCategoryId = chatUserAssociationCategoryId;
      dispatch(updateChatCategory({ newChatUserAssociation, oldCategoryId }));

      //socket.emit('refreshChatSignal',{})

    }).catch(err => {
      console.log(err);
    });
    */
  };

  // Accepting and applying chatId from url parameters if any
  let urlParams = useParams();
  useEffect(() => {
    if (urlParams.groupChatId !== undefined) {
      dispatch(setGroupChatSessionId(urlParams.groupChatId));
    }
  },[])

  /* Empty chat */
  const nullChatId = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
  
    return true;
  }
  const NoChat = (
    <Box
          className="chat-main-empty"
          sx={
            mobileLayout ?
            { // Mobile layout
              width: '100%',
              height: '100%',
            }
            :
            { // PC layout
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',

              width: '100%',
              height: '100%',

              fontSize: '100px',

              opacity: 1
            }
          }
        >
          {!mobileLayout && <GroupsRoundedIcon fontSize='inherit' />}
    </Box>
  )



  /* Chat messages */
  let vh = window.innerHeight * 0.01; 
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  const ChatMessageWindow = (mesgRef, uID, mesgs) => {
    return (
      <Box
        className="chat-main-messages"
        sx={ mobileLayout ?
          { // Mobile layout
            mx: 1,
            flex: 1,
            maxHeight: '100vh', // Fallback for old browsers
            maxHeight: `calc(var(--vh, 1${vh}) * 100)`, // See: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
            overflow: 'auto',
            scrollbarWidth: 'none', // Hide the scrollbar in Firefox
            '&::-webkit-scrollbar': {
              width: '0.5em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent', // Hide the scrollbar thumb
            }
          }
          :
          { // PC layout
            mx: 1,
            flex: 1,
            overflow: 'auto',
            scrollbarWidth: 'none', // Hide the scrollbar in Firefox
            '&::-webkit-scrollbar': {
              width: '0.5em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent', // Hide the scrollbar thumb
            },

            borderBottomRightRadius: 'var(--border-radius-main)',
            borderBottomLeftRadius: 'var(--border-radius-main)'
          }
        }
        ref={mesgRef}
      >
        <>
          {
          //mesgs.slice(0, 50).map((message, index) => (
          mesgs.map((message, index) => (
            <GroupChatMessage
              key={index} 
              userId={uID}
              senderInfo={groupChatMembers[message.senderId]}
              message={message} 
              currentGroupChatId={groupChatId}
            />
          ))
          }
          <Box mb={8}></Box> {/* Add a bottom margin below last message to give space for  Message Input Field */}
        </>
      </Box>
    )
  }

  /* Chat input field */
  const [newMessage, setNewMessage] = useState('');
  const ChatMessageInput = (
    <Box
      sx={mobileLayout ?
        { // Mobile layout
          position: 'fixed',
          bottom: 0,
          width: '100%',
          background: 'var(--color-main)',
          zIndex: 2,
        }
      :
        { // PC layout
          position: 'sticky',
          bottom: 0,
          background: 'var(--color-main)',
          zIndex: 2
          
        }
      }

    >
      
      {mobileLayout && (<>
        {/* Left edge bend */}
        <Box
          sx={{
            position: 'fixed',
            backgroundColor: '#00000000',
            bottom: 67,
            left: 7,
            height: '50px',
            width: '50px',
            borderBottomLeftRadius: 'var(--border-radius-main)',
            'box-shadow': '0 15px var(--color-main)',
            zIndex: 2,
          }}
        />
        {/* Right edge bend */}
        <Box
          sx={{
            position: 'fixed',
            backgroundColor: '#00000000',
            bottom: 67,
            right: 7,
            height: '50px',
            width: '50px',
            borderBottomRightRadius: 'var(--border-radius-main)',
            'box-shadow': '0 15px var(--color-main)',
            zIndex: 2,
          }}
        />
      </>)}
      
      <GroupChatInputBox newMessage={newMessage} setNewMessage={setNewMessage} onSendMessage={handleSendMessage} />
      
    </Box>
  )

  /* Loading spinner */
  const LoadingSpinner = (
    <Box
          className="chat-main-empty"
          sx={
            mobileLayout ?
            { // Mobile layout
              width: '100%',
              height: '100%',
            }
            :
            { // PC layout
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',

              width: '100%',
              height: '100%',

              fontSize: '100px',

              opacity: 1
            }
          }
        >
          <Box
            sx={{
              position: 'fixed', // Fixed position
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center', // Vertical centering
              justifyContent: 'center', // Horizontal centering
            }}
          >
          <CircularProgress color="inherit" />
        </Box>
    </Box>
    
  )
  if (isLoading) {
    return (
      <Box
      className="chat-main"
      sx={
        mobileLayout ?
        mobileMode // Mobile layout
        :
        pcMode // PC layout
      }
    >
      {LoadingSpinner}
    </Box>
    )
  }

  /* Empty chat page */
  if (nullChatId(groupChatId)) {
    return (
      <Box
      className="chat-main"
      sx={
        mobileLayout ?
        mobileMode // Mobile layout
        :
        pcMode // PC layout
      }
    >
      {NoChat}
    </Box>
    )
  }

  return (
    <Box
      className="chat-main"
      sx={
        mobileLayout ?
        mobileMode // Mobile layout
        :
        pcMode // PC layout
      }
    >
        {isLoading ? LoadingSpinner : (
          <ThemeProvider theme={lightTheme}>
            <Box
              sx={{
                height: `100%`, // Original: '${windowHeight - 60}px'
                width: '100%',
                
                overflow: 'hidden',

                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* Chat header */}
              {groupChatId && (
                <GroupChatHeader groupChat={groupChat} groupChatMembers={groupChatMembers} />
              )}

              {/* Main chat window */}
              {chatMessages && (
                <>
                  {/* Accept Group invite prompt */}
                  { groupChat && 
                    Object.keys(groupChatUserAssociation).length > 0 && groupChatUserAssociation.acceptedInvite === false ?
                      <AcceptGroupInvitePrompt groupChat={groupChat}/>
                    :
                      <></>
                  }

                  { groupChat && 
                    Object.keys(groupChatUserAssociation).length > 0 && groupChatUserAssociation.acceptedInvite === true ?
                      ChatMessageWindow(messageListRef, userId, chatMessages)
                    :
                      <></>
                  }
                  { groupChat && 
                    Object.keys(groupChatUserAssociation).length > 0 && groupChatUserAssociation.acceptedInvite === true ?
                      ChatMessageInput
                    :
                      <></>
                  }
                </>
              )}
              
              {/* Dialogs */}
              

            </Box> 
          </ThemeProvider>
        )}
    </Box>
  );
}
