import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import axios from 'axios';
import userService from 'api/services/userService';

import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

const PasswordResetPage = () => {
    const navigate = useNavigate();
    const mobileLayout = useSelector(state => state.global.isMobile);

    const { token } = useParams();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [message, setMessage] = useState('');
    const [messageColor, setMessageColor] = useState('var(--color-accent)');
    
    const [disableButton, setDisableButton] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage("Passwords don't match!");
            setMessageColor('Brown');
            return;
        }

        userService.resetPassword({ resetToken: token, newPassword: password }).then(res => {
            setMessage(res.message);
            setMessageColor('var(--color-accent)');
            setDisableButton(true);
        }).catch(err => {
            console.log(err);
        });
    };

    // Clear text field color on text change
    useEffect(() => {
        setMessage('');
        setMessageColor('var(--color-accent)');
    },[password, confirmPassword])

    /* Big M8 logo */
    const BigM8Logo = (
        <Box
        sx={{
            //backgroundColor: '#000', 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 3,

        }}
        >
            <div className="logo-container">
                <img src='/logo/BM8.svg' alt="Logo" style={{ width: "75px" }} />
            </div>
        </Box>
    )

    /* Back to login button */
    const BackToLogin = (
        <Link to="/login" >
        <Button
            type="button"
            fullWidth
            variant="text"
            sx={
                mobileLayout ?
                { mt: 2, color: '#333', textTransform: 'none' }
                :
                { mt: -1, color: '#333', textTransform: 'none' }
            }
        >
             Back to login
        </Button>
        </Link>
    )

    return (
        <ThemeProvider theme={lightTheme}>
            <Container maxWidth="xs">
                
                {BigM8Logo}
                
                <Typography
                    variant="h5"
                    component="h1"
                    align="center"
                    gutterBottom
                    sx={{ marginTop: 6 }}
                >
                    Reset Your Password
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        label="New Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        error={messageColor === 'Brown' ? true : false}
                        required
                    />
                    <TextField
                        label="Confirm New Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        error={messageColor === 'Brown' ? true : false}
                        required
                    />

                    <Typography sx={{textAlign: 'center'}} color={messageColor}>{message}</Typography>

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={disableButton}
                        sx={{
                            mt: 5,
                            mb: 2,
                            backgroundColor: '#333',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#555',
                            },
                        }}
                    >
                        Reset Password
                    </Button>
                </form>
                {BackToLogin}
            </Container>
        </ThemeProvider>
    );
};

export default PasswordResetPage;
