import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import EventDrawerContent from '../../events/EventDrawerContent';
import CustomDrawer from '../../drawer/Drawer';
import EditEventMedia from './EditEventMedia';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Initialize dayjs with timezone plugin
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

function CreateEvent({ event, index, setEvents }) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCardClick = (event) => {
    event.preventDefault();
    setDrawerOpen(true);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleDateTimeChange = (newValue) => {
    setEvents(prevEvents => prevEvents.map((e, i) => 
      i === index ? { ...e, eventDateTime: newValue } : e
    ));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEvents(prevEvents => prevEvents.map((e, i) => 
      i === index ? { ...e, [name]: value } : e
    ));
  };

  const handleSubmit = async () => {
    // Implement form submission logic here
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.tz.guess()}>
      <Box mx={2} mb={3} sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button size="small" onClick={handleCardClick} variant="contained" color="grey" sx={{  color: "black"  }}>Preview</Button>
        </Box>
        <EditEventMedia 
          image={event.image} 
          setImage={(img) => {
            setEvents(prevEvents => prevEvents.map((e, i) => 
              i === index ? { ...e, image: img } : e
            ));
          }} 
        />        
        <MobileDateTimePicker
            label="Event Date Time *"
            inputFormat="YYYY-MM-DD HH:mm"
            value={event.eventDateTime}
            onChange={handleDateTimeChange}
            slotProps={{ textField: { size: 'small', margin: "normal", fullWidth: true } }}
            renderInput={(params) => <TextField {...params} />}
          />
        <TextField size="small" label="Name *" name="name" value={event.name} onChange={handleInputChange} fullWidth margin="normal" />
        <TextField size="small" label="Description *" name="description" value={event.description} onChange={handleInputChange} fullWidth margin="normal" multiline rows={3} />
        <TextField size="small" label="Location *" name="location" value={event.location} onChange={handleInputChange} fullWidth margin="normal" />
        <TextField size="small" label="Cost *" name="cost" value={event.cost} onChange={handleInputChange} fullWidth margin="normal" />
        <TextField size="small" label="Discounted Cost" name="discountedCost" value={event.discountedCost} onChange={handleInputChange} fullWidth margin="normal" />
        <TextField size="small" label="Number Available *" name="numberAvailable" value={event.numberAvailable} onChange={handleInputChange} fullWidth margin="normal" />
        
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center", mb: 5 }}>
          <Button size="small" onClick={handleCardClick} variant="contained" color="grey" sx={{ mt: 3, color: "black"  }}>Preview</Button>
        </Box>
      </Box>

      <CustomDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} content={<EventDrawerContent isPreview={true} event={{ ...event, image: event.image.mediaUrl }}/>} height={windowHeight * 0.7} buttonAbsolute={true} />
    
    </LocalizationProvider>
  );
}

export default CreateEvent;
