import * as React from 'react';
import {
    AppBar, Box, Toolbar, IconButton, Typography, Menu, Modal,
    Avatar, Button, Tooltip, MenuItem, Badge, Container, ThemeProvider, createTheme
} from '@mui/material';
import { useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import SearchIcon from '@mui/icons-material/Search';  // Commented out SearchIcon
import { Link } from 'react-router-dom';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});


function NoH8() {
    return (
        <ThemeProvider theme={lightTheme}>
            <Container maxWidth='xl'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img
                                src="..\..\logo\BM8.svg"
                                alt="M8s Page"
                                style={{ maxWidth: '50px', marginTop: 20 }}
                            />
                            <Typography variant="h4" gutterBottom sx={{ marginTop: 1.5, fontWeight: 'bold', textAlign: 'center' }}>
                                M8 Code of Conduct
                            </Typography>
                        </div>

                        <Typography variant="h5" gutterBottom sx={{ marginTop: 1.5, fontWeight: 'bold'}}>
                            Purpose
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            M8 is committed to providing a safe and respectful environment for all users. This Code of Conduct outlines the principles and guidelines that users are expected to follow to maintain a positive and enjoyable experience on our platform.
                        </Typography>

                        <Typography variant="h5" gutterBottom sx={{ marginTop: 1.5, fontWeight: 'bold' }}>
                            User Responsibilities
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>1. Respect Others:</span> Treat all users with respect and kindness. Harassment, discrimination, or any form of abusive behavior will not be tolerated.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>2. Authenticity:</span> Provide accurate and truthful information in your profile. Do not impersonate others or create fake accounts.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>3. Consent:</span> Obtain consent before sharing personal information, images, or engaging in any offline interactions. Respect the privacy and boundaries of others.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>4. No Offensive Content:</span> Do not post or share content that is offensive, explicit, or violates community standards. This includes, but is not limited to, hate speech, nudity, or any form of explicit material.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>5. Reporting:</span> Report any inappropriate behavior or content to andrew.nolff@m8-us.com. We take user reports seriously and will investigate promptly.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>6. Safety First:</span> Prioritize your safety. Exercise caution when arranging to meet someone in person for the first time. Choose public places and inform a friend about your plans.
                        </Typography>

                        <Typography variant="h5" gutterBottom sx={{ marginTop: 1.5, fontWeight: 'bold' }}>
                            Prohibited Activities
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            The following activities are strictly prohibited on M8:
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>1. Harassment:</span> Any form of harassment, bullying, or intimidation.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>2. Scamming:</span> Engaging in fraudulent activities, scams, or solicitation.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>3. Illegal Activities:</span> Any activities that violate local, state, or federal laws.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>4. Impersonation:</span> Pretending to be someone else, including public figures or other users.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>5. Spam:</span> Sending unsolicited messages, advertisements, or promotional content.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>6. Minors:</span> Users of must be above the age of 18 to register and use the platform. By using M8, you affirm that you are of legal age in your jurisdiction. Any accounts found to be in violation of this age requirement may be subject to immediate suspension or termination.
                        </Typography>

                        <Typography variant="h5" gutterBottom sx={{ marginTop: 1.5, fontWeight: 'bold' }}>
                            Consequences of Violations
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>1. Warnings:</span> Users may receive warnings for minor infractions.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>2. Suspension:</span> Continued violations may lead to temporary suspension.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            <span style={{ fontWeight: 'bold' }}>3. Ban:</span> Severe or repeated violations may result in permanent bans from the platform.
                        </Typography>

                        <Typography variant="h5" gutterBottom sx={{ marginTop: 1.5, fontWeight: 'bold' }}>
                            Changes to the Code of Conduct
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            M8 reserves the right to update and modify this Code of Conduct as needed. Users will be notified of any changes.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            By using M8, users agree to adhere to this Code of Conduct. Failure to comply may result in account actions, including suspension or termination.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            For support or to report violations, contact andrew.nolff@m8-us.com.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            Thank you for contributing to a positive and respectful community on M8.
                        </Typography>

                        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                            M8 Team
                        </Typography>

                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 30 }}>
                            <img
                                src="..\..\logo\BM8.svg"
                                alt="M8s Page"
                                style={{ maxWidth: '50px', marginTop: 5 }}
                            />
                        </div>

                    </div>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default NoH8;
