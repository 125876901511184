import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { updateUser } from '../../../app/slices/user.slice';
import userService from 'api/services/userService';

import { Formik, Form, Field } from 'formik';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, TextField, Button } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditSectionHeader from '../EditSectionHeader';
import { BasicsForm } from 'components/forms/createFormComponent';

import dayjs from 'dayjs';

import '../EditProfileStyle.css'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    black: '#121212',
  },
});

function EditBasics({ setEdit }) {
  const mobileLayout = useSelector(state => state.global.isMobile);


  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const initialValues = {
    currentCity: user.basics.currentCity || '',
    genderIdentity: user.basics.genderIdentity || '',
    stageOfLife: user.basics.stageOfLife || [],
    religion: user.basics.religion || '',
    dateOfBirth: user.basics.dateOfBirth ? dayjs(user.basics.dateOfBirth) : null,
  };

  const [formValues, setFormValues] = useState(initialValues);

  const handleSave = async (values) => {
    const serializedValues = {
      ...values,
      dateOfBirth: values.dateOfBirth ? values.dateOfBirth.toISOString() : null,
    };
    const basics = serializedValues

    try {
      const response = await userService.modifyUserInfo({ basics });

      if (response) {
        const updatedUser = { ...user, basics };
        dispatch(updateUser(updatedUser));
      }

      setEdit(null);
    } catch (error) {
      console.error('Error updating user info:', error);
    }
  };

  const handleSubmit = (values) => {
    setFormValues(values);
  };

  return (
    <Box className={`profile-edit${mobileLayout ? '-mobile' : ""}`}>
      <BasicsForm 
        initialValues={formValues} 
        handleSave={handleSave} 
        onSubmit={handleSubmit}
        isEditMode={true} 
        setEdit={setEdit}
        margin={"normal"}
      />
    </Box>
  );
}

export default EditBasics;
