import React from "react"
import { isMobile } from "scripts/mobileCheck"

export const filledButtonStyle = {
    backgroundColor: 'var(--color-accent)', 
    borderRadius: '9999px',
    color: 'var(--color-main)', 
    textTransform: 'none',
    opacity: 1,
    transition: '0.2s',
    '&:hover': {
        backgroundColor: 'var(--color-accent)', 
        opacity: 0.7,
    }
}

export const outlinedButtonStyle = {
    marginTop: '0px',
    textTransform: 'none',
    borderRadius: '9999px',
    color: '#000',
    borderColor: 'var(--color-accent)',
    '&:hover': {
        borderColor: 'var(--color-accent)',
        backgroundColor: 'var(--color-accent)',
        color: 'var(--color-main)',
    }
}