// React, Redux, DOM essentialsw
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Formik Form essentials
import { Formik, Form, Field } from 'formik';

// Yup essentials
import * as Yup from 'yup';

// DayJS essentials
import dayjs from 'dayjs';

// MUI components
import { IconButton, Button, Container, Box, TextField, Typography} from '@mui/material';

// MUI icons
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// MUI functions & adapters
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Backend service calls
import userService from "../api/services/userService";

// Components
import OtpVerificationPage from './OtpVerificationPage'; // Import the OTP verification page

// MUI theming
import { ThemeProvider, createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

// Yup validation schema
const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required'),
  dateOfBirth: Yup.date()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Email address must match')
    .required('Required'),
  password: Yup.string()
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required')
});



function RegisterPage() {
  const mobileLayout = useSelector(state => state.global.isMobile);

  const navigate = useNavigate();
  const [referralParams] = useSearchParams();
  const [showOtpPage, setShowOtpPage] = useState(); // State to toggle showing OTP verification page

  // Obtain referral code from URL if registering user is referred by someone
  const referredCode = referralParams.get('referralID');

  useEffect(() => {
    userService.checkToken()
      .then((response) => {
        console.log(response)
        if (response.status === 200) {
          navigate("/")
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSubmit = async (values, actions) => {
    try {
      await userService.register(values);
      setShowOtpPage(true); // Show OTP verification page after registration
    } catch (error) {
      console.error(error);
      actions.setSubmitting(false);
    }
  };

  const maxDate = dayjs().subtract(18, 'year');

  /* Big M8 logo */
  const BigM8Logo = (
    <Box
      sx={{
          //backgroundColor: '#000', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 3,

      }}
    >
        <div className="logo-container">
            <img src='/logo/BM8.svg' alt="M8 register page logo" style={{ width: "75px" }} />
        </div>
    </Box>
  )

  /* Page title */
  const PageTitle = (
    <Box
      sx={{
        mt: 1,
        width: "100%",
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      {/* 
      <IconButton onClick={() => navigate(-1)} >
        <ArrowBackIosIcon fontSize='small' sx={{ ml: 1 }} />
      </IconButton>
      */}
      <Typography component="h1" variant="h5" fontWeight={'normal'} sx={{textAlign: 'center', width: '100%'}}>
        Account Registration
      </Typography>
    </Box>
  )

  /* Already have account button */
  const AlreadyAccount = (
    <Link to="/login" >
      <Button
          type="button"
          fullWidth
          variant="text"
          sx={
              mobileLayout ?
              { mt: 2, color: '#333', textTransform: 'none' }
              :
              { mt: -1, color: '#333', textTransform: 'none' }
          }
      >
          Already have an account? Sign in
      </Button>
    </Link>
  )

  return (
    <ThemeProvider theme={lightTheme}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container component="main" maxWidth="xs">

        {/* Big logo */}
        {BigM8Logo}

        <Box mt={8}></Box>

        {/* Page Title */}
        {PageTitle}

        <Box mt={3}></Box>

        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          {showOtpPage ? (
              <>
                <OtpVerificationPage />
                <Box mb={5}></Box>
              </>
            ) : (
              <Formik
                initialValues={{ 
                  name: '', 
                  dateOfBirth: null, 
                  email: '', 
                  confirmEmail: '', 
                  password: '', 
                  confirmPassword: '', 
                  referredCode: referredCode 
                }}
                validationSchema={SignupSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <Field as={TextField}
                      size="small"
                      name="name"
                      label="Name"
                      fullWidth
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                      margin="normal"
                    />
                    <Field as={DesktopDatePicker}
                      label="Date of Birth"
                      inputFormat="MM/DD/YYYY"
                      value={values.dateOfBirth}
                      maxDate={maxDate}
                      slotProps={{ textField: { 
                        size: "small",
                        margin: "normal", 
                        fullWidth: true,
                        error: touched.dateOfBirth && Boolean(errors.dateOfBirth),
                        helperText: touched.dateOfBirth && errors.dateOfBirth
                      }}}
                      onChange={value => setFieldValue("dateOfBirth", value)}
                      renderInput={(params) => 
                        <TextField
                          {...params}
                        />
                      }
                    />
                    <Field as={TextField}
                      size="small"
                      name="email"
                      label="Email Address"
                      fullWidth
                      error={touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      margin="normal"
                    />
                    <Field as={TextField}
                      size="small"
                      name="confirmEmail"
                      label="Confirm Email Address"
                      fullWidth
                      error={touched.confirmEmail && !!errors.confirmEmail}
                      helperText={touched.confirmEmail && errors.confirmEmail}
                      margin="normal"
                    />
                    <Field as={TextField}
                      size="small"
                      type="password"
                      name="password"
                      label="Password"
                      fullWidth
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      margin="normal"
                    />
                    <Field as={TextField}
                      size="small"
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      fullWidth
                      error={touched.confirmPassword && !!errors.confirmPassword}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      margin="normal"
                    />

                    {/* Register button */}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isSubmitting}
                      sx={{
                        textTransform: 'none',
                        mt: 6,
                        mb: 2,
                        backgroundColor: '#333', // dark gray
                        color: '#fff', // white text
                        '&:hover': {
                          backgroundColor: '#555', // slightly lighter gray on hover
                        } 
                      }}
                    >
                      Register
                    </Button>

                    {/* Already have account button */}
                    {AlreadyAccount}

                  </Form>
                  
                )}
              </Formik>
         )}
        </Box>
        
      </Container>
    </LocalizationProvider>
    </ThemeProvider>
  );
}

export default RegisterPage;
