import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '0em',
  marginLeft: '25px',
  marginRight: '25px'
});

export const InfoItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1em',
  marginLeft: '25px',
  marginRight: '25px'
});

export const LongTextTypography = ({ array }) => (
  <Box sx={{ maxWidth: '55vw' }}>
    <Typography variant="body2" style={{ wordBreak: 'break-word', textAlign: 'right' }}>
      {array && array.hasOwnProperty('length') && array.length ? array.join(", ") : "N/A"}
    </Typography>
  </Box>
);

export const OutlineButton = styled(Button)({
  mt: 2,
  marginTop: '0px',
  textTransform: 'none',
  borderRadius: '9999px',
  color: '#000',
  borderColor: 'var(--color-accent)',
  '&:hover': {
    borderColor: 'var(--color-accent)',
    backgroundColor: 'var(--color-accent)',
    color: 'var(--color-main)',
  }
  
});

export const FilledButton = styled(Button)({
  mt: 2,
  marginTop: '0px',
  textTransform: 'none',
  backgroundColor: 'var(--color-accent)', 
  '&:hover': {
      backgroundColor: '#555', 
  },
  borderRadius: '9999px',
  color: 'var(--color-main)'
  
});

