// React, Redux, DOM essentials
import React, { useEffect, useState, useReducer} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

// Redux store management functions
import { 
  updateCategories,
  setSelectedCategoryId,
  setChatSessionId
} from 'app/slices/chat.slice';

// Socket.IO essentials
import socket from 'socketio/socket';

// Backend service calls
import chatService from 'api/services/chatService';

// MUI components
import { 
  List, 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Typography,
  Box, 
  Avatar, 
  ButtonBase, 
  Tab, 
  Tabs 
} from '@mui/material';

// MUI icons
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// Components
import GroupChatList from 'components/groupchats/groupChatList/GroupChatList';

// Custom theme
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Custom hooks
import { useMouseHover } from 'hooks/onMouseHover';

// Custom functions
import { delay } from 'scripts/delay';


const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


export default function GroupChatListView({ group }) {
  const mobileLayout = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hovered, hoverEventHandlers] = useMouseHover();

  const groupChatId = useSelector(state => state.groupchats.groupChatSessionId); 
  const userId = useSelector(state => state.user.userId);
  const chats = useSelector(state => state.chats.groupChats);
  const chatUserAssociation = useSelector(state => state.chats.chatUserAssociation);

  // For showing a indicator in Chat Type menu if user has unread Chat (not Group Chat) message(s)
  const [hasUnreadMsg, setHasUnreadMsg] = React.useState(false);
  const getUnreadCount = async () =>{
    try {
      // serviceCode = 1: Count unread messages in ALL chat
      // serviceCode = 2: Count unread messages in SPECIFIC chat (In this case)
      // serviceCode = 3: Count unread messages across ALL chats in SPECFIC category 
      const counter = await chatService.getUnreadMessageCount( {userId: userId, serviceCode: 1});
      if (counter.data.unreadCounter > 0) {
        setHasUnreadMsg(true);
      } else {
        setHasUnreadMsg(false); 
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getUnreadCount();
  },[])

  /* Title and Back button */
  const handleBackButton = () => {
    
    dispatch(setSelectedCategoryId(null));
    
    // For some reason dispatches causes Chat component to malfunction,
    // reload fixes the issue.
    //socket.emit('refreshChatSignal',{});

  }
  const TitleNBackButton = (
    <Box
          sx={{ display: "flex", width: "100%", alignItems: "center"}}
        >
          {group && (
            <IconButton onClick={handleBackButton} >
              <ArrowBackIosIcon fontSize='small' sx={{ ml: 1 }} />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ ml: 1 }} fontWeight={'bold'}>
            
          </Typography>
      </Box>
  );

    /* Chat type menu */
    const [showChatTypeMenu, setShowChatTypeMenu] = useState(false);
    const [chatType, setChatType] = useState(1);
    const handleChatTypeChange = (event, newValue) => {
        setChatType(newValue);
    };
    const chatTypes = [
        {label: `M8 Chats ${hasUnreadMsg === true ? '•' : ''}`},
        {label: "Group Chats"},
    ];
    useEffect(() => {
        if (hovered === true) {
            setShowChatTypeMenu(true);
        } else {
            setShowChatTypeMenu(false);
        }
    },[hovered])
    useEffect(() => {
      switch (chatType) {
          case 0:
              navigate('/chat')
              break;
          case 1:
              void(0);
              break;
          default:
              void(0);
      }
  },[chatType])
    const ChatTypeMenu = (
    <Box
        {...hoverEventHandlers} 
        sx={
            {
                display: 'flex',
                justifyContent: 'center',
                minHeight: showChatTypeMenu ? 40 : 35, height: showChatTypeMenu ? 40 : 35,
                mb: showChatTypeMenu ? 2 : 0
            }
        }
    >
        {!showChatTypeMenu ?
            <Box
                sx={
                  {
                    bgcolor: 'var(--color-accent)', 
                    opacity: 0.2, 
                    borderRadius: 9999,
                    width: 30, 
                    height: 9, 
                    mt: 2
                  }
                }
            >
            </Box>
        :
            <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={chatType}
            onChange={handleChatTypeChange}
            aria-label="Vertical tabs example"
            textColor="var(--color-text)"
            indicatorColor="var(--color-separator)"
            sx={
                { 
                  color: 'var(--color-accent)',
                  borderRight: 0, 
                  borderColor: 'var(--color-accent)',
                  '.MuiTab-textColorPrimary': { color: 'var(--color-text)' },
                  '.MuiTabs-indicator': { backgroundColor: 'var(--color-separator)' },
                }
            }
            >
                {chatTypes.length > 0 && chatTypes.map((option, index) => 
                    <Tab key={index} label={option.label} sx={{alignItems: 'start', textTransform: "none"}} />
                )}
            </Tabs>
        }
        
    </Box>
    )


  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        className="chat-list"
        sx={
          mobileLayout ?
          { // Mobile layout
            width: '100%',
            height: '100%',

            display: groupChatId  === null ? "visible" : "none"
          }
          :
          { // PC layout
            mt: 2,

            border: '2px solid var(--color-accent)',
            borderRadius: 'var(--border-radius-main)',

            width: '420px',
            height: '85vh',
            '@media (max-height: 650px)': {
              height: '80vh'
            },
            '@media (max-height: 508px)': {
              height: '75vh'
            },

          }
        }
      >
        <Box sx={{ mt: 0, width: '100%' }}>
          {ChatTypeMenu}
          <GroupChatList chatTypeMenuVisible={showChatTypeMenu}/>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
