import React, { useState, useMemo } from 'react';

export const useMouseHover = () => {
    const [hovered, setHovered] = useState();
    
    const eventHandlers = useMemo(() => ({
      onMouseOver() { setHovered(true); },
      onMouseOut() { setHovered(false); }
    }), []);
    
    return [hovered, eventHandlers];
}