import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ListItem, ListItemAvatar, Avatar, ListItemText, AvatarGroup, Box, IconButton, Menu, MenuItem, Typography  } from "@mui/material";
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhotoRoundedIcon from '@mui/icons-material/PhotoRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { dialogButtonRegular } from "styles/dialogButtonStyles";
import { dialogButtonFocus } from "styles/dialogButtonStyles";

import socket from "socketio/socket";

export default function ChatMessageOptions ({message, userId, mobileLayout}) {
  
  // Handle dropdown menu button
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  // Drop down menu functions
  // -------------- Edit chat -------------- //
  // Edit chat dialog handle
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditDialogOpen = () => {
    setEditDialogOpen(true);
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };
  const editChat = () => {

  }
  /* Edit chat dialog element */
  const EditChatDialog = (
    <Dialog
      open={editDialogOpen}
      onClose={handleEditDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Edit chat message"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <p>Edit chat message</p>
        <small>Note: M8s can still see this message unless it is unread.</small>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={dialogButtonRegular} onClick={handleEditDialogClose}>
          Cancel
        </Button>
        <Button sx={dialogButtonFocus} onClick={() => {handleEditDialogClose(); editChat()}} autoFocus>
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  )

  // -------------- Delete chat -------------- //
  // Delete chat dialog handle
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };
  // Delete chat function
  const deleteChat = async () => {
    //const response = await messageService.deleteChatMessage({messageId: message._id, userId: userId}).then((res) => {
    //    
    //});
    socket.emit('deleteGroupChatMessage', {message: message, userId: userId})
  }
  /* Delete chat dialog element */
  const DeleteChatDialog = (
    <Dialog
      open={deleteDialogOpen}
      onClose={handleDeleteDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Delete this message?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <p>This message will be permanently deleted from you view. </p>
        <small>Note: M8s can still see this message unless it is unread.</small>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={dialogButtonRegular} onClick={handleDeleteDialogClose}>
          Cancel
        </Button>
        <Button sx={dialogButtonFocus} onClick={() => {handleDeleteDialogClose(); deleteChat()}} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )

  /* Drop down menu */
  const chatOptions = [
    //{ icon: <BorderColorRoundedIcon fontSize='inherit'/>, text: 'Edit', function: handleEditDialogOpen },
    { icon: <DeleteRoundedIcon fontSize='inherit'/>, text: 'Delete', function: handleDeleteDialogOpen }
  ];
  const ITEM_HEIGHT = 48;
  const DropDownMenu = (<>
    <Menu
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      keepMounted
      elevation={1}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: 'auto'
        },
      }}
    >
      {chatOptions.length && chatOptions.map((option, index) => {
        return (
            <MenuItem key={index} onClick={() => {handleClose(); option.function();}}>
                <Typography textAlign="center">{option.icon}&nbsp; &nbsp;{option.text}</Typography>
            </MenuItem>
        )
      })}
    </Menu>
  </>)

  return (<Box>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                    opacity: 1,
                    width: 1,
                    //"&:hover": {
                    //opacity: 0.5,
                    ////backgroundColor: 'transparent'
                    //}
                }}
                >
                <MoreVertIcon />
            </IconButton>

            {DropDownMenu}

            {/* Dialogs */}
            {EditChatDialog}
            {DeleteChatDialog}
  </Box>)
}