// React, Redux, DOM essentials
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { useDispatch, useSelector } from 'react-redux';

// MUI components
import { 
  ListItem, 
  ListItemAvatar, 
  ListItemText, 
  Avatar, 
  Box, 
  Typography, 
  ButtonBase 
} from '@mui/material';

// MUI icons
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import StarIcon from '@mui/icons-material/Star';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';

// Backend service calls
import userService from 'api/services/userService';
import chatService from 'api/services/chatService';
import groupChatService from 'api/services/groupchatService';

// Socket.IO
import socket from 'socketio/socket';

// Redux state store function
import {
  setGroupChatSessionId,
  updateGroupSessionAssociation, 
} from 'app/slices/groupchat.slice';

// Custom scripts
import { delay } from 'scripts/delay';




export default function GroupChatListItem({ groupChat }) {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.user.userId);
  const currentGroupChatId = useSelector(state => state.groupchats.groupChatSessionId);
  const groupChatUserAssociations = useSelector(state => state.groupchats.groupChatUserAssociations);
  const [groupChatUserAssociation, setGroupChatUserAssociation] = useState({});

  const lastMessage = useSelector(state => state.chats.lastMessages[groupChat._id])
  const [friend, setFriend ] = useState({});

  // Get unread message count for individual chat in list.
  const [unreadCount, setUnReadCount] = React.useState(0);
  const user = useSelector(state => state.user)
  const getUnreadCount = async () =>{
    try {
      // serviceCode = 1: Count unread messages in ALL chat
      // serviceCode = 2: Count unread messages in SPECIFIC chat (In this case)
      // serviceCode = 3: Count unread messages across ALL chats in SPECFIC category 
      const counter = await groupChatService.getUnreadMessageCount( {userId: user.userId, serviceCode: 2, groupChatId : groupChat._id} );
      setUnReadCount(counter.data.unreadCounter);
    } catch (error) {
      throw error;
    }
  };

  // Group Chat list item style (changes for current selected chat)
  const [listItemBodyColor, setListItemBodyColor] = useState('');
  useEffect(() => {
    currentGroupChatId === groupChat._id ? 
      setListItemBodyColor('var(--color-accent2)') 
    : 
      setListItemBodyColor('');
  },[currentGroupChatId])

  // Find assocaition between current user and Group Chat
  const assignGroupChatUserAssociation = async () => {
    setGroupChatUserAssociation(groupChatUserAssociations[groupChat._id]);
  }
  useEffect(() => {
    assignGroupChatUserAssociation();
  },[])

  // For detecting Group Chat and User association update
  useEffect(() => {
    assignGroupChatUserAssociation();
  },[groupChatUserAssociations])

  /* Indicator for Group Chats created by current user */
  const Indicators = (<>

    {/* Unread message count */}
    {unreadCount !== 0 ?
      <Box 
      sx={{
        backgroundColor : "var(--color-accent)", 
        color : "var(--color-main)", 
        borderRadius : "50%", 
        padding: "0px", 
        paddingLeft: "6px",
        paddingRight: "6px",
        marginLeft: "15px", 
        textAlign : "center",
        mr: 2
      }}
      > 
        <Typography fontWeight={'bold'} fontSize={12}>{unreadCount}</Typography>
      </Box>
    :
      <></>
    }
    {/* Indicator for Group Chats created by current user */}
    {groupChat.hostUserId === userId ?
      <Box>
        <StarIcon fontSize='inherit'/>
      </Box>
    :
      <></>
    }
    {/* Await invite respond indicator */}
    {groupChatUserAssociation && Object.keys(groupChatUserAssociation).length > 0 && 
      groupChatUserAssociation.acceptedInvite === false ?
        <Box>
          <AutoAwesomeRoundedIcon fontSize='inherit'/>
        </Box>
      :
        <></>
    }
    
  </>)

  // Get unread message count
  useEffect(() => {
    getUnreadCount(); // Check for unread messages on app launch first

    const unreadCountFunctionCall = () => {
      //dispatch(incrementUnreadCount());
      getUnreadCount();
    }
    
    socket.on('newChat', unreadCountFunctionCall);
    socket.on("receiveMessage", unreadCountFunctionCall);
    socket.on('chatRead', unreadCountFunctionCall);

    return () => {
      socket.off('newChat', unreadCountFunctionCall);
      socket.off('receiveMessage', unreadCountFunctionCall);
      socket.off('chatRead', unreadCountFunctionCall);
    };
  }, [socket]);
  
  // Group Chat item click handler
  const itemClickHandle = async () => {

    // Set Group Chat session
    dispatch(setGroupChatSessionId(groupChat._id)); 

    // Set Group Chat user assocation
    dispatch(updateGroupSessionAssociation(groupChatUserAssociation));

    // Set chat in session as Read immediately
    //socket.emit('readChat', { receiverId: userId, chatId: chat._id })
    let delayer = await delay(1);
    getUnreadCount();
  }

  

  return (
    <ButtonBase 
      component="div"
      sx={{ 
        width: "90%", 
        backgroundColor: listItemBodyColor,        
        borderRadius: 'var(--border-radius-main)',
        "&:hover": {
          cursor: 'pointer'
        } 
      }} 
    >
      <ListItem /*component={Link} to={`/chat/${chat._id}`}*/ 
        onClick={itemClickHandle}
      > 
          <ListItemAvatar>
            <Avatar alt={groupChat.name && groupChat.name} src={Object.hasOwn(friend, 'images') && friend.images.length > 0 ? friend.images[0].mediaUrl : 'unknown'} >
              <PeopleAltRoundedIcon/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{
              textDecoration: "none", 
              color: "var(--color-accent)",
              '.MuiListItemText-secondary': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
            primary={groupChat.name ? groupChat.name : 'unknown group'} 
            //secondary={lastMessage.content} 
          />
          {Indicators}

      </ListItem>
    </ButtonBase>  
   
    
  );
}