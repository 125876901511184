import axios from "../axios"

const getUserSetting = async () => {
    try {
        const response = await axios.get(`/setting/getUserSetting`);
        return response;
      } catch (error) {
        throw error;
      }
};

const updateUserSetting = async (body) => {
    try {
        const response = await axios.post(`/setting/updateUserSetting`,body);
        return response.data;
      } catch (error) {
        throw error;
      }
};

const settingService = {
    getUserSetting,
    updateUserSetting
  };
  
  export default settingService;