import React from 'react';
import { Field } from 'formik';
import { TextField } from '@mui/material';

const CustomTextField = ({ fieldConfig, values, setFieldValue, margin }) => (
  <Field 
    as={TextField} 
    size="small" 
    name={fieldConfig.name} 
    label={fieldConfig.label} 
    margin={margin} 
    onChange={(e) => setFieldValue(fieldConfig.name, e.target.value)}
    value={values[fieldConfig.name]}
    key={fieldConfig.name} 
  />
);

export default CustomTextField;
