import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

// Non-auth visitor pages
import LoginPage from "./pages/Login";
import RegisterPage from "./pages/RegisterPage";
import AuthLayout from "./components/containers/AuthLayout"
import OtpVerificationPage from "./pages/OtpVerificationPage"; 
import Forget from "./pages/ForgetPasswordPage";
import PasswordResetPage from './pages/PasswordResetPage';
import ReferredPage from "pages/ReferredPage";

// Authed user pages
import HomePage from "pages/Explore";
import ChatPage from "pages/ChatPage";
import GroupChatPage from "pages/GroupChatPage";
import SearchPage from "pages/SearchPage"
import AdminPage from "pages/AdminPage";
import YM8 from "pages/YM8";
import How2M8 from "pages/How2M8";
import NoH8 from "pages/NoH8";
import Difference from "pages/Difference";
import EventsPage from "pages/EventsPage";
import EventsPageOld from "pages/EventsPageOld";

// User profile edit pages
import EditBasics from "components/editUserProfile/sections/EditBasics";
import UserProfilePage from "pages/UserProfilePage";
import ReferralPage from "pages/ReferralPage";
import SettingsPage from "pages/SettingsPage";

// 404 page
import EmptyPage from "pages/EmptyPage";

// Redux store management
import { updateUser } from './app/slices/user.slice';

// MUI theming
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for (let registration of registrations) {
          registration.update();
        }
      });

      navigator.serviceWorker.addEventListener('controllerchange', function() {
        window.location.reload();
      });
    }
  }, []);

  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
          <Routes>
            {/* These pages are publically accessable*/}
            <Route>
              {/* Account management */}
              <Route exact path="/login" element={<LoginPage />} />
              <Route exact path="/register" element={<RegisterPage />} />
              <Route exact path="/forgot-password" element={<Forget />} />
              <Route path="/reset-password/:token" element={<PasswordResetPage />} />
              <Route exact path="/verify-email/" element={<OtpVerificationPage />} />
              {/* Referral (receiver) */}
              <Route exact path="/refer" element={<ReferredPage />} />
            </Route>

            {/* These pages only accessable when user is login*/}
            <Route element={<AuthLayout />}>
              {/* Home Page */}
              <Route path="/" element={<HomePage />} />
                {/* Redirects */}
                <Route exact path="/home" element={<Navigate to="/" replace/>} /> 
                <Route exact path="/main" element={<Navigate to="/" replace/>} /> 
                <Route exact path="/index" element={<Navigate to="/" replace/>} /> 
                <Route exact path="/explore" element={<Navigate to="/" replace/>} /> 

              {/* User Profile */}
              <Route exact path="/user-profile" element={<UserProfilePage editable={true}/>} />
              <Route exact path="/edit-basics" element={<EditBasics />} />

              {/* Chat */}
              <Route exact path="/chat" element={<ChatPage />} />
                {/* Redirects */}
                <Route exact path="/chats" element={<Navigate to="/chat" replace/>} /> 
                <Route exact path="/message" element={<Navigate to="/chat" replace/>} /> 
                <Route exact path="/messages" element={<Navigate to="/chat" replace/>} /> 
                <Route exact path="/m8" element={<Navigate to="/chat" replace/>} /> 
                <Route exact path="/m8s" element={<Navigate to="/chat" replace/>} />
                {/*<Route exact path="/category" element={<Navigate to="/chat" replace/>} /> */}
              <Route exact path="/chat/:chatId" element={<ChatPage />} />

              {/* Group Chat */}
              <Route exact path="/group-chat" element={<GroupChatPage />} />
                {/* Redirects */}
                <Route exact path="/group-chats" element={<Navigate to="/group-chat" replace/>} /> 
                <Route exact path="/groupchat" element={<Navigate to="/group-chat" replace/>} /> 
                <Route exact path="/groupchats" element={<Navigate to="/group-chat" replace/>} /> 

              {/* Events */}
              <Route exact path="/event" element={<EventsPage />} />
                {/* Redirects */}
                <Route exact path="/events" element={<Navigate to="/event" replace/>} /> 
                <Route exact path="/d8" element={<Navigate to="/event" replace/>} /> 
                <Route exact path="/d8s" element={<Navigate to="/event" replace/>} /> 
              <Route exact path="/event-legacy" element={<EventsPageOld />} />

              {/* Search */}
              <Route exact path="/search" element={<SearchPage />} />
                {/* Redirects */}
                <Route exact path="/find" element={<Navigate to="/search" replace/>} /> 

              {/* Settings */}
              <Route exact path="/settings" element={<SettingsPage />} />
                {/* Redirects */}
                <Route exact path="/setting" element={<Navigate to="/settings" replace/>} /> 
                <Route exact path="/options" element={<Navigate to="/settings" replace/>} /> 
                <Route exact path="/option" element={<Navigate to="/settings" replace/>} /> 
                <Route exact path="/configs" element={<Navigate to="/settings" replace/>} /> 
                <Route exact path="/config" element={<Navigate to="/settings" replace/>} /> 

              {/* Referral (sender) */}
              <Route exact path="/referral" element={<ReferralPage />} />

              {/* Others */}
              <Route exact path="/YM8" element={<YM8 />} />
              <Route exact path="/How2M8" element={<How2M8 />} />
              <Route exact path="/NoH8" element={<NoH8 />} />
              <Route exact path="/Difference" element={<Difference />} />

              {/* Admin */}
              <Route exact path="/admin" element={<AdminPage />} />

              {/* Handle nonexisting pages (404) */}
              <Route path="/*" element={<EmptyPage />} />

            </Route>
          </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
