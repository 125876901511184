// React, Redux, DOM essential
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// Backend service call
import userService from "../api/services/userService";

// Redux store function calls
import { updateUser } from '../app/slices/user.slice';

// Socket.IO
import socket from '../socketio/socket';

// MUI component
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { 
    Container, 
    TextField, 
    Button, 
    Typography, 
    Box, 
    Modal, 
    Backdrop, 
    Fade, 
    Alert,
    Checkbox
} from '@mui/material';

// MUI icons
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

// Custom styles
import '../App.css'
import { popupStyle } from 'components/login/styles/popupStyles';

// Custom components
import YM8 from 'components/login/popups/YM8';
import How2M8 from 'components/login/popups/How2M8';
import TheDifference from 'components/login/popups/TheDifference';
import WhatIsM8 from 'components/login/popups/WhatIsM8';

// Custom scripts


const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mobileLayout = useSelector(state => state.global.isMobile);

    // Skip login if logged in
    useEffect(() => {
        userService.checkToken()
            .then((response) => {
                if (response.status === 200) {
                    navigate("/")
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    // Login button handle
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [keepLogin, setKeepLogin] = useState(false);
    const [verifyStat, setVerifyStat] = useState(localStorage.getItem("account-verified")); // For displaying success verification alert
    const [errMsg, setErrMsg] = useState('');
    const [errCode, setErrCode] = useState(102);

    // Popup states
    const [openPopup1, setOpenPopup1] = useState(false); // YM8
    const [openPopup2, setOpenPopup2] = useState(false); // How2M8
    const [openPopup3, setOpenPopup3] = useState(false); // TheDifference
    const [openPopup4, setOpenPopup4] = useState(false); // WhatIsM8
    
    // Login form submission
    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        try {
            const response = await userService.login({ email, password, keepLogin });

            if (response.status === 200) {
                setVerifyStat(false);
                setErrCode(response.status)
                localStorage.removeItem("account-verified")
                navigate("/")
            } else if (response.status === 401) {
                setErrCode(response.status)
                setErrMsg('Incorrect email or password. Please try again.');
            } else if (response.status === 403) {
                setErrCode(response.status)
                setErrMsg('Please check your email inbox (and spam folder) for verification.');
            }
        } catch (error) {
            console.error(error); 
        }
    };

    // Keep Login checkbox handle
    const handleKeepLoginChange = (event) => {
        setKeepLogin(event.target.checked);
    }

    // Clear errors on entering email and/or password
    useEffect(() => {
        setErrCode(102);
        setErrMsg('');
    }, [email, password]);

    /* Big logo */
    const BigLogo = (
        <Box
            sx={
                mobileLayout ?
                { // Mobile
                    backgroundColor: '#000', 
                    bgcolor: '#000',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }
                :
                { // PC
                    backgroundColor: '#fff',
                    width: '50%',
                }
        }
        >
            <div 
                style={
                    mobileLayout ?
                    { // Mobile
                        width: '100%', /* Full width */
                        height: '30vh', /* 35% of the viewport height */
                        backgroundColor: '#000', /* Black background */
                        display: 'flex',
                        justifyContent: 'center', /* Center content horizontally */
                        alignItems: 'center' /* Center content vertically */
                    }
                    :
                    { // PC
                        width: '100%', /* Full width */
                        height: '100vh', /* 100% of the viewport height */
                        backgroundColor: '#000', /* Black background */
                        display: 'flex',
                        justifyContent: 'center', /* Center content horizontally */
                        alignItems: 'center' /* Center content vertically */
                    }
                }
            >
                <img 
                    src='/logo/WM8.svg' 
                    alt="M8 logo" 
                    // Enlarge logo for PC
                    style={ mobileLayout ?
                            { width: "75px" }
                            :
                            { width: "10vw" }
                        } 
                />
            </div>
        </Box>
    )

    /* Login form */
    const LoginForm = (
        <Box 
            component="form" 
            noValidate
            sx={
                mobileLayout ?
                { // Mobile
                    mt: 1,
                }
                :
                { // PC
                    mt: 1,
                    width: '400px',
                }
            }
            onSubmit={handleLogin}
        >
            {/* "What is M8?" button */}
            <Button
                type="button"
                variant="contained"
                sx={{
                    textTransform: 'none',
                    width: '100%',
                    mt: 1,
                    mb: 2,
                    backgroundColor: 'var(--color-main)', 
                    color: 'var(--color-accent)', 
                    border: '2px solid var(--color-accent)', 
                    '&:hover': {
                        backgroundColor: 'var(--color-accent)', 
                        color: 'var(--color-main)', 
                    }
                }}
                onClick={() => setOpenPopup4(true)}
            >
                What is M8?
            </Button>

            

            {/* Display success alert for recent verified user */}
            { verifyStat ?
            <Alert severity="success" onClose={() => {setVerifyStat(false);localStorage.removeItem("account-verified")}}>
                Account verification successful!
            </Alert>
            :
            <></>
            }
            {/* Display failed login alert for failed login attempts */}
            { errCode === 401 &&
            <Alert severity="error" onClose={() => {setErrCode(102)}}>
                {errMsg}
            </Alert>
            }
            {/* Display email verification msg for unverified users */}
            { errCode === 403 &&
            <Alert severity="info" onClose={() => {setErrCode(102)}}>
                {errMsg}
            </Alert>
            }

            {/* Email field */}
            <TextField
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                error={errCode !== 401 ? false : true}
                onChange={(e) => setEmail(e.target.value)}
            />
            {/* Password field */}
            <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                error={errCode !== 401 ? false : true}
                onChange={(e) => setPassword(e.target.value)}
            />
            {/* Keep Login checkbox */}
            <Typography textAlign={'left'} fontSize={15} mt={1} >
                <Checkbox 
                    checked={keepLogin} 
                    onChange={handleKeepLoginChange} 
                    sx={{ 
                        padding: 0, 
                        mr: 1,
                        '&.Mui-checked': {
                            color: 'var(--color-accent)',
                        }
                    }}
                /> 
                <span style={{ opacity: 0.5 }} >
                    Keep me logged in for 30 days
                </span>
                
            </Typography>

            {/* Login button */}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                    textTransform: 'none',
                    mt: 4,
                    mb: 2,
                    backgroundColor: 'var(--color-accent)', // Set the background color 
                    color: 'var(--color-text-invert)', // Set the text color
                    border: '2px solid var(--color-accent)', // Set a border
                    '&:hover': {
                        backgroundColor: 'var(--color-main)', // Change background color on hover
                        color: 'var(--color-text)', // Change text color on hover
                    }
                }}
            >
                Log In
            </Button>

            {/* Forgot Password */}
            <Button
                component={Link} // Directly use Link here
                to="/forgot-password"
                fullWidth
                variant="contained"
                sx={{
                    textTransform: 'none',
                    mt: 1, // Adjust margin as needed
                    mb: 2,
                    backgroundColor: 'var(--color-accent)', // Set the background color 
                    color: 'var(--color-text-invert)', // Set the text color
                    border: '2px solid var(--color-accent)', // Set a border
                    '&:hover': {
                        backgroundColor: 'var(--color-main)', // Change background color on hover
                        color: 'var(--color-text)', // Change text color on hover
                    }
                }}
            >
                Forgot Password?
            </Button>
            <Link to="/register" >
                <Button
                    type="button"
                    fullWidth
                    variant="text"
                    sx={
                        mobileLayout ?
                        { mt: 2, color: '#333', textTransform: 'none' }
                        :
                        { mt: -1, color: '#333', textTransform: 'none' }
                    }
                >
                    Don't have an account? Sign up
                </Button>
            </Link>
        </Box>
    )

    return (
        <ThemeProvider theme={lightTheme}>
            <Container 
                className="login-container" 
                component="main" 
                maxWidth={mobileLayout ? "xs" : ""} // Use different container width on PC/Tablets
                sx={{ padding: 0 }}
                disableGutters // Disable those weird paddlings on sides
            >
                <Box
                    sx={
                        mobileLayout ?
                        { // Mobile
                            display: "block"
                        }
                        :
                        { // PC
                            display: "flex",
                            justifyContent: "center", 
                            alignItems: "center", 
                            height: "100%" 
                        }
                    }
                >
                    {/* Big logo */}
                    {BigLogo}

                    {/* User interaction area */}
                    <Box
                        className="login-userinputs" 
                        sx={
                            mobileLayout ?
                            { // Mobile
                                marginTop: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 3,
                            }
                            :
                            { // PC
                                marginTop: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 3,
                                width: '50vw'
                            }
                        }
                    >
                        {/* Login form */}
                        {LoginForm}

                        {/* What is M8 */}
                        <WhatIsM8 openPopup={openPopup4} setOpenPopup={setOpenPopup4}/>
                        {/* YM8 */}
                        <YM8 openPopup={openPopup1} setOpenPopup={setOpenPopup1}/>
                        {/* How2M8 */}
                        <How2M8 openPopup={openPopup2} setOpenPopup={setOpenPopup2}/>
                        {/* Difference */}
                        <TheDifference openPopup={openPopup3} setOpenPopup={setOpenPopup3}/>

                            
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default LoginPage;