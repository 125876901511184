import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    AppBar, Box, Toolbar, IconButton, Typography, Menu,
    Avatar, Button, Tooltip, MenuItem, Badge, Container, ThemeProvider, createTheme
} from '@mui/material';

function YM8Content() {
    return (
        <>
            <Typography variant="h3" gutterBottom sx={{ marginTop: 3, fontWeight: 'bold', textAlign: 'center'  }}>
                YM8
            </Typography>
            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                The prevailing apps in the market leave much to be desired. Despite my personal success using them in the past, leading to marriage and parenthood, I find the current apps undervalue users' time. Unless opting for a pricey premium, one must be cautious with limited swipes, prioritizing genuine interest. The ubiquitous mindless swiping technique emphasizes looks over substance, hindering meaningful connections.
            </Typography>

            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                Enter M8, a paradigm shift. Our focus is on user value, not just profits. We believe excellence leads to success and to succeed M8 will revolve around two core principles:
            </Typography>

            <Typography variant="h7" gutterBottom sx={{ marginLeft: 2, marginTop: 1.5, fontWeight: 'bold' }}>
                1. Efficient Search:
            </Typography>
            <Typography paragraph sx={{ marginLeft: 6, marginTop: 1, fontSize: '1rem' }}>
                M8 streamlines user searches, allowing precise filtering based on various criteria - age, religion, race, interests, and more. While only name, age, and email address are required, users will find that the more they put into M8, the more they will get out of it, empowering users to find other M8s within minutes rather than hundreds of hours of swiping.
            </Typography>

            <Typography variant="h7" gutterBottom sx={{ marginLeft: 2, marginTop: 1.5, fontWeight: 'bold' }}>
                2. Real Connections:
            </Typography>
            <Typography paragraph sx={{ marginLeft: 6, marginTop: 1, fontSize: '1rem' }}>
                M8 incentivizes real-life interactions. We collaborate with vendors for D8s (date coupons), encouraging users to meet and share unique experiences. These incentives go beyond monetary value, fostering genuine connections through meaningful conversations in the hope that users will come to a deeper understanding of each other.
            </Typography>
            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                While inspired by dating apps, M8 transcends into a friendship platform. Navigating new cities or seeking companionship becomes effortless. M8 dismantles barriers - whether you're busy, shy, or unsure where to find like-minded individuals. With extensive user information, finding someone with 25+ common interests becomes a reality and increases the chances of a meaningful connection. D8s provide a little nudge of encouragement and creative spark to break users out of their shells and truly get to know each other.
            </Typography>
            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                M8 is your gateway to genuine happiness, breaking the monotony of existing social platforms. Access more, connect more, and experience more with M8.
            </Typography>
            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                If you've made it this far I truly appreciate you taking the time to learn more about M8 and I hope you enjoy and value our product the way we intend the world to. Good luck and good day M8s!
            </Typography>
        </>
    );
}

export default YM8Content;