// React essentials
import React, { useState } from "react";

// MUI components
import { 
    Fade,
    Modal,
    Box,
    Button,
    Backdrop,
    Typography,
} from "@mui/material";

// Popup content
import How2M8Content from "components/help/How2M8";

// Custom styles
import { popupStyle } from "../styles/popupStyles";

export default function How2M8({openPopup, setOpenPopup}) {
    const How2M8 = (<>
        {/*
        <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{
                textTransform: 'none',
                mt: 1,
                mb: 0,
                backgroundColor: 'var(--color-main)', // Set the background color to white
                color: 'var(--color-accent)', // Set the text color to black
                border: '2px solid var(--color-accent)', // Set a black border
                '&:hover': {
                    backgroundColor: 'var(--color-accent)', // Change background color on hover
                    color: 'var(--color-main)', // Change text color on hover
                }
            }}
            onClick={() => {
                setOpenPopup2(true); // Open the popup
            }}
        >
            How2M8
        </Button>
        
        {/* Popup }
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openPopup2}
            onClose={() => setOpenPopup2(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openPopup2}>
                <Box sx={popupStyle}>
                    <Box sx={{ position: 'fixed', top: 10, right: 10, zIndex: 1 }}>
                        <Button
                            onClick={() => setOpenPopup2(false)}
                            sx={{
                                fontSize: '20px', // Set the font size to make it bigger
                                fontWeight: 'bold', // Set the font weight to bold
                                color: 'black', // Set the font color to black
                            }}
                        >
                            X
                        </Button>
                    </Box>
                            <How2M8Content/>
                    <Button onClick={() => setOpenPopup2(false)}>Close</Button>
                </Box>
            </Fade>
        </Modal>
        */}
    </>)

    return (How2M8)
}