import React from 'react';
import EventTable from 'components/admin/events/EventTable';

export default function EventsPage() {
    return (
        <div className="Event">
            <div id="google-sheets-data">
                <EventTable />
            </div>
        </div>
    );
}