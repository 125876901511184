import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import PopupSlideshow from '../components/popup/Slideshow/PopupSlideshow';
import SearchPageTutorial from '../components/tutorial/Search/SearchPageTutorial';

import SearchResults from '../components/search/SearchResults';
import Search from '../components/search/Search';

import { isMobile } from '../scripts/mobileCheck'
import HelpButton from 'components/tutorial/HelpButton';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

export default function SearchPage() {
  const [results, setResults] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState({
    basics: {
      currentCity: [],
      genderIdentity: '',
      stageOfLife: [],
      religion: '',
      ageRange: [18, 100]
    },
    career: {
      schools: [],
      occupation: [],
      classes: [],
      company: '',
      income: '',
    },
    physical: {
      heightRange: [24, 108],
      weightRange: [50, 600],
      ethnicity: [],
      eyeColor: [],
      hairColor: [],
      bodyType: '',
      disability: [],
    },
    personality: {
      personalityType: [],
      introExtroVert: [],
      morningNight: [],
      favoriteEmojis: [],
      relationshipIntent: '',
      friendIntent: '',
      idealFD8: [],
      idealRD8: [],
      coreValues: [],
    },
    theTea: {
      interests: [],
      drugsAlcohol: [],
      music: [],
      tvMovies: [],
      comedians: [],
      sportsTeams: [],
      citiesVisitedLived: [],
      politics: [],
      sexuality: [],
      roommatePreference: [],
    }
  });
  
  // First-time User Experience: Show tutorial popup for first-time users 
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const tutorialPopup = (
      <PopupSlideshow 
        content={<SearchPageTutorial tutorialVisible={tutorialVisible} setTutorialVisible={setTutorialVisible}/>} 
        visible={tutorialVisible}
        setVisible={setTutorialVisible}
        backdrop={true}
        association={"first-time-search"}
      />
  );
  // Floating help button logic 
  const handleHelpButtonClick = () => {
    localStorage.setItem("first-time-search", true);
    setTutorialVisible(true);
  }
  useEffect(() => {
    localStorage.getItem("first-time-search") && setTutorialVisible(true);
  },[])

  return (
    <ThemeProvider theme={lightTheme}>

      {/* First time user tutorial */}
      {tutorialPopup}

      {/* Main container */}
      <Box className="search-page-container" sx={{display: 'flex', justifyContent: 'center', width: '100%', height: '82vh', mt: 4, gap: 2, }}>
        <>
          {
            isMobile() ?
              ( // On mobile, only show search form, then show result in full replacing the search form
                <>
                  {
                    results ?
                      <SearchResults results={results ? results : []} setResults={setResults} />
                    :
                      <Search searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setResults={setResults} /> 
                  }
                </>
              )
            : // On PC, show both search results and search form all the time as there's enough space
              (
                <>
                  <Search searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setResults={setResults} /> 
                  <SearchResults results={results ? results : []} setResults={setResults} />
                </>
              )
          }
        </>
      </Box>

      {/*<HelpButton onClick={handleHelpButtonClick}/>*/}
    </ThemeProvider>
  );
}
