import React from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

import MemberProfile from "components/userProfile/MemberProfile";
import BottomDrawer from 'components/drawer/Drawer';

export default function GroupChatMemberProfile({profileDrawerVisible, toggleProfileDrawer}) {
    const mobileLayout = useSelector(state => state.global.isMobile);

    const selectedGroupMember = useSelector(state => state.groupchats.groupChatMemberDetail);

    const NoProfile = (
        <Box
                className="chat-profile-empty"
                sx={
                mobileLayout ?
                { // Mobile layout
                    width: '100%',
                    height: '100%',
                }
                :
                { // PC layout
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',

                    width: '100%',
                    height: '100%',

                    fontSize: '100px',

                    opacity: 0.5
                }
                }
            >
                {!mobileLayout && <AccountCircleRoundedIcon fontSize='inherit' />}
        </Box>
    )

    return (
        <Box
            className="chat-profile"
            sx={
            mobileLayout ?
            { // Mobile layout
                display: "none",//profileDrawerVisible ? "visible" : "none"
            }
            :
            { // PC layout
                opacity: 1,

                mt: 2,

                border: '2px solid var(--color-accent)',
                borderRadius: 'var(--border-radius-main)',

                width: '420px',
                height: '85vh',
                '@media (max-height: 650px)': {
                    height: '80vh'
                },
                '@media (max-height: 508px)': {
                    height: '75vh'
                },
                '@media (max-width: 1170px)': {
                    opacity: 0,
                    width: '1px',
                },

                overflowY: 'scroll'

            }
            }
        >

            {Object.keys(selectedGroupMember).length > 0 ?
            (mobileLayout ?
                <BottomDrawer 
                    isDrawerOpen={profileDrawerVisible} 
                    toggleDrawer={toggleProfileDrawer} 
                    content={<MemberProfile {...selectedGroupMember} chatPageLayout={true}/>} 
                />
                :
                <MemberProfile {...selectedGroupMember} chatPageLayout={true}/>
            )
            :
            (
                NoProfile
            )
            }
        </Box>
    )
}