import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Tabs, Tab, Container, ThemeProvider, createTheme, Button, Typography, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import CSVReader from "react-csv-reader";

import EventCarousel from '../components/admin/events/EventCarousel';
import AdminUserSearch from 'components/admin/users/AdminUserSearch';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: grey[900],
          },
        },
      },
    },
  },
});

function AdminPage() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [editView, setEditView] = useState(null);
  const [eventData, setEventData] = useState(null)

  // Prohibit normal users from using admin features
  const role = useSelector(state => state.user.role);
  useEffect(() => {
    if (role !== 'admin') navigate(-1);
  }, [role, navigate]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setEditView(null); // Reset edit view when changing tabs
  };

  // CVS file upload and parsing
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const onD8CSVLoaded = (data, fileInfo) => {
    const processedData = data.map(event => {
      let newEvent = {...event};
  
      newEvent.eventDateTime = dayjs();
  
      Object.keys(newEvent).forEach(key => {
        if(newEvent[key] === null) {
          newEvent[key] = '';
        }
      });
  
      return newEvent;
    });
  
    console.log(processedData);
    setEventData(processedData);
    setEditView('d8s');
  }

  

  /* D8s */
  if (editView === 'd8s') {
    return (
      <ThemeProvider theme={lightTheme}>
          <EventCarousel eventData={eventData} setEventData={setEventData} setEditView={setEditView} />
      </ThemeProvider>
    )
  }
  const D8Management = (
    <Box mt={2}>
      <Typography variant="subtitle2">Upload D8 data CSV</Typography>
      <CSVReader
        cssClass="react-csv-input"
        onFileLoaded={onD8CSVLoaded}
        parserOptions={papaparseOptions}
      />
      {/* <Button onClick={() => setEditView('d8s')}>Create D8</Button> */}
    </Box>
  )

  /* User management */
  const UserManagement = (<>

      {/* User Search */}
      <Typography mt={2} mb={1} fontSize={20} fontWeight={'bold'}>User Moderation</Typography>
      <Box
        sx={{
          border: '2px var(--color-accent) solid',
          borderRadius: 'var(--border-radius-main)',
        }}
      >
        <AdminUserSearch/>
        <Box mb={4}></Box>
      </Box>
      

    </>)

  return (
    <ThemeProvider theme={lightTheme}>
      <Container maxWidth='xl'>
        <Typography variant='h4' fontWeight={'bold'} mt={3}>Admin Dashboard</Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              '.MuiTab-textColorPrimary': { color: grey[900] },
              '.MuiTabs-indicator': { backgroundColor: grey[900] },
            }}
          >
            <Tab label="User Management" sx={{ textTransform: 'none' }} />
            <Tab label="Event (D8) Management" sx={{ textTransform: 'none' }} />
          </Tabs>
        </Box>

        {tabValue === 0 && UserManagement}
        {tabValue === 1 && D8Management}
      </Container>
    </ThemeProvider>
  );
}

export default AdminPage;
