import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import userService from '../../api/services/userService';
import { updateUser } from '../../app/slices/user.slice';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, TextField, Button, Typography, Avatar, IconButton, CircularProgress } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import EditSectionHeader from './EditSectionHeader';
import EditProfileMedia from './EditProfileMedia';

import './EditProfileStyle.css';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    black: '#121212',
  },
});

function EditProfile({ setEdit }) {
  const mobileLayout = useSelector(state => state.global.isMobile);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [editedProfile, setEditedProfile] = useState({
    name: user.name,
    description: user.description,
    images: user.images || [],
  });

  const [newImages, setNewImages] = useState([])
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [isSaving, setIsSaving] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile((prevEditedProfile) => ({
      ...prevEditedProfile,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const formData = new FormData();
    newImages.forEach(image => {
      if (image instanceof File) {
          formData.append('images', image);
      }
    });
    if (imagesToDelete.length > 0) {
      formData.append('imagesToDelete', JSON.stringify(imagesToDelete));
    }
  
    Object.keys(editedProfile).forEach(key => {
      if (key !== 'images') {
        formData.append(key, editedProfile[key]);
      }
    });
  
    try {
      setIsSaving(true)
      const response = await userService.modifyUserInfo(formData);
  
      if (response) {
        setIsSaving(false)
        const { name, description, images } = response
        dispatch(updateUser({ ...user, name, description, images }));
        setEdit(null);
      }
    } catch (error) {
      console.error('Error updating user info:', error);
    }
  };

  return (
    <>
      {isSaving ? (
        <Box
            sx={{
              position: 'fixed', // Fixed position
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center', 
            }}
          >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <ThemeProvider theme={lightTheme}>
          <Box display="flex" flexDirection="column" className={`profile-edit${mobileLayout ? '-mobile' : ""}`} >
            <Box mt={mobileLayout ? 2 : 3} mx={mobileLayout ? 1 : 3} mb={mobileLayout && 5}>
              <EditSectionHeader
                section="Edit Profile"
                setEdit={setEdit}
                handleSave={handleSave}
              />
            </Box>
            <EditProfileMedia
              existingImages={editedProfile.images}
              newImages={newImages}
              setNewImages={setNewImages}
              setImagesToDelete={setImagesToDelete}
              maxImages={6}
            />
            <TextField
              sx={{ mt: 4, mb: 2, mx: mobileLayout ? 2 : 5 }}
              size="small"
              name="name"
              label="Name"
              value={editedProfile.name}
              onChange={handleInputChange}
            />
            <TextField
              sx={{ mt: 2, mb: 2, mx: mobileLayout ? 2 : 5 }}
              size="small"
              name="description"
              label="Description"
              value={editedProfile.description}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </Box>
        </ThemeProvider>
      )}
    </>
  )
}

export default EditProfile;
