import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isMobile } from '../../scripts/mobileCheck';

const NewMemberList = ({ Component, title, data }) => (
  <div style={{ marginTop: "20px" }}>

    {/* Content container */}
    <Box 
      sx={
        isMobile() ?
        { // Mobile
          width: 'inherit',
          height: '430px',

          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',

          alignContent: 'flex-start',

          overflowX: 'auto',
          whiteSpace: 'nowrap',

          border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',

          mt: 1
        }
        :
        { // PC
          width: 'inherit',
          height: '430px',

          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',

          alignContent: 'flex-start',

          overflowX: 'auto',
          whiteSpace: 'nowrap',

          border: '2px solid var(--color-accent)',
          borderRadius: 'var(--border-radius-main)',

          mt: 1
        }
      }
    >
      {data.length > 0 && data.map((item, index) => <Component key={index} {...item} />)}

    </Box>
  </div>

);

export default NewMemberList;