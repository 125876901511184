import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { SectionContainer, SectionTitle, InfoItem, LongTextTypography, OutlineButton, FilledButton } from './StyledComponents';
import { isMobile } from '../../scripts/mobileCheck';

function PersonalitySection({ personality, setEdit, self }) {
    return (
        <SectionContainer mb={isMobile ? 2 : 3}>
          <Box sx={{ mb: 1, mr: 1 }} display="flex" justifyContent='space-between' alignItems='center'>
            <SectionTitle variant="h6">Personality</SectionTitle>
            {self && (
              <OutlineButton
                onClick={() => setEdit("Personality")} 
                
                variant="outlined" 
                size='small'
              >
                Edit
              </OutlineButton>
            )}
          </Box>
          <Divider variant='middle' sx={{ bgcolor: "#83838383", marginBottom: '20px' }}/>
            <InfoItem>
                <Typography variant="body2">Personality Type</Typography>
                <Typography variant="body2">{personality.personalityType || 'N/A'}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Introvert/Extrovert</Typography>
                <LongTextTypography variant="body2" array={personality.introExtroVert} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Morning/Night</Typography>
                <LongTextTypography variant="body2" array={personality.morningNight} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Favorite Emojis</Typography>
                <Typography variant="body2">
                    {personality.favoriteEmojis && personality.favoriteEmojis.length > 0
                        ? personality.favoriteEmojis.map(emoji => emoji.native).join(' ')
                        : 'N/A'}
                </Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Relationship Intent</Typography>
                <Box sx={{ maxWidth: '55vw' }}>
                    <Typography style={{ wordBreak: 'break-word', textAlign: 'right' }} variant="body2">{personality.relationshipIntent || 'N/A'}</Typography>
                </Box>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Friendship Intent</Typography>
                <Box sx={{ maxWidth: '55vw' }}>
                    <Typography style={{ wordBreak: 'break-word', textAlign: 'right' }} variant="body2">{personality.friendIntent || 'N/A'}</Typography>
                </Box>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Ideal Relationship D8</Typography>
                <LongTextTypography variant="body2" array={personality.idealRD8} />
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Ideal Friendship D8</Typography>
                <LongTextTypography variant="body2" array={personality.idealFD8} />
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Core Values (Up to 5)</Typography>
                <LongTextTypography variant="body2" array={personality.coreValues} />
            </InfoItem>
        </SectionContainer>
    );
}

export default PersonalitySection