import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CustomSelect = ({ fieldConfig, values, setFieldValue, margin = 'normal', image }) => (
  <FormControl fullWidth margin={margin} size="small" key={fieldConfig.name}>
    <InputLabel id={`${fieldConfig.name}-label`}>{fieldConfig.label}</InputLabel>
    <Select
      labelId={`${fieldConfig.name}-label`}
      label={fieldConfig.label}
      value={values[fieldConfig.name] || ''}
      onChange={(e) => setFieldValue(fieldConfig.name, e.target.value)}
    >
            <MenuItem value="" key="none">
                {image ? <img height={175} src="" alt="None" /> : "None"}
            </MenuItem>
      {fieldConfig.options.map(option => (
        <MenuItem value={option} key={option} sx={{ whiteSpace: 'normal' }}>
          {image ?  <img height={175} src={option} alt={option} /> : option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default CustomSelect;
