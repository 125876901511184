import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from '../app/slices/user.slice';

import { Avatar, Typography, Box, Button, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Link } from 'react-router-dom';
import { isMobile } from '../scripts/mobileCheck';

import BasicsSection from '../components/userProfile/BasicsSection';
import CareerSection from '../components/userProfile/CareerSection';
import PhysicalSection from '../components/userProfile/PhysicalSection';
import PersonalitySection from '../components/userProfile/PersonalitySection';
import TheTeaSection from '../components/userProfile/TheTeaSection';

import EditProfile from '../components/editUserProfile/EditProfile';
import EditBasics from '../components/editUserProfile/sections/EditBasics';
import EditCareer from '../components/editUserProfile/sections/EditCareer';
import EditPhysical from '../components/editUserProfile/sections/EditPhysical';
import EditPersonality from '../components/editUserProfile/sections/EditPersonality';
import EditTheTea from '../components/editUserProfile/sections/EditTheTea';

import PopupSlideshow from '../components/popup/Slideshow/PopupSlideshow'; 
import UserProfileTutorial from '../components/tutorial/1UserProfile/UserProfileTutorial';

import userService from '../api/services/userService';
import socket from '../socketio/socket';

const ProfileContainer = styled(`div`)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledAvatar = styled(Avatar)({
  width: '120px',
  height: '120px',
  marginBottom: '1em',
  border: '4px solid white',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)'
});

const UserInfo = styled(Box)({
  textAlign: 'center',
  marginBottom: '2em'
});

// Updated styles to fit images within the viewport
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',             // Adjust width automatically to fit content
  height: 'auto',            // Adjust height automatically to fit content
  maxHeight: '90vh',         // Limit max height to viewport height
  maxWidth: '90vw',          // Limit max width to viewport width
  bgcolor: 'transparent',    // Set background color to transparent to remove white space
  boxShadow: 'none',         // Remove box shadow to eliminate extra spacing
  overflow: 'hidden',        // Prevent any overflow within the modal
  display: 'flex',           // Use flex to center the content
  justifyContent: 'center',
  alignItems: 'center',
};

const modalImageStyle = {
  maxHeight: '100vh',         // Image will fit within the container's height
  maxWidth: '100vw',          // Image will fit within the container's width
  display: 'block',
  objectFit: 'contain',      // Ensures the image scales down and maintains its aspect ratio
  padding: 0,
  margin: 0,
};



export default function UserProfilePage({ editable }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { images, name, description, basics, career, physical, personality, theTea } = user;
  const [edit, setEdit] = useState(null);
  const [self, setSelf] = useState(editable ? true : false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // For testing purposes
  useEffect(() => {
    //dispatch(updateUser(userProfile))
  }, []);

  // First-time User Experience: Show tutorial popup for first-time users 
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const tutorialPopup = (
    <PopupSlideshow 
      content={<UserProfileTutorial tutorialVisible={tutorialVisible} setTutorialVisible={setTutorialVisible}/>} 
      visible={tutorialVisible} 
      setVisible={setTutorialVisible}
      backdrop={true}
      association={"first-time-user"}
    />
  );

  // Floating help button logic 
  const handleHelpButtonClick = () => {
    localStorage.setItem("first-time-user", true);
    setTutorialVisible(true);
  }

  useEffect(() => {
    localStorage.getItem("first-time-user") && setTutorialVisible(true);
    localStorage.getItem("first-time") && setTutorialVisible(true);
  }, []);

  // Profile detail edit section handle
  const handleOpenModal = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  /* Profile pictures & Description */
  const userSummary = (
    <Box 
      className='profile-pictures-container'
      sx={
        isMobile() ?
        { // Mobile layout
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          mt: 5
        }
        :
        { // PC layout
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 5,
        }
      }
    >
      <Box 
        className='profile-pictures'
        sx={
          isMobile() ?
          { // Mobile layout
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
            width: images.length <= 4 ? '87vw' : '100vw',
            height: images.length == 1 ? '400px' : '400px', 
            overflowY: 'scroll'
          }
          :
          { // PC layout
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            width: '550px',
            height: images.length <= 2 ? '300px' : '470px', 
            overflow: 'scroll'
          }
        }
      >
        {/* When user has images */}
        {images && images.map((image, index) => (
          <Box key={index} position="relative" onClick={() => handleOpenModal(image)}>
            <Avatar
              src={image.mediaUrl}
              alt={`Profile Media ${index}`}
              variant='rounded'
              sx={
                isMobile() ?
                { // Mobile layout
                  width: images.length <= 1 ? '64vw' : '40vw',
                  height: images.length <= 1 ? '64vw' : '40vw',
                  cursor: 'pointer',
                  borderRadius: 'var(--border-radius-box)'
                }
                :
                { // PC layout
                  width: images.length <= 1 ? '270px' : '220px',
                  height: images.length <= 1 ? '270px' : '220px',
                  cursor: 'pointer',
                  borderRadius: 'var(--border-radius-box)'
                }
              }
            />
          </Box>
        ))}

        {/* When user has no images */}
        {!images.length && (
          <Box position="relative">
            <Avatar
              variant='rounded'
              sx={
                isMobile() ?
                { // Mobile layout
                  width: images.length <= 1 ? '64vw' : '40vw',
                  height: images.length <= 1 ? '64vw' : '40vw',
                  cursor: 'pointer',
                  borderRadius: 'var(--border-radius-box)'
                }
                :
                { // PC layout
                  width: images.length <= 1 ? '270px' : '220px',
                  height: images.length <= 1 ? '270px' : '220px',
                  cursor: 'pointer',
                  borderRadius: 'var(--border-radius-box)'
                }
              }
            />
          </Box>
        )}
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            component="img"
            sx={modalImageStyle}
            src={selectedImage && selectedImage.mediaUrl}
            alt="User picture"
          />
        </Box>
      </Modal>
              
      <UserInfo mt={3} mx={3}>
        <Typography 
          className='profile-name'
          variant="h4" 
          component="div"
        >
          {name}
        </Typography>
        <Typography 
          className='profile-description'
          variant="body2" 
          style={
            isMobile() ?
            { // Mobile
              marginTop: '1em',
              width: '90vw',
              marginBottom: '20px'
            }
            :
            { // PC
              marginTop: '1em',
              width: '500px',
              marginBottom: '20px'
            }
          }
        >
          {description}
        </Typography>
        {self && (
          <Button 
            sx={{ 
              mt: 2,
              backgroundColor: 'var(--color-main)', 
              '&:hover': {
                backgroundColor: 'var(--color-accent)',
                borderColor: 'var(--color-accent)',
                color: 'var(--color-main)',
              },
              borderColor: 'var(--color-accent)',
              borderRadius: '9999px',
              color: 'var(--color-accent)',
              textTransform: 'none'
            }}
            onClick={() => setEdit('userInfo')} 
            variant="outlined" 
            size='medium'
          >
            Edit Pictures & Description
          </Button>
        )}
      </UserInfo>
    </Box>
  );

  /* Profile details */
  const userDetail = (
    <Box
      className='profile-detail-container'
      sx={
        isMobile() ?
        {
          width: '100vw',
        }
        :
        {
          height: '70vh',
          width: '500px',
          border: '2px solid #000',
          borderRadius: 'var(--border-radius-main)',
          overflowY: 'scroll',
          mt: 0
        }
      }
    >
      <div>&emsp;</div>
      <BasicsSection basics={basics} setEdit={setEdit} self={editable} />
      <CareerSection career={career} setEdit={setEdit} self={editable} />
      <PhysicalSection physical={physical} setEdit={setEdit} self={editable} />
      <PersonalitySection personality={personality} setEdit={setEdit} self={editable} />
      <TheTeaSection theTea={theTea} setEdit={setEdit} self={editable} />
    </Box>
  );

  return (
    <>
      {edit === 'userInfo' && (
        <EditProfile setEdit={setEdit} />
      )}
      {edit === 'Basics' && (
        <EditBasics section={edit} setEdit={setEdit} />
      )}
      {edit === 'Career' && (
        <EditCareer section={edit} setEdit={setEdit} />
      )}
      {edit === 'Physical' && (
        <EditPhysical section={edit} setEdit={setEdit} />
      )}
      {edit === 'Personality' && (
        <EditPersonality section={edit} setEdit={setEdit} />
      )}
      {edit === 'TheTea' && (
        <EditTheTea section={edit} setEdit={setEdit} />
      )}

      {edit === null && (
        <ProfileContainer className='profile-container'>
          {/* Tutorial popup */}
          {tutorialPopup}

          {/* Container box for Profile infos */}
          <Box
            sx={
              isMobile() ?
              { // Mobile layout
                mt: 0, 
                mb: 2
              }
              :
              { // PC layout
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                gap: 12,
                width: '100%',
                height: '100vh'
              }
            }
          >
            {/* Pictures and description */}
            {userSummary}
            
            {/* Details/Attributes */}
            {userDetail}
          
          </Box>
        </ProfileContainer>
      )}
    </>
  );
}
