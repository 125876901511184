import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { SectionContainer, SectionTitle, InfoItem, LongTextTypography, OutlineButton, FilledButton} from './StyledComponents';
import { isMobile } from '../../scripts/mobileCheck';

function TheTeaSection({ theTea, setEdit, self }) {
    return (
        <SectionContainer mb={isMobile ? 2 : 3}>
          <Box sx={{ mb: 1, mr: 1 }} display="flex" justifyContent='space-between' alignItems='center'>
            <SectionTitle variant="h6">The Tea</SectionTitle>
            {self && (
              <OutlineButton
                onClick={() => setEdit("TheTea")} 
                variant="outlined" 
                size='small'
              >
                Edit
              </OutlineButton>
            )}
          </Box>
          <Divider variant='middle' sx={{ bgcolor: "#83838383", marginBottom: '20px' }}/>
            <InfoItem>
                <Typography variant="body2">Interests</Typography>
                <LongTextTypography variant="body2" array={theTea.interests} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Recreational Substances</Typography>
                <LongTextTypography variant="body2" array={theTea.drugsAlcohol} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Music</Typography>
                <LongTextTypography variant="body2" array={theTea.music} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">TV/Movies</Typography>
                <LongTextTypography variant="body2" array={theTea.tvMovies} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Comedians</Typography>
                <LongTextTypography variant="body2" array={theTea.comedians} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Sports Teams</Typography>
                <LongTextTypography variant="body2" array={theTea.sportsTeams} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Cities Visited/Lived</Typography>
                <LongTextTypography variant="body2" array={theTea.citiesVisitedLived} / >
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Politics</Typography>
                <Typography variant="body2">{theTea.politics || 'N/A'}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Sexuality</Typography>
                <Typography variant="body2">{theTea.sexuality || 'N/A'}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Looking for Roommate</Typography>
                <Typography variant="body2">{theTea.roommatePreference || 'N/A'}</Typography>
            </InfoItem>
        </SectionContainer>
    );
}

export default TheTeaSection