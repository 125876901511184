import React from 'react';
import { Field } from 'formik';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

const CustomDatePicker = ({ fieldConfig, values, setFieldValue, margin, maxDate }) => (
  <DesktopDatePicker
    label={fieldConfig.label}
    inputFormat={fieldConfig.inputFormat}
    value={values[fieldConfig.name]}
    maxDate={maxDate && maxDate}
    onChange={(value) => setFieldValue(fieldConfig.name, value)}
    slotProps={{ textField: { size: 'small', margin, fullWidth: true } }}
    renderInput={(params) => <TextField {...params} size="small" margin="normal" fullWidth />}
  />
);

export default CustomDatePicker;
