import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, Button } from '@mui/material';
import AttractionsRoundedIcon from '@mui/icons-material/AttractionsRounded';
import EmojiFoodBeverageRoundedIcon from '@mui/icons-material/EmojiFoodBeverageRounded';

import { imageStyle, slideStyle, slideStyle_mobile } from '../TutorialStyles';
import { filledButtonStyle } from 'styles/buttonStyles';

import slide2_1 from './slide2-1.png';
import slide3_1 from './slide3-1.png'

import { resetSlide } from '../../popup/Slideshow/slideshowController';

// This is the final list of tutorials of the sequence
// The sequence goes as follows: UserProfileTutorial -> HomePageTutorial -> M8sPageTutorial & D8sPageTutorial & SearchPageTutorial

export default function D8sPageTutorial({tutorialVisible, setTutorialVisible}) {
    const mobile = useSelector(state => state.global.isMobile);

    const dots = Array(4).fill(null); // For dots below the slideshow, change number of dots Here when you add/remove slides from return()

    // Reset slide to slide 1 on popup show
    useEffect(() => {
        if (tutorialVisible) {
            resetSlide();
        }
    },[tutorialVisible])

    return (
        <div className="tutorial-d8s slideshow-container">

            <div className="popup-slide fade tutorial-d8s-1">
                
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={300} width={700}> 
                    <Box mb={4} fontSize={80}>
                        {/*
                        <img 
                            src="/icons/home.svg" 
                            height={50}
                            style={imageStyle}
                            
                        />
                        */}
                        <EmojiFoodBeverageRoundedIcon fontSize='inherit'/>
                    </Box>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 3 : 7} mb={2}>
                        This is your <b>D8s page</b>, where you can view and claim D8s (date coupons) in and out of your area.
                    </Typography>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 3 : 7} mb={2}>
                    Date coupons are there to incentivize you to meet up with friends (not just through the M8 site) and truly get to know each other
                    </Typography>

                    {/*
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7}>
                        Press &emsp; ▶ &emsp; to continue...
                    </Typography>
                    */}
                </Box>
            </div>
            

            <div className="popup-slide fade tutorial-d8s-2">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        Check out the D8s listed on this page, click on a D8 to learn more about it.
                    </Typography>
                    
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide2_1}
                            height={mobile ? 400 : 400}
                            style={imageStyle}
                        />
                       
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-d8s-3">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 1 : 7} mb={4}>
                        For interested D8 which you meet the requirement of, you can Claim it.
                    </Typography>
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        
                        <img 
                            src={slide3_1} 
                            height={mobile ? 430 : 390}
                            style={imageStyle}
                        />
                    </Box>
                </Box>
            </div>
            
            <div className="popup-slide fade tutorial-d8s-4">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 1 : 7} mb={4}>
                        <b>Go meet with your M8s, make the connection Stronger!</b>
                    </Typography>

                    <Box mb={1} fontSize={80}>
                        {/*
                        <img 
                            src="/icons/home.svg" 
                            height={50}
                            style={imageStyle}
                            
                        />
                        */}
                        <EmojiFoodBeverageRoundedIcon fontSize='inherit'/>
                    </Box>
                    
                    <Button 
                        onClick={() => 
                            {
                                setTutorialVisible(false);
                                localStorage.removeItem('first-time-d8');
                                resetSlide();
                                //window.location.reload(true);
                            }
                        }
                        sx={[filledButtonStyle, {width: 160}]}
                        mt={mobile ? '50px' : ''}
                        >
                            Close Tutorial
                    </Button>
                </Box>
                </div>
    
            {/* The indicator dots below slides*/}
            <Box 
                className='popup-slide-dot-container' 
                sx={{textAlign: 'center'}}
            >
                {dots.map((_, index) => (
                    <span key={index} className="dot"></span>
                ))}
            </Box>

        </div>
    );
}

