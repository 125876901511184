// React, Redux, DOM essential
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

// MUI components

// MUI icons

// Custom scripts

export default function ReferredPage() {
    const navigate = useNavigate();
    const [referralParams] = useSearchParams();
    const user = useSelector((state) => state.user);

    // Redirect user to register page if said user is referred
    const query = referralParams.get('referralID');
    useEffect(() => {
        if (query === null || query === "" || query === undefined || query.length > 10 || query.length < 10) {
            navigate("/login");
        } else {
            navigate("/register?referralID=" + query);
        }
    }, []);

    return <>

    </>
}