import { createSlice } from '@reduxjs/toolkit';

const initialState = {

  groupChats: {},
  groupChatUserAssociations: {},

  groupSessionAssociation: {},
  groupChatDetail: {},
  groupChatMembers: {},
  lastGroupMessages: {},
  groupChatSessionId: null,
  
  groupChatMemberDetail: {},
}

const groupChatSlice = createSlice({
  name: 'groupchats',
  initialState,
  reducers: {
    // Group Chat
    addGroupChat: (state, action) => {
      const groupChat = action.payload
      const groupChatId = groupChat._id
      state.groupChats[groupChatId] = groupChat
    },
    updateGroupChats: (state, action) => {
      const groupChats = action.payload
      state.groupChats = groupChats
    },
    updateGroupChatUserAssociations: (state, action) => {
      const groupChatUserAssociations = action.payload
      state.groupChatUserAssociations = groupChatUserAssociations
    },

    // Group Chat session state setters
    setGroupChatSessionId: (state, action) => {
      const groupChatId = action.payload
      state.groupChatSessionId = groupChatId 
    },
    updateGroupChatMembers: (state, action) => {
      const groupChatMembers = action.payload
      state.groupChatMembers = groupChatMembers
    },
    updateGroupSessionAssociation: (state, action) => {
      const groupSessionAssociation = action.payload
      state.groupSessionAssociation = groupSessionAssociation
    },
    updateGroupChatDetail: (state, action) => {
      const groupChatDetail = action.payload
      state.groupChatDetail = groupChatDetail
    },

    // Selected Group Chat member info setter
    updateGroupChatMemberDetail: (state, action) => {
      const groupChatMemberDetail = action.payload
      state.groupChatMemberDetail = groupChatMemberDetail
    }
  },
  
});

export const { 

  addGroupChat,
  updateGroupChats,
  updateGroupChatUserAssociations,
  
  setGroupChatSessionId,
  updateGroupChatMembers,
  updateGroupSessionAssociation,
  updateGroupChatDetail,

  updateGroupChatMemberDetail,
  
} = groupChatSlice.actions;
export default groupChatSlice.reducer;