import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { Link } from 'react-router-dom';

import './EditProfileStyle.css';
import { abort } from 'process';

const EditSectionHeader = ({ section, setEdit, handleSave }) => (
  <Box display="flex" justifyContent='space-between' alignItems='center' sx={{ mb: 3 }}>
    <Box display="flex" alignItems='center'>
      <IconButton onClick={() => setEdit(null)}>
        <ArrowBackIosIcon fontSize='small' sx={{ mr: 1 }} />
      </IconButton>
      <Typography variant="subtitle1">{section}</Typography>
    </Box>
    <Button 
      onClick={() => handleSave()} 
      sx={{ 
          backgroundColor: 'var(--color-accent)', 
          borderRadius: '9999px',
          color: 'var(--color-main)', 
          textTransform: 'none',
          width: 90,
          opacity: 1,
          transition: '0.2s',
          '&:hover': {
            backgroundColor: 'var(--color-accent)', 
            opacity: 0.7,
        },
      }}
    >
      Save
    </Button>
  </Box>
);

export default EditSectionHeader;
