import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, Button } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

import { imageStyle, slideStyle, slideStyle_mobile } from '../TutorialStyles';
import { filledButtonStyle } from 'styles/buttonStyles';

import { showSlide, resetSlide } from 'components/popup/Slideshow/slideshowController';

import slide4_1 from './slide4-1.png'

// This is the 1st tutorial of the sequence
// The sequence goes as follows: UserProfileTutorial -> HomePageTutorial -> M8sPageTutorial & D8sPageTutorial & SearchPageTutorial

export default function UserProfileTutorial({tutorialVisible, setTutorialVisible}) {
    const mobile = useSelector(state => state.global.isMobile);

    // Reset slide to slide 1 on popup show
    useEffect(() => {
        if (tutorialVisible) {
            resetSlide();
        }
    },[tutorialVisible])
    
    const dots = Array(2).fill(null); // For dots below the slideshow, change number of dots Here when you add/remove slides from return()

    return (
        <div className="tutorial-userprofile slideshow-container">

            { // This slide only shows for first time users
            localStorage.getItem("first-time") &&
            <div className="popup-slide fade tutorial-1-1">
                
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle}>
                    <Typography fontSize={20} fontWeight='bold'  >Welcome to M8!</Typography>
                    <br/>
                    <br/>
                    <img src='/logo/BM8.svg' alt="logo" width={mobile ? 150 : 150}/>
                    <br/>
                    <br/>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 0 : 7} mb={3}>
                        M8 is your gateway to finding genuine connections and building meaningful friendships. We streamline your search by allowing you to filter based on extensive criteria, from age and background to interests and hobbies.
                    </Typography>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 0 : 7}>
                        Let's get started!
                    </Typography>
                    {/*
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7}>
                        Press &emsp; ▶ &emsp; to continue...
                    </Typography>
                    */}
                </Box>
            </div>
            }
            

            <div className="popup-slide fade tutorial-1-2">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle}>

                    <Box fontSize={80} mb={4}>
                        <AccountCircleRoundedIcon fontSize='inherit'/>
                    </Box>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={3}>
                        The page you're currenly on is your <b>Profile page</b>. This is where you add or change <b>pictures</b>, <b>description</b>, and <b>characteristics</b> about yourself.
                    </Typography>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={3}>
                        Every detail you add is visible to anyone visiting your profile.
                    </Typography>
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-1-4">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle}>
  
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        Start freshing up your profile! Adding more details to help compatible M8s discover you. 
                    </Typography>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={5}>
                        Once you are done, go to the home page by clicking &nbsp;
                        <img 
                            src="./logo/BM8.svg" 
                            width={mobile ? 300 : 500}
                            style={{width: 18}}
                        /> 
                        .
                    </Typography>
                    {/* 
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={6}>
                        The more you share, the more you shine! 
                    </Typography>
                    <img 
                            src="./tutorialImages/1UserProfile/slide4-1.png" 
                            width={mobile ? 300 : 500}
                            style={imageStyle}
                    />
                    */}

                    <Button 
                        onClick={() => 
                            {
                                setTutorialVisible(false);
                                localStorage.removeItem('first-time');
                                //localStorage.setItem('first-time-home', true);
                                resetSlide();
                            }
                        }
                        sx={[filledButtonStyle, {width: 180}]}
                        mt={mobile ? '50px' : ''}
                        >
                            Edit Profile
                    </Button>
                </Box>
            </div>
            
    
            <Box 
                className='popup-slide-dot-container' 
                sx={{textAlign: 'center'}}
            >
                {localStorage.getItem("first-time") && <span className="dot"></span>}
                {dots.map((_, index) => (
                    <span key={index} className="dot"></span>
                ))}
            </Box>
        </div>
    );
}