import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    AppBar, Box, Toolbar, IconButton, Typography, Menu,
    Avatar, Button, Tooltip, MenuItem, Badge, Container, ThemeProvider, createTheme
} from '@mui/material';

function DifferenceContent() {
    return (
        <>
            <Typography variant="h3" gutterBottom sx={{ marginTop: 3, fontWeight: 'bold', textAlign: 'center' }}>
                The Difference
            </Typography>

            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                If you've explored the M8 website and found yourself drawing parallels with platforms like Facebook and Bumble, thinking, "This seems quite similar; I'm not sure about the distinctions," you're both correct and not entirely so. While M8 shares resemblances with Bumble and Facebook, it distinguishes itself by amalgamating key features from both platforms to enhance the overall user experience.
            </Typography>

            <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
                To illustrate this, envision these platforms as grocery stores:
            </Typography>

            <Typography variant="h7" gutterBottom sx={{ marginLeft: 2, marginTop: 1.5, fontWeight: 'bold' }}>
                The Bumble Grocery Store:
            </Typography>

            <Typography paragraph sx={{ marginLeft: 6, marginTop: 1, fontSize: '1rem' }}>
                Entering Bumble's store, a manager promptly engages you, seeking information about your preferences. He immediately informs you that there's an option to pay more for slightly improved search criteria and a few other upgrades. Instead of walking through the aisles yourself, the manager, akin to a personal shopper, selects items for you based on your inputs. You decide item by item which you would like to keep, but each item also decides if it wants to be with you before any interaction is had with the item. Without the upgraded experience, you're limited to keeping 25 items daily. In order for the grocery store to stay in business and grow their profits, they need to keep the customers in the grocery store for as long as possible.
            </Typography>

            <Typography variant="h7" gutterBottom sx={{ marginLeft: 2, marginTop: 1.5, fontWeight: 'bold' }}>
                The Facebook Grocery Store:
            </Typography>

            <Typography paragraph sx={{ marginLeft: 6, marginTop: 1, fontSize: '1rem' }}>
                Entering Facebook's grocery store, you're blown away by the number of items in the store as they are the biggest grocery store in the world and anything you could ever want is in that store. Unfortunately, it is a bit difficult to navigate the grocery store since you can only search by general category of food or by exact serial number of a specific item. If you search by general category, you can see all the different items that belong to that category, and if you select a specific item there is most likely an extremely rich history of the item as well as great details about the item. The Facebook grocery store makes the majority of its money from coupons that are scattered throughout the store that customers look at and use. The more coupons that customers look at and use, the more money the grocery store makes. While Facebook does a great job at tailoring the coupons to the specific customer, the grocery store would prefer the customer to stay in the grocery store for as long as possible and designs their store specifically to keep the customers engaged and addicted to the store.
            </Typography>

            <Typography variant="h7" gutterBottom sx={{ marginLeft: 2, marginTop: 1.5, fontWeight: 'bold' }}>
                The M8 Grocery Store:
            </Typography>

            <Typography paragraph sx={{ marginLeft: 6, marginTop: 1, fontSize: '1rem' }}>
                Though M8's aisles may seem sparse in its early stages of growth, envision a future with a critical mass of items. Entering M8s grocery store, you will be greeted by a manager and asked for some input about yourself, a majority of which is optional but beneficial if entered. There are aisles to walk through, but the M8 grocery store understands that it's extremely frustrating and inefficient to walk up and down the aisles all over the store searching for the items you want only to realize that they are out of stock, so they created a kiosk where you can input information about the items you want and see exactly where, what, and how many items there are. Whether the items want you back is a different question and is still a part of the game, but at least you were able to send the item a message saying that you wanted it in an efficient way. At M8s grocery store they value the users' time and know you want to be minimizing any time in the grocery store. What will make customers the happiest is not spending time in the grocery store, but rather spending as much time as possible in the kitchen cooking those ingredients and savoring the food you just cooked.
            </Typography>

            <Typography paragraph sx={{ marginLeft: 6, marginTop: 1, fontSize: '1rem' }}>
                The current business model plan for M8 is to profit only when customers purchase D8s. In this way, we are incentivizing the user to spend as little time as possible on the actual site and the most time interacting, planning, and enjoying time spent with other M8 users or other friends/family in general.
            </Typography>
        </>
    );
}

export default DifferenceContent;