import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    AppBar, Box, Toolbar, IconButton, Typography, Menu, Modal,
    Avatar, Button, Tooltip, MenuItem, Badge, Container, ThemeProvider, createTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import SearchIcon from '@mui/icons-material/Search';  // Commented out SearchIcon

function How2M8Content() {
    return (
    <>
        <Typography variant="h3" gutterBottom sx={{ marginTop: 3, fontWeight: 'bold', textAlign: 'center' }}>
            How2M8 v1.0
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            Ideally a website or app is so intuitive that there is no need for a tutorial or user guide to understand how it is supposed to work. Unfortunately, M8 is not at that stage yet and you might be wondering how to best navigate this website, so I have created this guide to provide you with the vision I had for how this site would be used when we created it.
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            There are 2 main features to the M8 site:
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            <span style={{ fontWeight: 'bold' }}>1. M8s (mates):</span> users that you can search for and message, whether that be for romantic or platonic purposes
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            <span style={{ fontWeight: 'bold' }}>2. D8s (dates):</span> date coupons that you can claim that are there to incentivize you to meet up with friends (not just through the M8 site) and truly get to know each other
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            If you navigate from the home page to the "M8s" page you will initially either see a blank page or a page with only 1 category called "New":
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                    src="..\..\docImages\M8_Categories_1.JPG"
                    alt="M8_Categories_1"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\M8_Categories_2.JPG"
                    alt="M8_Categories_2"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\M8_Categories_3.JPG"
                    alt="M8_Categories_3"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
            </div>

        </Typography>

        <Typography paragraph sx={{ fontSize: '1rem' }}>
            If you would like to create your own custom category, select the "+" button, type in your desired name for the category, and add:
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                    src="..\..\docImages\Adding_Category_1.JPG"
                    alt="Adding_Category_1"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\Adding_Category_2.JPG"
                    alt="Adding_Category_2"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
            </div>
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            Once the category is created, you can move a user from any other category into that category by going into their chat, clicking the 3 dots, clicking "Move to", and move the user into any other category:
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                    src="..\..\docImages\Moving_User_1.JPG"
                    alt="Moving_User_1"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\Moving_User_2.JPG"
                    alt="Moving_User_2"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\Moving_User_3.JPG"
                    alt="Moving_User_3"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
            </div>
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            To search for other users go to the "Search" page, input the characteristics of the user that you would like to filter for in the Basics, Career, Physical, Personality, and The Tea tabs, and then search:
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                    src="..\..\docImages\Search_1.JPG"
                    alt="Search_1"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\Search_2.JPG"
                    alt="Search_2"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\Search_3.JPG"
                    alt="Search_3"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
            </div>
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            A list of users that most match the inputs that you entered into the search will appear at the top. As you scroll down, you will continue to see users, but you will see users with less matched criteria. Adjust your filters as necessary to find exactly who you are looking for.
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            Click on a user that interests you to see their full profile and send them a message! They will appear in your "New" category and you can then move them into a custom category of your choice if desired.
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                    src="..\..\docImages\Message_1.JPG"
                    alt="Message_1"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\Message_2.JPG"
                    alt="Message_2"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
            </div>
        </Typography>

        <Typography paragraph sx={{ marginTop: 1.5, fontSize: '1rem' }}>
            To see what D8s are available and claim a D8 navigate to the "D8s" page, click on a D8 that interests you to learn more about details, and claim the D8 if interested:
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <img
                    src="..\..\docImages\D8_1.JPG"
                    alt="D8_1"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\D8_2.JPG"
                    alt="D8_2"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
                <img
                    src="..\..\docImages\D8_3.JPG"
                    alt="D8_3"
                    style={{ verticalAlign: 'middle', horizontalAlign: 'middle', maxWidth: '350px', marginTop: 10 }}
                />
            </div>
        </Typography>
    </>
    );
}

export default How2M8Content;