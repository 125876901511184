import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Link } from 'react-router-dom';

const SectionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '0em',
  marginLeft: '25px',
  marginRight: '25px'
});

const InfoItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '1em',
  marginLeft: '25px',
  marginRight: '25px'
});

export function ProfileSection({ title, info, setEdit, self }) {
  const titleEditKeyMap = {
    "Basics": 0,
    "Career": 1,
    "Physical": 2,
    "Personality": 3,
    "The Tea": 4
  }

  return (
    <SectionContainer className="member-profile">
      <Box sx={{ mb: 2 }} display="flex" justifyContent='space-between' alignItems='center'>
        <SectionTitle variant="h6">{title}</SectionTitle>
        {self && (
          <Button onClick={() => setEdit(titleEditKeyMap[title])} variant="text" sx={{ textTransform: 'none' }}>
            Edit
          </Button>
        )}
      </Box>
      {info.map((item, index) => (
        <InfoItem key={index}>
          <Typography variant="body2">{item.label}</Typography>
          <Typography variant="body2">{item.value}</Typography>
        </InfoItem>
      ))}
    </SectionContainer>
  );
}
