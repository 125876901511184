import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import MemberProfile from '../userProfile/MemberProfile';
import BottomDrawer from '../drawer/Drawer';

import './memberCard.css';
import { Box, Avatar, styled, Card, CardContent, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const StyledAvatar = styled(Avatar)({
  width: '120px',
  height: '120px',
  marginBottom: '1em',
  border: '4px solid white',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)'
});

export default function MemberCard(props) {
  const { images, name, description, basics, career, physical, personality, theTea } = props;
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Function to handle the click event on the card
  const handleCardClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setDrawerOpen(true); // Open the Drawer
  };

  // Function to handle closing the Drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const calculateAge = (dateOfBirth) => {
    const dob = dayjs(dateOfBirth);
    const now = dayjs();
    return now.diff(dob, 'year');
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Card 
        sx={
            { 
              minWidth: 150, 
              maxWidth:150, 
              maxHeight: 190, 

              mx: 1, 
              my: 1, 

              flexBasis: '100%',

              borderRadius: '12px',
              border: 'var(--color-accent) 0px',

              transition: '0.2s',

              bgcolor: '#00000000',
                '&:hover': {
                  bgcolor: 'grey.100'
                }
              }
            } 
        onClick={handleCardClick} 
        style={{ cursor: 'pointer' }} 
        variant="outlined">


        <CardContent>
          {/* Avatar */}
          <Avatar 
            alt={name} 
            src={images.length ? images[0].mediaUrl : ''} 
            sx={{ width: 60, height: 60, margin: "10px auto" }}
          />

          {/* Infos */}
          <Box style={{ textAlign: 'center', width: "100%" }}>
            <Typography variant="body2" mt={3} sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {name + ", " + calculateAge(basics.dateOfBirth)}
            </Typography>
            <Typography variant="body2">
              {basics.currentCity}
            </Typography>
          </Box>

        </CardContent>
      </Card>

      <BottomDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} content={<MemberProfile {...props} />} />
    </ThemeProvider>
  );
}