import React, { useState } from 'react';
import UserProfile from 'pages/UserProfilePage';
import CustomDrawer from '../drawer/Drawer';
import { Box, Card, CardContent, Avatar, Typography, Grid, Chip } from '@mui/material';
import MemberProfile from '../userProfile/MemberProfile';
import dayjs from 'dayjs';

import { isMobile } from 'scripts/mobileCheck';


const SearchCard = ({ result }) => {
  const { images, name, description, basics, career, physical, personality, theTea, matchedFields } = result;
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Function to handle the click event on the card
  const handleCardClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setDrawerOpen(true); // Open the Drawer
  };

  // Function to handle closing the Drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const calculateAge = (dateOfBirth) => {
    const dob = dayjs(dateOfBirth);
    const now = dayjs();
    return now.diff(dob, 'year');
  };

  function formatHeight(value) {
    return `${Math.floor(value / 12)}' ${value % 12}"`
  }

  function extractBodyType(path) {
    const parts = path.split('/');
    const fileName = parts[parts.length - 1]; // Get the last part of the path
    return fileName.split('.')[0]; // Remove the file extension and return the body type
  }

  const multiELement = [
    'basics.stageOfLife',

    'career.schools',
    'career.classes',
    'career.occupation',

    'physical.ethnicity',
    'physical.disability',

    'personality.introExtroVert',
    'personality.morningNight',
    'personality.idealFD8',
    'personality.idealRD8',
    'personality.coreValues',

    'theTea.interests',
    'theTea.drugsAlcohol',
    'theTea.music',
    'theTea.tvMovies',
    'theTea.comedians',
    'theTea.sportsTeams',
    'theTea.citiesVisitedLived',  
  ]

  const formatLabel = (match, element) => {
    
    if (multiELement.includes(match.field)) {
      return element
    } else if  (match.field === 'basics.dateOfBirth') {
      return calculateAge(match.value) + " years old"
    } else if  (match.field === 'career.income') {
      return "$" + match.value
    } else if  (match.field === 'physical.height') {
      return formatHeight(match.value) 
    } else if  (match.field === 'physical.weight') {
      return match.value + " lbs"  
    } else if  (match.field === 'physical.eyeColor') {
      return match.value + " eyes"  
    } else if  (match.field === 'physical.hairColor') {
      return match.value + " hair"  
    } else if  (match.field === 'physical.bodyType') {
      return  extractBodyType(match.value)
    } else if  (match.field === 'personality.favoriteEmojis') {
      return element.native   
    } else if  (match.field === 'theTea.roommatePreference') {
      if (match.value === "Yes") {
        return "Looking for Roommate"
      } else if (match.value === "No") {
        return "Not looking for Roommate"
      }   
    } else {
      return match.value
    }
  }


  return (
    <>
      <Box 
        className="search-result-card"
        onClick={handleCardClick} 
        sx={
          isMobile() ?
          { // Mobile layout
            cursor: 'pointer', 

            width: "180px",
            height: '230px',

            mx: '2px'
          }
          :
          { // PC layout
            cursor: 'pointer', 

            width: "180px",
            height: '230px',

            mx: 5,
            '@media (max-width: 1000px)': {
              mx: 0,
            }
            
          }
        } 
      >
        <Card 
          variant="outlined" 
          sx={
            isMobile() ?
            { // Mobile layout
              minWidth: 0, 
              width: '100%', 
              height: '100%', 

              mx: 0, 
              my: 0, 


              borderRadius: '12px',
              //border: 'var(--color-accent) ',

              display: 'flex',
              flexDirection: 'column',

              bgcolor: '#00000000',
                '&:active': {
                  bgcolor: 'grey.100'
                }
            }
            :
            { // PC layout
              minWidth: 150, 
              width: '100%', 
              height: '100%', 

              mx: 1, 
              my: 1, 

              flexBasis: '100%',
              
              display: 'flex',
              flexDirection: 'column',


              borderRadius: '12px',
              //border: 'var(--color-accent) ',

              //boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.0), 0 6px 20px 0 rgba(0, 0, 0, 0.0)',
              //boxShadow: 1,
              

              transition: '0.2s',

              bgcolor: '#00000000',
                '&:hover': {
                  bgcolor: 'grey.100'
                }
            }
            } 
        >
          <Box
            sx={
              {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',

                textAlign: 'center'
              }
            }
          >
              <Grid item mb={1} mt={2}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar src={images.length > 0 ? images[0].mediaUrl : ""} style={{ width: 50, height: 50 }} />
                </div>
              </Grid>


                <Typography variant="subtitle2" mb={1}>
                 {name}
                </Typography>

                <Box>
                  {matchedFields && matchedFields.map((match, index) => (
                    <Grid item key={index}>
                            {Array.isArray(match.value) && match.value.map((element, index) => (
                              <Chip key={element.id + index} label={formatLabel(match, element)} size="small" variant="outlined" />
                            ))}
                            {!Array.isArray(match.value) && (
                              <Chip label={formatLabel(match)} size="small" variant="outlined" sx={{ fontSize: "12px" }} />
                            )}
                    </Grid>
                  ))}
                </Box>


          </Box>
        </Card>
      </Box>

      <CustomDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} content={<MemberProfile {...result} />} />
    </>
  );
};

export default SearchCard;
