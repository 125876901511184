import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import axios from 'axios';
import userService from 'api/services/userService';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';


const lightTheme = createTheme({
    palette: {
        mode: 'light', // Set the theme mode to light
    },
});
function Forget() {
    const mobileLayout = useSelector(state => state.global.isMobile);

    // Email submission handler
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('Enter your account email and we\'ll send you a link to reset your password');
    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');

        userService.forgotPassword({ email }).then((res) => {
            setMessage(res.message);
        }).catch((err) => {
            console.log(err);
        });
    };

    /* Big M8 logo */
    const BigM8Logo = (
        <Box
        sx={{
            //backgroundColor: '#000', 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 3,

        }}
        >
            <div className="logo-container">
                <img src='/logo/BM8.svg' alt="Logo" style={{ width: "75px" }} />
            </div>
        </Box>
    )

    /* Email form */
    const EmailForm = (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0 }}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                    sx={{
                        mt: 3,
                        mb: 2,
                        backgroundColor: '#000', // Set the background color 
                        color: '#fff', // Set the text color
                        border: '2px solid #000', // Set a border
                        '&:hover': {
                            backgroundColor: '#fff', // Change background color on hover
                            color: '#000', // Change text color on hover
                        }
                    }}
            >
                Send Reset Link
            </Button>
        </Box>
    )

    /* Back to login button */
    const BackToLogin = (
        <Link to="/login" >
        <Button
            type="button"
            fullWidth
            variant="text"
            sx={
                mobileLayout ?
                { mt: 2, color: '#333', textTransform: 'none' }
                :
                { mt: -1, color: '#333', textTransform: 'none' }
            }
        >
             Back to login
        </Button>
        </Link>
    )

    return (
        <ThemeProvider theme={lightTheme}>
            <Container maxWidth="xs">
               
            {BigM8Logo}

            <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" sx={{textAlign:'center'}}>
                    Forgot Password?
                </Typography>

                <Typography mt={5} color="textSecondary" sx={{textAlign:'center'}}>{message}</Typography>
                
                {EmailForm}

                {BackToLogin}
            </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Forget;
