import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { SectionContainer, SectionTitle, InfoItem, OutlineButton, FilledButton } from './StyledComponents';
import { isMobile } from 'scripts/mobileCheck';

function PhysicalSection({ physical, setEdit, self }) {
    function formatHeight(value) {
        return `${Math.floor(value / 12)}' ${value % 12}"`
    }
    return (
        <SectionContainer mb={isMobile ? 2 : 3}>
          <Box sx={{ mb: 1, mr: 1 }} display="flex" justifyContent='space-between' alignItems='center'>
            <SectionTitle variant="h6">Physical</SectionTitle>
            {self && (
              <OutlineButton
                onClick={() => setEdit("Physical")} 
                variant="outlined" 
                size='small'
              >
                Edit
              </OutlineButton>
            )}
          </Box>
          <Divider variant='middle' sx={{ bgcolor: "#83838383", marginBottom: '20px' }}/>
            <InfoItem>
                <Typography variant="body2">Height:</Typography>
                <Typography variant="body2">{formatHeight(physical.height)}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Weight:</Typography>
                <Typography variant="body2">{physical.weight + " lbs" || 'N/A'}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Ethnicity:</Typography>
                <Typography variant="body2">{physical.ethnicity.length ? physical.ethnicity : 'N/A'}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Eye Color:</Typography>
                <Typography variant="body2">{physical.eyeColor || 'N/A'}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Hair Color:</Typography>
                <Typography variant="body2">{physical.hairColor || 'N/A'}</Typography>
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Body Type:</Typography>
                {physical.bodyType ? (
                    <img height={175} src={physical.bodyType } alt={"Body Type"} />
                ) : (
                    <Typography variant="body2">{physical.bodyType || 'N/A'}</Typography>
                )}
    
            </InfoItem>
            <InfoItem>
                <Typography variant="body2">Disability</Typography>
                <Typography variant="body2" array={physical.disability} />
            </InfoItem>
        </SectionContainer>
    );
}

export default PhysicalSection
