import React, { useEffect, useState, useReducer} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 

import socket from 'socketio/socket';

import { List, Typography, Box, ListItem, ListItemAvatar, Avatar, ListItemText, ButtonBase, Tab, Tabs } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import ChatList from './chatList/ChatList';
import CategoryList from './categoryList/CategoryList';

import { useMouseHover } from 'hooks/onMouseHover';

import { 
  updateCategories,

  setSelectedCategoryId,
  setChatSessionId
} from 'app/slices/chat.slice';

import chatService from 'api/services/chatService';
import groupChatService from 'api/services/groupchatService';

import { delay } from 'scripts/delay';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


function ChatListView({ group }) {
  const mobileLayout = useSelector(state => state.global.isMobile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hovered, hoverEventHandlers] = useMouseHover();

  const selectedCategoryId = useSelector(state => state.chats.selectedCategoryId);
  const categories = useSelector(state => state.chats.categories);
  const category = useSelector(state => state.chats.categories[selectedCategoryId]);
  const chatId = useSelector(state => state.chats.chatSessionId); 
  const userId = useSelector(state => state.user.userId);
  const chats = useSelector(state => state.chats.chats[selectedCategoryId]);
  const chatUserAssociation = useSelector(state => state.chats.chatUserAssociation);

  // For showing indicator in Chat Type Menu when user has unread messages in one or more Group Chats
  const [hasUnreadMsg, setHasUnreadMsg] = React.useState(false);
  const getUnreadCount = async () =>{
    try {
      // serviceCode = 1: Count unread messages in ALL chat
      // serviceCode = 2: Count unread messages in SPECIFIC chat (In this case)
      // serviceCode = 3: Count unread messages across ALL chats in SPECFIC category 
      const counter = await groupChatService.getUnreadMessageCount( {userId: userId, serviceCode: 1});
      if (counter.data.unreadCounter > 0) {
        setHasUnreadMsg(true);

      } else {
        setHasUnreadMsg(false); 
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getUnreadCount();
  },[])

  /* Title and Back button */
  const handleBackButton = () => {
    
    dispatch(setSelectedCategoryId(null));
    
    // For some reason dispatches causes Chat component to malfunction,
    // reload fixes the issue.
    //socket.emit('refreshChatSignal',{});

  }
  const TitleNBackButton = (
    <Box
          sx={{ display: "flex", width: "100%", alignItems: "center"}}
        >
          {group && (
            <IconButton onClick={handleBackButton} >
              <ArrowBackIosIcon fontSize='small' sx={{ ml: 1 }} />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ ml: 1 }} fontWeight={'bold'}>
            {category && category.name}
          </Typography>
      </Box>
  );

    /* Chat type menu */
    const [showChatTypeMenu, setShowChatTypeMenu] = useState(false);
    const [chatType, setChatType] = useState(0);
    const handleChatTypeChange = (event, newValue) => {
        setChatType(newValue);
    };
    const chatTypes = [
        {label: "M8 Chats"},
        {label: `Group Chats ${hasUnreadMsg === true ? '•' : ''}`},
    ];
    useEffect(() => {
        if (hovered === true) {
            setShowChatTypeMenu(true);
        } else {
            setShowChatTypeMenu(false);
        }
    },[hovered])
    useEffect(() => {
        switch (chatType) {
            case 0:
                void(0);
                break;
            case 1:
                navigate('/group-chat')
                break;
            default:
                void(0);
        }
    },[chatType])
    const ChatTypeMenu = (
    <Box
        {...hoverEventHandlers} 
        sx={
            {
                display: 'flex',
                justifyContent: 'center',
                minHeight: showChatTypeMenu ? 40 : 35, height: showChatTypeMenu ? 40 : 35,
                mb: showChatTypeMenu ? 2 : 0
            }
        }
    >
        {!showChatTypeMenu ?
            <Box
                sx={
                    {
                        bgcolor: 'var(--color-accent)', 
                        opacity: 0.2, 
                        borderRadius: 9999,
                        width: 30, 
                        height: 9, 
                        mt: 2
                    }
                }
            >
            </Box>
        :
            <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={chatType}
            onChange={handleChatTypeChange}
            aria-label="Vertical tabs example"
            textColor="var(--color-text)"
            indicatorColor="var(--color-separator)"
            sx={
                { 
                    color: 'var(--color-accent)',
                    borderRight: 0, 
                    borderColor: 'var(--color-accent)',
                    '.MuiTab-textColorPrimary': { color: 'var(--color-text)' },
                    '.MuiTabs-indicator': { backgroundColor: 'var(--color-separator)' },
                    
                }
            }
            >
                {chatTypes.length > 0 && chatTypes.map((option, index) => 
                    <Tab key={index} label={option.label} sx={{alignItems: 'start', textTransform: "none"}} />
                )}
            </Tabs>
        }
        
    </Box>
    )


  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        className="chat-list"
        sx={
          mobileLayout ?
          { // Mobile layout
            width: '100%',
            height: '100%',

            display: chatId === null ? "visible" : "none"
          }
          :
          { // PC layout
            mt: 2,

            border: '2px solid var(--color-accent)',
            borderRadius: 'var(--border-radius-main)',

            width: '420px',
            height: '85vh',
            '@media (max-height: 650px)': {
              height: '80vh'
            },
            '@media (max-height: 508px)': {
              height: '75vh'
            },
          }
        }
      >
        <Box sx={{ mt: 0, width: '100%' }}>
          {/* Show chat list when category is selected; Show category list if not */}
          {Object.keys(categories).length && selectedCategoryId === null ?
            <>
                {ChatTypeMenu}
                <CategoryList chatTypeMenuVisible={showChatTypeMenu}/>
                
            </>
            :
            <>
                {ChatTypeMenu}
                {TitleNBackButton}
                <ChatList chatTypeMenuVisible={showChatTypeMenu} />
                
            </>
            
          }
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ChatListView;
