import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import debounce from 'lodash/debounce';

const CustomMultiAutocomplete = ({ fieldConfig, values, onSubmit, margin, image }) => {
  const [options, setOptions] = useState([]);

  const handleDynamicSearch = debounce(async (searchTerm) => {
    const data = await fieldConfig.fetchOptions(searchTerm);
    setOptions(data);
  }, 300);

  useEffect(() => {
    if (fieldConfig.options) {
      setOptions(fieldConfig.options);
    }
    if (fieldConfig.fetchOptions) {
      handleDynamicSearch('');
    }
  }, []);

  function extractBodyType(path) {
    const parts = path.split('/');
    const fileName = parts[parts.length - 1]; // Get the last part of the path
    return fileName.split('.')[0]; // Remove the file extension and return the body type
  }

  const formatLabel = (option)  => {
    if (fieldConfig.name === 'bodyType') {
      return extractBodyType(option)
    } else {
      return option
    }
  }

  return (
    <Autocomplete
      multiple
      //freeSolo
      options={options}
      margin={margin}
      name={fieldConfig.name}
      value={values[fieldConfig.name] || []}
      onInputChange={(event, value) => fieldConfig.fetchOptions ? handleDynamicSearch(value) : fieldConfig.options ? fieldConfig.options : null}
      onChange={(event, value) => onSubmit({ ...values, [fieldConfig.name]: value })}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip 
            label={formatLabel(option)}
            {...getTagProps({ index })} 
            key={index}
          />
        ))
      }
      renderOption={(props, option) => (
        <li {...props}>
          {image ? <img src={option} alt={option} height="150" style={{ marginRight: 5 }} /> : option}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={fieldConfig.label} margin="normal" size="small" fullWidth />
      )}
      key={fieldConfig.name}
    />
  );
};

export default CustomMultiAutocomplete;
