// React & Redux essentials
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI components
import { 
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    List,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Box,
    Typography,
    Snackbar,
    SnackbarContent
} from "@mui/material";

// MUI icons
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import CloseIcon from '@mui/icons-material/Close';

// MUI styles

// Background service calls

// Custom styles
import { dialogButtonRegular } from "styles/dialogButtonStyles";
import { dialogButtonFocus } from "styles/dialogButtonStyles";
import groupChatService from "api/services/groupchatService";

// Custom functions


export default function AddToGroup({ friendId }) {
    // Dialog states
    const [dialogOpen, setDialogOpen] = useState(false);

    // Snackbar states
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('No message');

    // Group chat list state (redux)
    const groupChats = useSelector(state => state.groupchats.groupChats);

    // Dialog button handler
    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleClose = () => {
        setDialogOpen(false);
    };

    // Group Chat item click handle
    const handleGroupChatItemClick = async (groupChatId) => {
        await groupChatService.addUserToGroupChat({ groupChatId: groupChatId, userId: friendId })
        .then(res => {
            if (res.status === 200) {
                setSnackbarMessage("Invite sent to M8");
                setSnackbarShow(true);
            }
            if (res.status === 204) {
                setSnackbarMessage("M8 already in group or invited");
                setSnackbarShow(true);
            }
            setDialogOpen(false);
        })
        .catch(error => {
            void(0);
        })
    }

    // Refresh groupChat when Redux store is updated
    useEffect(() => {  
    },[groupChats])

    /* Add To Group dialog */
    const AddToGroupDialog = (
        <Dialog open={dialogOpen} onClose={setDialogOpen}>
            <Box sx={{ width: "500px", mt: 1}}>
                <DialogTitle sx={{ fontSize: "1.1rem", fontWeight: 'bold'}}>
                    Invite M8 to group
                </DialogTitle>
                <DialogContent sx={{mt: 1}}>
                    <DialogContentText id="alert-dialog-description">
                        Choose a group...
                    </DialogContentText>
                </DialogContent>

                <List sx={{ pt: 0, width: "100%" }}>
                    {groupChats && Object.keys(groupChats).length  > 0 && Object.values(groupChats).map(groupChat => (
                        <ListItem disableGutters key={groupChat._id}>
                        <ListItemButton onClick={() => {handleGroupChatItemClick(groupChat._id)}} sx={{ padding: "0 24px" }}>
                            <ListItemAvatar>
                            <Avatar /*variant='rounded'*/ sx={{ width: 30, height: 30 }}> 
                                <GroupsRoundedIcon/>
                            </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={groupChat.name} />
                        </ListItemButton>
                        </ListItem>
                    ))}  
                </List>

                <DialogActions>
                    <Button size="small" onClick={handleClose} sx={dialogButtonFocus}>
                        Cancel
                    </Button>
                </DialogActions>
            </Box>

        </Dialog>
    )

    /* Add to group status (snackbar) */
    const AddToGroupSnackbarAction = (
        <Fragment>
          {/*
          <Button color="secondary" size="small" onClick={() => {setSnackbarShow(false)}}>
            CLOSE
          </Button>
          */}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => {setSnackbarShow(false)}}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Fragment>
    )
    const AddToGroupSnackbar = (
        <Snackbar
            open={snackbarShow}
            autoHideDuration={1500}
            onClose={() => {setSnackbarShow(false)}}
            
        >
            <SnackbarContent 
                style={{
                    backgroundColor:'var(--color-accent)',
                    color:'var(--color-text-invert)',
                }}
                message={snackbarMessage}
                action={AddToGroupSnackbarAction}
            />
        </Snackbar>
    )
    

    /* Add To Group button */
    const AddToGroupButton = (
        <Button 
        sx={{ 
            //fontSize: 1,
            textTransform: "none",
            color: 'var(--color-text)',
            borderRadius: 'var(--border-radius-box)',
            borderColor: 'var(--color-accent)',
            backgroundColor: 'var(--color-main)',
            '&:hover': {
            color: 'var(--color-text-invert)',
            borderRadius: 'var(--border-radius-box)',
            borderColor: 'var(--color-accent)',
            backgroundColor: 'var(--color-accent)',
            },
            width: '140px',
            height: '30px',
        }}
        size='small'
        variant="outlined"
        onClick={handleClickOpen}
    >
        <GroupsRoundedIcon fontSize='medium'/>&nbsp;&nbsp;Add To Group
    </Button>
    )

    return (
    <>
        {AddToGroupButton}

        {AddToGroupDialog}

        {AddToGroupSnackbar}
    </>
    );
}