// React, Redux, DOM essentials
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// MUI components
import { Box, Typography, Button } from '@mui/material';

// MUI icons
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import AddIcon from '@mui/icons-material/Add';
import AdUnitsRoundedIcon from '@mui/icons-material/AdUnitsRounded';

// Tutorial slide styles
import { imageStyle, slideStyle, slideStyle_mobile } from '../TutorialStyles';
import { filledButtonStyle } from 'styles/buttonStyles';

// Slide images
import slide2_1 from './slide2-1.png'
import slide3_1 from './slide3-1.png'
import slide4_1 from './slide4-1.png'
import slide5_1 from './slide5-1.png'
import slide5_2 from './slide5-2.png'
import slide6_1 from './slide6-1.png'
import slide7_1 from './slide7-1.png'
import slide8_1 from './slide8-1.png'
import slide9_1 from './slide9-1.png'

// Slide functions
import { resetSlide } from 'components/popup/Slideshow/slideshowController';

// This is the final list of tutorials of the sequence
// The sequence goes as follows: UserProfileTutorial -> HomePageTutorial -> M8sPageTutorial & D8sPageTutorial & SearchPageTutorial

export default function M8sPageTutorial({tutorialVisible, setTutorialVisible}) {
    const mobile = useSelector(state => state.global.isMobile);

    // Reset slide to slide 1 on popup show
    useEffect(() => {
        if (tutorialVisible) {
            resetSlide();
        }
    },[tutorialVisible])

    const dots = Array(mobile ? 9 : 10).fill(null); // For dots below the slideshow, change number of dots Here when you add/remove slides from return()

    return (
        <div className="tutorial-m8s slideshow-container">

            <div className="popup-slide fade tutorial-m8s-1">
                
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={300} width={700}> 
                    <Box mb={4} fontSize={80}>
                        {/*
                        <img 
                            src="/icons/home.svg" 
                            height={50}
                            style={imageStyle}
                            
                        />
                        */}
                        <QuestionAnswerRoundedIcon fontSize='inherit'/>
                    </Box>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 0 : 7} mb={2}>
                        This is your <b>M8s page</b>, where you manage and chat with your M8s.
                    </Typography>

                    {/*
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7}>
                        Press &emsp; ▶ &emsp; to continue...
                    </Typography>
                    */}
                </Box>
            </div>
            

            <div className="popup-slide fade tutorial-m8s-2">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        Chats are stored in <b>Categories</b>.
                    </Typography>
                    
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide2_1}
                            height={mobile ? 260 : 400}
                            style={imageStyle}
                        />
                       
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-m8s-3">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        New messages from a M8 or you are stored in <b>New Chats</b> category at first.
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide3_1}
                            height={mobile ? 260 : 400}
                            style={imageStyle}
                        />
                    
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-m8s-4">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        To add a Category, simply click the <AddIcon fontSize='inherit'/> button.
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide4_1}
                            height={mobile ? 260 : 400}
                            style={imageStyle}
                        />
                    
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-m8s-5">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        Click on a Category to view Chats.
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide5_1}
                            height={mobile ? 260 : 350}
                            style={imageStyle}
                        />
                        {!mobile && <img 
                            src={slide5_2}
                            height={280}
                            style={imageStyle}
                        />}
                    
                    </Box>
                </Box>
            </div>

            <div className="popup-slide fade tutorial-m8s-6">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        Click on a Chat to start chatting!
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide6_1}
                            height={mobile ? 260 : 400}
                            style={imageStyle}
                        />
                    
                    </Box>
                </Box>
            </div>

            

            <div className="popup-slide fade tutorial-m8s-7">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        On the top right corner, you can perform other chat actions.
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                        mb={2}
                    >
                        <img 
                            src={slide7_1}
                            width={mobile ? 240 : 280}
                            style={imageStyle}
                        />
                    
                    </Box>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={mobile ? 2 : 1}>
                        The actions include:
                    </Typography>
                    {mobile ? 
                        <Typography fontSize={15} fontWeight='normal' textAlign="left" mx={7} mb={4}>
                            <b>• Change Category</b><br/><b>• Delete Chat</b><br/><b>• Block User</b>
                        </Typography>
                    :
                        <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                            <b>Change Category</b>&nbsp;&nbsp;&nbsp;<b>Delete Chat</b>&nbsp;&nbsp;&nbsp;<b>Block User</b>
                        </Typography>
                    }
                    
                </Box>
            </div>

            { !mobile &&
            <div className="popup-slide fade tutorial-m8s-8">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        You can spark interests by referencing the M8's profile during chat. 
                    </Typography>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        This is displayed on the right on desktop or tablet.
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide8_1}
                            width={600}
                            style={imageStyle}
                        />
                    
                    </Box>
                </Box>
            </div>
            }

            <div className="popup-slide fade tutorial-m8s-9">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        To view the chatting M8's profile{!mobile && " on mobile"}, click on the M8's name.
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                        mb={2}
                    >
                        <Box 
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 50
                            }}
                        >
                            <AdUnitsRoundedIcon fontSize='inherit'/> 
                        </Box>
                        
                        <img 
                            src={slide9_1}
                            width={mobile ? 240 : 280}
                            style={imageStyle}
                        />
                    
                    </Box>
                </Box>
            </div>
            
            <div className="popup-slide fade tutorial-m8s-10">

                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        <b>Have fun chatting!</b>
                    </Typography>
                    <Typography fontSize={80} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        <QuestionAnswerRoundedIcon fontSize='inherit'/>
                    </Typography>
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                        mb={!mobile && 1}
                    >
                        
                    </Box>

                    <Button 
                        onClick={() => 
                            {
                                setTutorialVisible(false);
                                localStorage.removeItem('first-time-m8');
                                resetSlide();
                            }
                        }
                        sx={[filledButtonStyle, {width: 180}]}
                        mt={mobile ? '50px' : ''}
                        >
                            Start Chatting
                    </Button>
                </Box>
                </div>
    
            {/* The indicator dots below slides*/}
            <Box 
                className='popup-slide-dot-container' 
                sx={{textAlign: 'center'}}
            >
                {dots.map((_, index) => (
                    <span key={index} className="dot"></span>
                ))}
            </Box>

        </div>
    );
}

