import axios from "../axios";

const createEvents = async (body) => {
  try {
    const response = await axios.post(`/event`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// To be depreciated... maybe
const getAllEvents = async () => {
  try {
    const response = await axios.get(`/event`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getEvents = async () => {
  try {
    const response = await axios.get(`/event/getEvents`);
    return response;
  } catch (error) {
    throw error;
  }
}

const getEventById = async (id) => {
  try {
    const response = await axios.get(`/event/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateEvent = async (id, body) => {
  try {
    const response = await axios.put(`/event/${id}`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteEvent = async (id) => {
  try {
    const response = await axios.delete(`/event/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const claimEvent = async (claimId) => {
  try {
    const response = await axios.post(`/event/claim/${claimId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const unclaimEvent = async (claimId) => {
  try {
    const response = await axios.post(`/event/unclaim/${claimId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const checkClaim = async (claimId) => {
  try {
    const response = await axios.get(`/event/checkClaim/${claimId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getUserClaimedEvents = async () => {
  try {
    const response = await axios.get(`/event/claim/claimed`);
    return response;
  } catch (error) {
    throw error;
  }
};

const eventService = {
  createEvents,
  getAllEvents,
  getEvents,
  getEventById,
  updateEvent,
  deleteEvent,
  claimEvent,
  unclaimEvent,
  checkClaim,
  getUserClaimedEvents
};

export default eventService;
