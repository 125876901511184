import axios from "../axios"

// Gather user information
const getUserById = async (body) => {
    try {
        const response = await axios.post('/admin/getUserById', body)
    } catch (error) {

    }
}
const getUserByName = async (body) => {
    try {
        console.log(body.userName)
    } catch (error) {

    }
}

const adminService = {
    getUserById,
    getUserByName
};

  export default adminService;