export const dialogButtonRegular = {
    textTransform: "none", 
    fontSize: "0.75rem", 
    color: "var(--color-accent)"
}

export const dialogButtonFocus = {
  textTransform: "none", 
  fontSize: "0.75rem", 
  backgroundColor: "var(--color-accent)", 
  color: "var(--color-main)", 
  opacity: 1, 
  transition: '0.3s',
  '&:hover': {
      backgroundColor: "var(--color-accent)", color: "var(--color-main)",
      opacity: 0.5
  }
}