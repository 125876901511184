import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimedEvents: {}
}

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    updateEvents: (state, action) => {
      const claimedEvents = action.payload;
      state.claimedEvents = claimedEvents
    },
    claimEvent: (state, action) => {
      const newEvent = action.payload;
      state.claimedEvents[newEvent.eventId] = newEvent
    },
    unclaimEvent: (state, action) => {
      const eventId = action.payload;
      delete state.claimedEvents[eventId]
    }
  },
});

export const { updateEvents, claimEvent, unclaimEvent } = eventsSlice.actions;
export default eventsSlice.reducer;