export const textFieldOverrides = {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: 'var(--color-accent)',
          },
        },
      },
    },
  };