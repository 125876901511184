import React, { useState } from 'react';
import { useSelector } from 'react-redux'

import { Box, Grid, Typography, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import SearchCard from './SearchCard';

import { isMobile } from '../../scripts/mobileCheck'
;

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});


function Search({ results, setResults }) {
  const mobileLayout = useSelector(state => state.global.isMobile);

  /* Title and Back button (mobile only) */
  const BackButton = (
    <Box 
      sx={{ 
        position: 'fixed',
        top: 57,
        display: 'flex', 
        alignItems: 'center', 
        width: '100%', 
        height: '70px',
        backgroundColor: 'var(--color-main)',
        border: '2px solid var(--color-accent)',
        borderTop: '0px',
        //borderBottomLeftRadius: 'var(--border-radius-main)',
        //borderBottomRightRadius: 'var(--border-radius-main)',
        zIndex: '1000',
      }}
    >
      <IconButton size='small' onClick={() => setResults(null)} >
      &nbsp;&nbsp;&nbsp;&nbsp; <ArrowBackIosIcon />
      </IconButton>
      <Typography ml={2} variant="subtitle1">Search Results</Typography> 
    </Box>
  )

  return (
    <ThemeProvider theme={lightTheme}>
      
      {/* Container for search results */}
      <Box 
        className='search-results'
        sx={
          mobileLayout ?
          { // Mobile layout
            position: 'fixed',
            top: 0,
            left: 0,

            backgroundColor: '#fff',

            height: '100%',
            width: '100%',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '10px',

            overflowY: 'scroll'
          }
          :
          { // PC layout
            height: '100%',
            width: '70vw',
            '@media (max-width: 1504px)': {
              height: '100%',
              width: '60vw'
            },

            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '20px',

            border: '2px solid var(--color-accent)',
            borderRadius: 'var(--border-radius-main)',

            overflowY: 'scroll'
            
          }
        }
      >
          {/* "Search Results" title and Back button, Only show on mobile */}
          {mobileLayout && BackButton}

          {/* Gap between "< Search Results" button and member cards on mobile, ensure no overlap. */}
          {mobileLayout && <Box sx={{width: '100%', height: 140}}></Box>}

          {/* Search results / listing */}
          {
          results.length ? 
            results.map(result => (
              <SearchCard result={result}/>
            ))
          :
            <Box 
              sx={{
                height: '100%', 
                width: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                opacity: 1
              }}
            >
              <Typography fontSize={90}><PersonSearchRoundedIcon fontSize='inherit'/></Typography>
              <Typography fontSize={30} fontWeight={'normal'}></Typography>
            </Box> 
          }
          {/* Bottom margin */}
          {mobileLayout && <Box sx={{width: '100%', height: 50}}></Box>}

      </Box>
    </ThemeProvider>
  );
}

export default Search;
