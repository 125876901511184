export function mongoDateConver(originalDateString) {
    try {
        // Check if the date string is valid
        if (!originalDateString || isNaN(Date.parse(originalDateString))) {
            console.warn(`Invalid date format received: ${originalDateString}`);
            return "Invalid date"; // Return a default message if the date is invalid
        }

        // Create a new Date object
        const date = new Date(originalDateString);

        // Format the date using Intl.DateTimeFormat
        let formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
        
        return formattedDate;
    } catch (error) {
        console.error("Date conversion error:", error.message);
        return "Invalid date"; // Return a default message in case of an error
    }
}
