import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import Papa from 'papaparse';
import { Modal, Box, Typography, useMediaQuery } from '@mui/material';

// A simple UI for the global search filter
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newFilter = inputValue.trim();
      if (newFilter) {
        setGlobalFilter((prevFilter) => [...(prevFilter || []), newFilter]);
        setInputValue(''); // Clear the input field
      }
    }
  };

  return (
    <div style={{ margin: '20px 0' }}>
      <input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Enter keyword(s)..."
        style={{
          fontSize: '18px',
          padding: '14px 20px',
          width: '100%', // Make the input width 100% of its container
          maxWidth: '60%', // Remove fixed width and allow it to shrink with the page sizes
          borderRadius: '30px',
          border: '1px solid #ddd',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          outline: 'none',
          transition: 'border-color 0.3s ease',
        }}
        onFocus={(e) => (e.target.style.borderColor = '#888')}
        onBlur={(e) => (e.target.style.borderColor = '#ddd')}
      />
      <div style={{ marginTop: '10px' }}>
        {globalFilter && globalFilter.map((filter, index) => (
          <span key={index} style={{ display: 'inline-block', marginRight: '10px', padding: '8px 12px', background: '#f1f1f1', borderRadius: '20px' }}>
            {filter}
            <button
              style={{ marginLeft: '8px', cursor: 'pointer', background: 'transparent', border: 'none', color: 'red', fontWeight: 'bold' }}
              onClick={() => {
                const newFilters = globalFilter.filter((_, i) => i !== index);
                setGlobalFilter(newFilters.length > 0 ? newFilters : undefined);
              }}
            >
              ✕
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}

// ... rest of your component code ...


// Function to render text with clickable URLs and "See More" logic
function RenderTextWithLinksAndSeeMore({ value, isExpanded, toggleExpand, isSmallScreen }) {
  const MAX_WORDS = 50; // Set the max number of words before truncating

  // Function to split text by words and handle links
  const renderTextWithLinks = (text) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(regex);

    return parts.map((part, i) =>
      regex.test(part) ? (
        <a key={i} href={part} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
          link
        </a>
      ) : (
        <span key={i}>{part}</span>
      )
    );
  };

  // Splitting the text into an array of words for comparison
  const words = value.split(' ');
  
  // Check if text exceeds max word count
  const needsExpansion = words.length > MAX_WORDS;

  if (needsExpansion && !isExpanded) {
    const truncatedWords = words.slice(0, MAX_WORDS).join(' ');
    return (
      <div>
        {renderTextWithLinks(truncatedWords)}
        {"... "}
        <span
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click from triggering the modal
            toggleExpand();
          }}
        >
          See More
        </span>
      </div>
    );
  }

  // Only make the column clickable if expansion is needed
  return (
    <div>
      {renderTextWithLinks(value)}
      {needsExpansion && isExpanded && (
        <span
          style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click from triggering the modal
            toggleExpand();
          }}
        >
          See Less
        </span>
      )}
    </div>
  );
}

export default function CSVTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({}); // Tracks expanded rows
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal for small screens
  const [modalContent, setModalContent] = useState({}); // Content for the modal

  // Check if the screen size is small (below 768px)
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  // Fetch the CSV and parse it
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/Austin.csv');
        const text = await response.text();
        const parsedData = Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
          quoteChar: '"',
        });
        setData(parsedData.data);
      } catch (error) {
        console.error('Error fetching and parsing CSV data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle row expand/collapse
  const toggleRowExpand = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  // Handle row click for smaller screens
  const handleRowClick = (row) => {
    if (isSmallScreen) {
      const { Date, Location } = row.values;
      setModalContent({ date: Date, location: Location });
      setIsModalOpen(true);
    }
  };

  // Dynamically generate columns based on the parsed CSV headers
  const columns = useMemo(() => {
    if (data.length > 0) {
      const headers = Object.keys(data[0]);

      return headers.map((key, index) => {
        if (index === 0) { // First column: Decrease by 1/3
          return {
            Header: key,
            accessor: key,
            style: { width: '15%', whiteSpace: 'normal' },
            Cell: ({ value, row }) => (
              <RenderTextWithLinksAndSeeMore
                value={value}
                isExpanded={expandedRows[row.index]}
                toggleExpand={() => toggleRowExpand(row.index)}
                isSmallScreen={isSmallScreen}
              />
            ),
          };
        } else if (index === 1) { // Second column: Increase by 2
          return {
            Header: key,
            accessor: key,
            style: { width: '40%', whiteSpace: 'normal' },
            Cell: ({ value, row }) => (
              <RenderTextWithLinksAndSeeMore
                value={value}
                isExpanded={expandedRows[row.index]}
                toggleExpand={() => toggleRowExpand(row.index)}
                isSmallScreen={isSmallScreen}
              />
            ),
          };
        } else if (index === 2 || index === 3) { // Date and Location columns
          return {
            Header: key,
            accessor: key,
            style: isSmallScreen ? { display: 'none' } : { width: '15%', whiteSpace: 'normal' }, // Hide columns on small screens
            Cell: ({ value, row }) => (
              <RenderTextWithLinksAndSeeMore
                value={value}
                isExpanded={expandedRows[row.index]}
                toggleExpand={() => toggleRowExpand(row.index)}
                isSmallScreen={isSmallScreen}
              />
            ),
          };
        } else if (index === 4) { // Hide Keywords column always
          return {
            Header: key,
            accessor: key,
            style: { display: 'none' }, // Hide the keywords column on all screen sizes
          };
        } else {
          return {
            Header: key,
            accessor: key,
            style: { width: '30%', whiteSpace: 'normal' },
            Cell: ({ value, row }) => (
              <RenderTextWithLinksAndSeeMore
                value={value}
                isExpanded={expandedRows[row.index]}
                toggleExpand={() => toggleRowExpand(row.index)}
                isSmallScreen={isSmallScreen}
              />
            ),
          };
        }
      });
    }
    return [];
  }, [data, expandedRows, isSmallScreen]);

  const customGlobalFilter = (rows, ids, filterValue) => {
    if (!filterValue || filterValue.length === 0) return rows;

    return rows.filter(row => {
      return filterValue.some(keyword => {
        const regex = new RegExp(`\\b${keyword}\\b`, 'i');
        return ids.some(id => {
          const cellValue = row.values[id]?.toString() || '';
          return regex.test(cellValue);
        });
      });
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalFilter: customGlobalFilter,
    },
    useGlobalFilter
  );

  const { globalFilter } = state;

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={{ width: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
      {/* Search Box with Exact Keyword Matching */}
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      
      <table
        {...getTableProps()}
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          tableLayout: 'fixed',
          backgroundColor: '#f8f8f8',
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '12px',
                    borderBottom: '2px solid #ddd',
                    backgroundColor: '#000',
                    color: '#fff',
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    borderRight: '1px solid #ddd',
                    ...column.style,
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  backgroundColor: row.index % 2 === 0 ? '#fff' : '#f2f2f2',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={() => handleRowClick(row)}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e0e0e0')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = row.index % 2 === 0 ? '#fff' : '#f2f2f2')}
              >
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: '12px',
                      borderBottom: '1px solid #ddd',
                      borderRight: '1px solid #ddd',
                      color: '#333',
                      ...cell.column.style,
                      wordWrap: 'break-word',
                      position: 'relative',
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Modal for showing details on small screens */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} sx={{ outline: 'none' }}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#000',
          color: '#fff',
          p: 4,
          borderRadius: '12px',
          width: '80%',
          maxWidth: '600px',
          boxShadow: 24,
          outline: 'none',
        }}>
          <Typography variant="h6" component="h2">
            Additional Details
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong>Date:</strong> {modalContent.date || 'N/A'}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong>Location:</strong> {modalContent.location || 'N/A'}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
