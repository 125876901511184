import React, { useState } from 'react';
import { Container, Typography, Button, Box, Tab, Tabs, ThemeProvider, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { BasicsSearchForm, CareerSearchForm, PhysicalSearchForm, PersonalitySearchForm, TheTeaSearchForm } from '../forms/createFormComponent';
import userService from '../../api/services/userService';

import { isMobile } from '../../scripts/mobileCheck';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: grey[900], // Style for active tab text
          },
        },
      },
    },
  },
});

const Search = (props) => {
  const { searchCriteria, setSearchCriteria, setResults } = props;
  const [tabValue, setTabValue] = useState(0); // State to track the current tab

  const formsConfig = [
    { title: 'Basics', component: BasicsSearchForm, dataKey: 'basics' },
    { title: 'Career', component: CareerSearchForm, dataKey: 'career' },
    { title: 'Physical', component: PhysicalSearchForm, dataKey: 'physical' },
    { title: 'Personality', component: PersonalitySearchForm, dataKey: 'personality' },
    { title: 'The Tea', component: TheTeaSearchForm, dataKey: 'theTea' },
  ];

  const updateSearchCriteria = (section, data) => {
    setSearchCriteria(prev => ({ ...prev, [section]: data }));
  };

  const handleSubmit = () => {
    userService.searchUserInfos(searchCriteria).then((res) => {
      setResults(res)
    }).catch((err) => {
      console.log(err)
    })
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={lightTheme}>

      <Box 
        className='search'
        sx={
          isMobile() ?
          { // Mobile layout
            width: '100%',

            border: '0px solid var(--color-accent)',
          }
          :
          { // PC layout
            width: '450px',
            height: '100%',
            overflowY: 'scroll',

            border: '2px solid var(--color-accent)',
            borderRadius: 'var(--border-radius-main)'

          }
        }
      >
        <Box
        mx={2}
        my={2}
        mb={5}
        >
          <Box 
            className='search-category' 
            sx={
              isMobile() ?
              { // Mobile layout
                /*
                borderBottom: 1, 
                borderColor: 'divider' 
                */
              }
              :
              { // PC layout
                /*
                borderBottom: 1, 
                borderColor: 'divider',
                */
                

                // Rotate category 90 for PC version
                /*
                transform: 'rotate(-90deg)',
                '-webkit-transform': 'rotate(-90deg)',
                '-moz-transform': 'rotate(-90deg)',
                '-o-transform': 'rotate(-90deg)',
                '-ms-transform': 'rotate(-90deg)',

                position: 'fixed',
                top: '50vh',
                left: '1px'
                */
                
              }
            }
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto" // 'auto' will only show scroll buttons when needed
              allowScrollButtonsMobile
              textColor="primary"
              indicatorColor="primary"
              sx={{
                '.MuiTab-textColorPrimary': { color: grey[900] },
                '.MuiTabs-indicator': { backgroundColor: grey[900] },
              }}
            >
              {formsConfig.length && formsConfig.map((form, index) => (
                <Tab label={form.title} sx={{ textTransform: 'none'}} key={index} />
              ))}
            </Tabs>
          </Box>

          {formsConfig.length && formsConfig.map((form, index) => (
            <div
              role="tabpanel"
              hidden={tabValue !== index}
              id={`tabpanel-${index}`}
              aria-labelledby={`tab-${index}`}
              key={index}
            >
              {tabValue === index && (
                <Box p={3} >
                  <form.component
                    initialValues={searchCriteria[form.dataKey]}
                    onSubmit={(data) => updateSearchCriteria(form.dataKey, data)}
                    onValuesChange={(updatedValues) => {
                      setSearchCriteria(prev => ({ ...prev, [form.dataKey]: updatedValues }));
                    }}
                    isEditMode={false}
                    margin={"dense"}
                  />
                </Box>
              )}
            </div>
          ))}

          <Box display="flex" justifyContent="center" mt={2}>
            <Button 
              variant="contained" 
              onClick={handleSubmit}
              sx={{ 
                backgroundColor: 'var(--color-accent)', 
                borderRadius: '9999px',
                textTransform: 'none',
                transition: '0.2s',
                color: 'var(--color-text-invert)', 
                opacity: 1,
                '&:hover': {
                  backgroundColor: 'var(--color-accent)', 
                  color: 'var(--color-text-invert)', 
                  opacity: 0.7,
                }
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
    </Box>

    </ThemeProvider>
  );
};

export default Search;
