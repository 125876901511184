import React, { useState } from 'react';
import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import CreateEvent from './CreateEvent';
import eventService from '../../../api/services/eventService';

const EventCarousel = ({ eventData, setEventData, setEditView }) => {
  const [currentEvent, setCurrentEvent] = useState({})
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    console.log(eventData)
    console.log(eventData[currentIndex])
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleNext = () => {
    console.log(eventData)
    console.log(eventData[currentIndex])
    setCurrentIndex((prevIndex) => (prevIndex < eventData.length - 1 ? prevIndex + 1 : eventData.length - 1));
  };

  const createFormData = (events) => {
    const formData = new FormData();
  
    events.forEach((event, index) => {
      formData.append(`file${index}`, event.image.file);
      const eventData = { ...event, image: { name: event.image.name } };
      formData.append(`eventData${index}`, JSON.stringify(eventData));
    });
  
    return formData;
  };

  const handleUpload = async () => {
    const formData = createFormData(eventData);
  
    try {
      const response = await eventService.createEvents(formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setEditView(null)
    } catch (error) {
      console.error('Error uploading:', error);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: "space-between", mt: 3, mx: 5 }}>
          <Typography variant="subtitle1">{"D8: " + (currentIndex + 1) + "/" + eventData.length}</Typography>
          <Button variant="text" size="small" sx={{ textTransform: "none" }} onClick={handleUpload}>Create D8s</Button>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton sx={{ mx: 2 }} onClick={handlePrev} disabled={currentIndex === 0}>
          <ArrowBackIos />
        </IconButton>
        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>
          <CreateEvent event={eventData[currentIndex]} index={currentIndex} setEvents={setEventData} />
        </Box>
        <IconButton sx={{ mx: 2 }} onClick={handleNext} disabled={currentIndex === eventData.length - 1}>
          <ArrowForwardIos />
        </IconButton>
      </Box>
    </Box>
  );
};

export default EventCarousel;
