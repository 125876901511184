import formService from '../../api/services/formService';
import dayjs from 'dayjs';

const genderIdentityOptions = ['Male', 'Female', 'Transgender Female', 'Transgender Male', 'Non-Binary/Genderqueer/Gender Fluid', 'Two Spirited', 'Prefer Not To Say', 'Other'];
const stageOfLifeOptions = ['Still In School', 'Grinding At Work', 'Figuring Myself Out', 'Married', 'Has Kid(s)', 'Living Life', 'Single','In A Relationship','Divorced'];
const religionOptions = ['Agnostic', 'Atheist', 'Buddhist', 'Catholic', 'Christian', 'Hindu', 'Jewish', 'Muslim', 'Sikh', 'Spiritual', 'Prefer Not To Say', 'Other'];
const incomeOptions = ['0-50,000', '50,000-100,000', '100,000-200,000', '200,000-400,000', '400,000-1,000,000', '1,000,000+'];
const ethnicityOptions = ['Hispanic Or Latino', 'American Indian Or Alaskan Native', 'Asian','Southeast Asian','Middle Eastern', 'Native Hawaiian Or Other Pacific Islander', 'Black Or African American', 'White'];
const eyeColorOptions = ['Amber', 'Blue', 'Brown', 'Gray', 'Hazel', 'Red', 'Green','Black', 'Other'];
const hairColorOptions = ['Blonde', 'Brown', 'Red', 'Black', 'White', 'Gray', 'Other'];
const personalityTypeOptions = ['INTJ', 'INTP', 'ENTJ', 'ENTP', 'INFJ', 'INFP', 'ENFJ', 'ENFP', 'ISTJ', 'ISFJ', 'ESTJ', 'ESFJ', 'ISTP', 'ESTP', 'ESFP'];
const morningNightOptions = ['Morning', 'Night'];
const introExtroVertOptions = ['Introverted', 'Extroverted'];
const relationshipIntentOptions = [
    "Looking To Have Fun",
    "Not Necessarily Looking For Anything Serious But Am Open To Seeing Where Things Go",
    "Looking For A Boy/Girlfriend",
    "Looking To Settle Down",
    "Polyamorous",
    "Exploring My Identity",
    "Not Looking"
];
const friendshipIntentOptions = [
    "Looking To Meet New People",
    "Looking To Meet Someone Just Like Me",
    "Looking To Make A Best Friend",
    "Not Looking To Make New Friends But Interested In Meeting New People",
    "Interested In Using M8 In Other Ways",
    "Not Looking"
];
const idealFD8Options = [
    "Getting Your Nails Done",
    "Wine Night",
    "Movie Theatre",
    "TV Show/Movie Marathon",
    "Playing A Sport",
    "Grabbing Coffee",
    "Grabbing A Drink",
    "Smoke Sesh",
    "Breakfast",
    "Brunch",
    "Lunch",
    "Dinner",
    "Meet Up At A Party/Social",
    "Walk In The Park",
    "Board/Card Games",
    "Playing/Listening To Music",
    "Group Hangout",
    "Exploring New Neighborhoods",
    "Shopping",
    "People watching",
    "Going To A Sporting Event",
    "Other"
];
const idealRD8Options = [
    "Breakfast",
    "Brunch",
    "Lunch",
    "Dinner",
    "Grabbing Coffee",
    "Grabbing A Drink",
    "Smoke Sesh",
    "Netflix And Chill",
    "Walk In The Park",
    "Movie Theatre",
    "Board/Card Games",
    "Playing/Listening To Music",
    "Meet Up At A Party/Social",
    "Group Hangout",
    "Exploring New Neighborhoods",
    "Playing A Sport",
    "Going To A Sporting Event",
    "Other"
];
const coreValuesOptions = [
    "Authenticity",
    "Accountability",
    "Achievement",
    "Adventure",
    "Authority",
    "Autonomy",
    "Balance",
    "Beauty",
    "Boldness",
    "Compassion",
    "Challenge",
    "Citizenship",
    "Community",
    "Competency",
    "Collaboration",
    "Contribution",
    "Creativity",
    "Curiosity",
    "Determination",
    "Fairness",
    "Faith",
    "Fame",
    "Friendships",
    "Fun",
    "Growth",
    "Happiness",
    "Honesty",
    "Humor",
    "Influence",
    "Inner Harmony",
    "Justice",
    "Kindness",
    "Knowledge",
    "Leadership",
    "Learning",
    "Love",
    "Loyalty",
    "Meaningful Work",
    "Openness",
    "Optimism",
    "Patriotism",
    "Peace",
    "Pleasure",
    "Popularity",
    "Recognition",
    "Religion",
    "Reputation",
    "Resilience",
    "Respect",
    "Responsibility",
    "Security",
    "Self-Respect",
    "Service",
    "Spirituality",
    "Stability",
    "Success",
    "Status",
    "Trustworthiness",
    "Wisdom"
];
const disabilityOptions = [
    "Paraplegia",
    "Quadriplegia",
    "Cerebral palsy",
    "Multiple sclerosis (MS)",
    "Muscular dystrophy",
    "Spina bifida",
    "Parkinson's disease",
    "Amyotrophic lateral sclerosis (ALS)",
    "Guillain-Barr� syndrome",
    "Rheumatoid arthritis",
    "Osteoarthritis",
    "Amputation",
    "Spinal cord injury",
    "Traumatic brain injury (TBI)",
    "Epilepsy",
    "Down syndrome",
    "Autism spectrum disorder (ASD)",
    "Intellectual disability",
    "Williams syndrome",
    "Fragile X syndrome",
    "Tourette syndrome",
    "Dyslexia",
    "Dyscalculia",
    "ADHD (Attention Deficit Hyperactivity Disorder)",
    "Bipolar disorder",
    "Schizophrenia",
    "Major depressive disorder",
    "Anxiety disorders (e.g., generalized anxiety disorder, social anxiety disorder)",
    "Obsessive-compulsive disorder (OCD)",
    "Post-traumatic stress disorder (PTSD)",
    "Sensory processing disorder (SPD)",
    "Auditory processing disorder (APD)",
    "Visual processing disorder",
    "Blindness",
    "Low vision",
    "Color blindness",
    "Deafness",
    "Hard of hearing",
    "Aphasia",
    "Dysarthria",
    "Apraxia of speech",
    "Stuttering",
    "Voice disorders",
    "Huntington's disease",
    "Fibromyalgia",
    "Chronic fatigue syndrome",
    "Crohn's disease",
    "Irritable bowel syndrome (IBS)",
    "Diabetes",
    "Asthma",
    "COPD (Chronic obstructive pulmonary disease)",
    "Cystic fibrosis",
    "Heart disease",
    "Hypertension",
    "Stroke",
    "Chronic pain disorders",
    "Endometriosis",
    "Polycystic ovary syndrome (PCOS)",
    "Erectile dysfunction",
    "Premature ejaculation",
    "Vaginismus",
    "Anorexia nervosa",
    "Bulimia nervosa",
    "Binge eating disorder",
    "Insomnia",
    "Narcolepsy",
    "Restless legs syndrome (RLS)",
    "Sleep apnea",
    "Seasonal affective disorder (SAD)",
    "Borderline personality disorder (BPD)",
    "Narcissistic personality disorder (NPD)",
    "Antisocial personality disorder (ASPD)",
    "Avoidant personality disorder",
    "Obsessive-compulsive personality disorder (OCPD)",
    "Asperger's syndrome",
    "Schizoid personality disorder",
    "Histrionic personality disorder",
    "Paranoid personality disorder",
    "Dependent personality disorder",
    "Hemiplegia",
    "Brachial plexus injury",
    "Bell's palsy",
    "Temporomandibular joint disorder (TMJ)",
    "Carpal tunnel syndrome",
    "Tendinitis",
    "Osteoporosis",
    "Scoliosis",
    "Arthritis",
    "Ehlers-Danlos syndrome",
    "Marfan syndrome",
    "Osteogenesis imperfecta",
    "Myasthenia gravis",
    "Polio (Post-polio syndrome)",
    "Charcot-Marie-Tooth disease",
    "Rett syndrome",
    "Attention deficit hyperactivity disorder (ADHD)",
    "Turner syndrome",
    "Klinefelter syndrome",
    "Fetal alcohol syndrome (FAS)",
    "Angelman syndrome",
    "Prader-Willi syndrome",
    "Beckwith-Wiedemann syndrome",
    "Duchenne muscular dystrophy",
    "Asperger syndrome",
    "Cornelia de Lange syndrome",
    "Treacher Collins syndrome",
    "Albinism",
    "Apert syndrome",
    "Neurofibromatosis",
    "Moebius syndrome",
    "Patau syndrome",
    "Edwards syndrome",
    "DiGeorge syndrome",
    "Noonan syndrome",
    "Smith-Magenis syndrome",
    "Cri du chat syndrome",
    "Lesch-Nyhan syndrome",
    "Prune belly syndrome",
    "Achondroplasia",
    "Arthrogryposis",
    "Fibrodysplasia ossificans progressiva",
    "Gaucher disease",
    "Hemophilia",
    "Schizoaffective disorder",
    "Depersonalization disorder",
    "Dissociative identity disorder (DID)",
    "Generalized anxiety disorder (GAD)",
    "Social anxiety disorder (SAD)",
    "Panic disorder",
    "Agoraphobia",
    "Specific phobias",
    "Binge-eating disorder",
    "Avoidant/restrictive food intake disorder (ARFID)",
    "Orthorexia nervosa",
    "Rumination disorder",
    "Pica",
    "Gender dysphoria",
    "Body dysmorphic disorder (BDD)",
    "Trichotillomania",
    "Excoriation disorder",
    "Hoarding disorder",
    "Compulsive buying disorder",
    "Internet gaming disorder",
    "Gambling disorder",
    "Kleptomania",
    "Pyromania",
    "Factitious disorder",
    "Conversion disorder",
    "Malingering",
    "Somatization disorder",
    "Somatic symptom disorder",
    "Illness anxiety disorder",
    "Dissociative amnesia",
    "Dissociative fugue",
    "Depersonalization/derealization disorder",
    "Body integrity dysphoria",
    "Erotomania",
    "Folie � deux",
    "Capgras syndrome",
    "Cotard delusion",
    "Koro",
    "Lycanthropy",
    "Fregoli delusion",
    "Reduplicative paramnesia",
    "Alien hand syndrome",
    "Alice in Wonderland syndrome",
    "Stendhal syndrome",
    "Paris syndrome",
    "Jerusalem syndrome"
];
const recreationalSubstancesOptions = ['Weed', 'Alcohol', 'Cocaine', 'Acid', 'MDMA', 'Heroin', 'Meth','Fentanyl'];
const politicsOptions = ['Democratic', 'Republican', 'Independent', 'Hate Politics','Other'];
const sexualityOptions = ['Straight', 'Gay', 'Lesbian', 'Bisexual', 'Asexual'];
const roommateOptions = ['Yes', 'No'];
const bodyTypeOptions = ['/bodyTypes/Slim.svg', '/bodyTypes/Tone.svg', '/bodyTypes/Muscular.svg', '/bodyTypes/Stocky.svg', '/bodyTypes/Large.svg'];

const fetchDynamicOptions = async (searchTerm, fieldName) => {
    switch (fieldName) {
        case 'occupation':
            return formService.getOccupations(searchTerm);
        case 'classes':
            return formService.getClasses(searchTerm);
        case 'currentCity':
            return formService.getCurrentCity(searchTerm);
        case 'schools':
            return formService.getSchool(searchTerm);
        case 'interests':
            return formService.getInterests(searchTerm);
        case 'music':
            return formService.getMusic(searchTerm);
        case 'favoriteFoods':
            return formService.getFavoriteFoods(searchTerm);
        case 'tvMovies':
            return formService.getMedia(searchTerm);
        case 'comedians':
            return formService.getComedians(searchTerm);
        case 'sportsTeams':
            return formService.getSports(searchTerm);
        case 'citiesVisitedLived':
            return formService.getCurrentCity(searchTerm);
        
        default:
            return [];
    }
};

const maxDate = dayjs().subtract(18, 'year');

const formConfig = {
    basics: [
        { type: 'singleAutocomplete', name: 'currentCity', label: 'Current City', fetchOptions: (input) => fetchDynamicOptions(input, 'currentCity') },
        { type: 'singleAutocomplete', name: 'genderIdentity', label: 'Gender Identity', options: genderIdentityOptions },
        { type: 'multiSelect', name: 'stageOfLife', label: 'Stage of Life', options: stageOfLifeOptions },
        { type: 'select', name: 'religion', label: 'Religion', options: religionOptions },
        { type: 'datePicker', name: 'dateOfBirth', label: 'Date of Birth', inputFormat: 'MM/DD/YYYY', maxDate }
    ],
    basicsSearch: [
        { type: 'multiAutocomplete', name: 'currentCity', label: 'Current City', fetchOptions: (input) => fetchDynamicOptions(input, 'currentCity') },
        { type: 'multiSelect', name: 'genderIdentity', label: 'Gender Identity', options: genderIdentityOptions },
        { type: 'multiSelect', name: 'stageOfLife', label: 'Stage of Life', options: stageOfLifeOptions },
        { type: 'multiSelect', name: 'religion', label: 'Religion', options: religionOptions },
        { type: 'slider', name: 'ageRange', label: 'Age Range', min: 18, max: 100, step: 1, formatLabel: (value) => `${value} years`, isRange: true }
    ],
    career: [
        { type: 'text', name: 'company', label: 'Company' },
        { type: 'select', name: 'income', label: 'Income ($)', options: incomeOptions },
        { type: 'multiAutocomplete', name: 'occupation', label: 'Occupation', fetchOptions: (input) => fetchDynamicOptions(input, 'occupation') },
        { type: 'multiAutocomplete', name: 'classes', label: 'Classes', fetchOptions: (input) => fetchDynamicOptions(input, 'classes') },
        { type: 'multiAutocomplete', name: 'schools', label: 'Schools', fetchOptions: (input) => fetchDynamicOptions(input, 'schools') }
    ],
    careerSearch: [
        { type: 'text', name: 'company', label: 'Company' },
        { type: 'multiSelect', name: 'income', label: 'Income ($)', options: incomeOptions },
        { type: 'multiAutocomplete', name: 'occupation', label: 'Occupation', fetchOptions: (input) => fetchDynamicOptions(input, 'occupation') },
        { type: 'multiAutocomplete', name: 'classes', label: 'Classes', fetchOptions: (input) => fetchDynamicOptions(input, 'classes') },
        { type: 'multiAutocomplete', name: 'schools', label: 'Schools', fetchOptions: (input) => fetchDynamicOptions(input, 'schools') }
    ],
    physical: [
        { type: 'slider', name: 'height', label: 'Height (inches)', min: 24, max: 108, step: 1, formatLabel: (value) => `${Math.floor(value / 12)}' ${value % 12}"` },
        { type: 'text', name: 'weight', label: 'Weight (lbs)' },
        { type: 'multiSelect', name: 'ethnicity', label: 'Ethnicity', options: ethnicityOptions },
        { type: 'select', name: 'eyeColor', label: 'Eye Color', options: eyeColorOptions },
        { type: 'select', name: 'hairColor', label: 'Hair Color', options: hairColorOptions },
        { type: 'selectImage', name: 'bodyType', label: 'Body Type', options: bodyTypeOptions },
        { type: 'multiAutocomplete', name: 'disability', label: 'Disability', options: disabilityOptions }
    ],
    physicalSearch: [
        { type: 'slider', name: 'heightRange', label: 'Height Range', min: 24, max: 108, step: 1, formatLabel: (value) => `${Math.floor(value / 12)}' ${value % 12}"`, isRange: true },
        { type: 'slider', name: 'weightRange', label: 'Weight Range', min: 50, max: 600, step: 5, formatLabel: (value) => `${value} lbs`, isRange: true },
        { type: 'multiSelect', name: 'ethnicity', label: 'Ethnicity', options: ethnicityOptions },
        { type: 'multiSelect', name: 'eyeColor', label: 'Eye Color', options: eyeColorOptions },
        { type: 'multiSelect', name: 'hairColor', label: 'Hair Color', options: hairColorOptions },
        { type: 'multiAutocompleteImage', name: 'bodyType', label: 'Body Type', options: bodyTypeOptions },
        { type: 'multiAutocomplete', name: 'disability', label: 'Disability', options: disabilityOptions }
    ],
    personality: [
        { type: 'select', name: 'personalityType', label: 'Personality Type', options: personalityTypeOptions },
        { type: 'multiSelect', name: 'introExtroVert', label: 'Introvert/Extrovert', options: introExtroVertOptions },
        { type: 'multiSelect', name: 'morningNight', label: 'Morning/Night', options: morningNightOptions },
        { type: 'select', name: 'relationshipIntent', label: 'Relationship Intent', options: relationshipIntentOptions },
        { type: 'select', name: 'friendIntent', label: 'Friendship Intent', options: friendshipIntentOptions },
        { type: 'multiSelect', name: 'idealFD8', label: 'Ideal Friendship D8', options: idealFD8Options },
        { type: 'multiSelect', name: 'idealRD8', label: 'Ideal Relationship D8', options: idealRD8Options },
        { type: 'multi5Autocomplete', name: 'coreValues', label: 'Core Values', options: coreValuesOptions },
        { type: 'emojiPicker', name: 'favoriteEmojis', label: 'Favorite Emoji\'s' },
    ],
    personalitySearch: [
        { type: 'multiSelect', name: 'personalityType', label: 'Personality Type', options: personalityTypeOptions },
        { type: 'multiSelect', name: 'introExtroVert', label: 'Introvert/Extrovert', options: introExtroVertOptions },
        { type: 'multiSelect', name: 'morningNight', label: 'Morning/Night', options: morningNightOptions },
        { type: 'multiSelect', name: 'relationshipIntent', label: 'Relationship Intent', options: relationshipIntentOptions },
        { type: 'multiSelect', name: 'friendIntent', label: 'Friendship Intent', options: friendshipIntentOptions },
        { type: 'multiSelect', name: 'idealFD8', label: 'Ideal Friendship D8', options: idealFD8Options },
        { type: 'multiSelect', name: 'idealRD8', label: 'Ideal Relationship D8', options: idealRD8Options },
        { type: 'multiAutocomplete', name: 'coreValues', label: 'Core Values', options: coreValuesOptions },
        { type: 'emojiPicker', name: 'favoriteEmojis', label: 'Favorite Emoji\'s'},
    ],
    theTea: [
        { type: 'select', name: 'politics', label: 'Politics', options: politicsOptions },
        { type: 'select', name: 'sexuality', label: 'Sexuality', options: sexualityOptions },
        { type: 'select', name: 'roommatePreference', label: 'Looking for Roommate', options: roommateOptions },
        { type: 'multiSelect', name: 'drugsAlcohol', label: 'Recreational Substances', options: recreationalSubstancesOptions },
        { type: 'multiAutocomplete', name: 'interests', label: 'Interests', fetchOptions: (input) => fetchDynamicOptions(input, 'interests') },
        { type: 'multiAutocomplete', name: 'music', label: 'Music', fetchOptions: (input) => fetchDynamicOptions(input, 'music') },
        { type: 'multiAutocomplete', name: 'favoriteFoods', label: 'Favorite Foods', fetchOptions: (input) => fetchDynamicOptions(input, 'favoriteFoods') },
        { type: 'multiAutocomplete', name: 'tvMovies', label: 'TV/Movies', fetchOptions: (input) => fetchDynamicOptions(input, 'tvMovies') },
        { type: 'multiAutocomplete', name: 'comedians', label: 'Comedians', fetchOptions: (input) => fetchDynamicOptions(input, 'comedians') },
        { type: 'multiAutocomplete', name: 'sportsTeams', label: 'Sports Teams', fetchOptions: (input) => fetchDynamicOptions(input, 'sportsTeams') },
        { type: 'multiAutocomplete', name: 'citiesVisitedLived', label: 'Cities Visited/Lived', fetchOptions: (input) => fetchDynamicOptions(input, 'citiesVisitedLived') }
    ],
    theTeaSearch: [
        { type: 'multiSelect', name: 'politics', label: 'Politics', options: politicsOptions },
        { type: 'multiSelect', name: 'sexuality', label: 'Sexuality', options: sexualityOptions },
        { type: 'multiSelect', name: 'roommatePreference', label: 'Looking for Roommate', options: roommateOptions },
        { type: 'multiSelect', name: 'drugsAlcohol', label: 'Recreational Substances', options: recreationalSubstancesOptions },
        { type: 'multiAutocomplete', name: 'interests', label: 'Interests', fetchOptions: (input) => fetchDynamicOptions(input, 'interests') },
        { type: 'multiAutocomplete', name: 'music', label: 'Music', fetchOptions: (input) => fetchDynamicOptions(input, 'music') },
        { type: 'multiAutocomplete', name: 'favoriteFoods', label: 'Favorite Foods', fetchOptions: (input) => fetchDynamicOptions(input, 'favoriteFoods') },
        { type: 'multiAutocomplete', name: 'tvMovies', label: 'TV/Movies', fetchOptions: (input) => fetchDynamicOptions(input, 'tvMovies') },
        { type: 'multiAutocomplete', name: 'comedians', label: 'Comedians', fetchOptions: (input) => fetchDynamicOptions(input, 'comedians') },
        { type: 'multiAutocomplete', name: 'sportsTeams', label: 'Sports Teams', fetchOptions: (input) => fetchDynamicOptions(input, 'sportsTeams') },
        { type: 'multiAutocomplete', name: 'citiesVisitedLived', label: 'Cities Visited/Lived', fetchOptions: (input) => fetchDynamicOptions(input, 'citiesVisitedLived') }
    ]
};

export default formConfig;
