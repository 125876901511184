import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    AppBar, Box, Toolbar, IconButton, Typography, Menu,
    Avatar, Button, Tooltip, MenuItem, Badge, Container, ThemeProvider, createTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import SearchIcon from '@mui/icons-material/Search';  // Commented out SearchIcon

import YM8Content from '../components/help/YM8';

const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});



function YM8() {
    return (
        <ThemeProvider theme={lightTheme}>
            <Container 
                maxWidth='xl'
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <div>
                        <YM8Content/>
                    </div>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default YM8;
