import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import {
  AppBar, Box, Toolbar, IconButton, Typography, Menu,
  Avatar, Button, Tooltip, MenuItem, Badge, Container
} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import MenuIcon from '@mui/icons-material/Menu';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ForumIcon from '@mui/icons-material/Forum';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';

import socket from 'socketio/socket';
import userService from '../../api/services/userService';
import chatService from 'api/services/chatService';

import { 
  setChatSessionId,
  setFriendId,
  setFriendUserInfo,
  setSelectedCategoryId 
} from 'app/slices/chat.slice';
import { 
  updateUser 
} from 'app/slices/user.slice';

import { delay } from 'scripts/delay';
import { setGroupChatSessionId } from 'app/slices/groupchat.slice';

export default function NavBar({forceUpdate}) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [renderNavBar, setRenderNavBar] = React.useState(true);

  // Logout handle
  const handleLogout = async (event) => {
    event.preventDefault(); // Prevent the default form submission
    try {
      const response = await userService.logout();
      
      if (response === "OK") {
        dispatch(updateUser(''));
        dispatch(setSelectedCategoryId(null));
        dispatch(setChatSessionId(null));
        dispatch(setGroupChatSessionId(null));
        dispatch(setFriendId(null));
        dispatch(setFriendUserInfo({}));
        socket.disconnect()
        navigate("/login")
      }
    } catch (error) {
      console.error(error); // Handle errors appropriately
    }
  };
  
  // Nav menu icon click handler
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  // Nav pages
  const pages = 
  [ 
    { text: 'M8s', link: "/chats", role: "user", type: 'link'  },
    
    //{ text: 'D8s', link: "/events", role: "user", type: 'link'   },
    { text: 'Austin', link: "/events", role: "user", type: 'link' }, // Share same url as D8 for now, prep for full D8 page migration
    { text: 'Search', link: "/search", role: "user", type: 'link'   },
  
    { text: 'YM8', link: "/YM8", role: "user", type: 'link'  },
    //{ text: 'How2M8', link: "/How2M8", role: "user", type: 'link'  },
    { text: 'The Difference', link: "/Difference", role: "user", type: 'link'  },
    { text: 'No H8', link: "/NoH8", role: "user", type: 'link'  },

    { text: 'Admin', link: "/admin", role: "admin", type: 'link'  },
  ];
  const handlePageClick = async () => {
    handleCloseNavMenu();

    // Clear Chat page states on entering Chat page
    /*
    if (window.location.href.indexOf('chat') > -1) {
      console.log('true')
      dispatch(setSelectedCategoryId(null));
      dispatch(setChatSessionId(null));
      dispatch(setFriendId(null));
      dispatch(setFriendUserInfo({}));
    }
    */
    
  }

  // User avatar click handler
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
   // User menu options
    const options = 
    [ 
     { icon: <PersonRoundedIcon fontSize='inherit'/>, text: 'Edit Profile', link: "/user-profile", role: "user", type: 'link' },
     { icon: <PeopleAltRoundedIcon fontSize='inherit'/>, text: 'Referral', link: "/referral", role: "user", type: 'link' },
     { icon: <SettingsRoundedIcon fontSize='inherit'/>, text: 'Settings', link: "/settings", role: "user", type: 'link' },
     { icon: <LogoutRoundedIcon fontSize='inherit'/>, text: 'Logout', onClick: handleLogout, role: "user", type: 'button'}
     
    ];
  

  // Get total registered users on site
  const [userCount, setUserCount] = React.useState("0");
  React.useEffect(() => {
      var total_count = ""
      // userService.getUserInfoDetails().then((res) =>{ // this works when I use existing API.
      userService.getUsercount().then((res) => { // this does not work when I used my own created API. 

          total_count = " Total M8s: " + res.count
          setUserCount(total_count)
      })
  },[])


  /* Logo / Home button */
  const NavLogo = (<>
    {!localStorage.getItem("first-time") ?
      // Normal Logo/Home button
      //<Tooltip title="Home" enterDelay={550}>
        <Link to="/" onClick={handlePageClick}>
          <img 
            src='/logo/WM8.svg' 
            alt="Logo" 
            style={
              { 
                width: "25px", 
                marginRight: "2vw",
                'user-drag': 'none',
                '-webkit-user-drag': 'none',
                'user-select': 'none',
                '-moz-user-select': 'none',
                '-webkit-user-select': 'none',
                '-ms-user-select': 'none'
              }
            } 
            />
        </Link>
      //</Tooltip>
    : // Special logo button for first time users
      <Tooltip title="Home">
        <Button
            onClick={() => {
              localStorage.removeItem('first-time');
              localStorage.removeItem('first-time-user');
              
              localStorage.setItem('first-time-home', true);
              localStorage.setItem('first-time-search', true);
              localStorage.setItem('first-time-m8', true);
              localStorage.setItem('first-time-d8', true);
              
              navigate(`/`);
              window.location.reload(true);
            }}
        >
            <img 
              src='/logo/WM8.svg' 
              alt="Logo" 
              style={
                { 
                  width: "25px", 
                  marginRight: "2vw",
                  'user-drag': 'none',
                  '-webkit-user-drag': 'none',
                  'user-select': 'none',
                  '-moz-user-select': 'none',
                  '-webkit-user-select': 'none',
                  '-ms-user-select': 'none'
                }
              } 
            />
        </Button>
      </Tooltip>
    }
  </>)

  /* Nav links */
  const NavLinks = (<>
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
      {/* Show burger icon for mobile */}
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        { // For new users, hide Navbar and show direction instead
          !localStorage.getItem("first-time") ?
          <MenuIcon />
          :
          <Box display='flex' alignContent='center' mt={1} position={'fixed'} left={50} top={10}>
            <ArrowBackRoundedIcon fontSize='small'/> &nbsp; <Typography fontSize={14} fontWeight={'bold'}>Click when done </Typography>
          </Box>
        } 
      </IconButton>
      <Menu
        className='header-menu-nav'
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' }, // `xs` for mobile display mode, `md` for PC display mode. Hide menu for PC by setting `md` to none
        }}
      >
        {pages.length && pages.map((page, index) => {
          if (page.role === "user" || ( page.role === "admin" && user.role === "admin" )) {
            let clickFunction = handleCloseNavMenu;

            if (page.type === "button") {
              clickFunction = page.onClick;
            }

            return (
              <MenuItem key={page.link} component={Link} to={page.link} onClick={clickFunction}>
                <Typography textAlign="center">{page.text}</Typography>
              </MenuItem>
            )
          }}
        )}
      </Menu>
      </Box>

      {/* Show navs as is for PC */}
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      { // For new users, hide Navbar and show direction instead
        !localStorage.getItem("first-time") ?
          pages.length && pages.map((page) => (
            <>
              {(page.role === "user" || ( page.role === "admin" && user.role === "admin" )) && (
                <Button
                  component={Link}
                  to={page.link}
                  key={page.text}
                  onClick={handlePageClick}
                  sx={{ 
                    my: 2, 
                    color: 'white', 
                    display: 'block', 
                    textAlign: 'center', 
                    textTransform: 'none',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    paddingLeft: 1, 
                    paddingRight: 1, 
                  }}
                >
                  {page.text}
                </Button>
              )}
            </>
          ))
        :
        <Box display='flex' alignContent='center' mt={1} position={'fixed'} left={200} top={15}>
            <ArrowBackRoundedIcon fontSize='small'/> &nbsp; <Typography fontSize={14} fontWeight={'bold'}>Click here when done </Typography>
        </Box>
      }
    </Box>
  </>)

  /* Total site user count */
  const UserCount = (
    <Typography
      sx={{ 
        mr: 5, 
        color: 'white', 
        display: 'block', 
        textAlign: 'center' , 
        textTransform: 'none', 
        fontWeight: 'normal', 
        fontSize: '15px', 
        '-moz-user-select': '-moz-none',
        '-khtml-user-select': 'none',
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
      }}
    >
      {userCount}
    </Typography>
  )

  /* Search */
  const SearchField = (<>
    {/*
      <IconButton size="large" color="inherit" onClick={handleSearchIconClick} sx={{ mr: 1 }}>
        <SearchIcon />
      </IconButton>
    */}
  </>)

  /* Chat Notification */
  const [unreadCount, setUnReadCount] = React.useState(0);
  const getUnreadCount = async () =>{
    try {
      // serviceCode = 1: Count unread messages in ALL chat (In this case)
      // serviceCode = 2: Count unread messages in SPECIFIC chat
      // serviceCode = 3: Count unread messages across ALL chats in SPECFIC category 
      const counter = await chatService.getUnreadMessageCount( {userId: user.userId , serviceCode: 1} );
      setUnReadCount(counter.data.unreadCounter);
    } catch (error) {
      throw error;
    }
  };
  React.useEffect(() => {
    getUnreadCount(); // Check for unread messages on app launch first

    const unreadCountFunctionCall = () => {
      //dispatch(incrementUnreadCount());
      getUnreadCount();
    }
    socket.on('newChat', unreadCountFunctionCall);
    socket.on("receiveMessage", unreadCountFunctionCall);
    socket.on('chatRead', unreadCountFunctionCall);

    return () => {
      socket.off('newChat', unreadCountFunctionCall);
      socket.off('receiveMessage', unreadCountFunctionCall);
      socket.off('chatRead', unreadCountFunctionCall);
    };
  },[socket]);
  const notificationClickHandle = () => {
    dispatch(setSelectedCategoryId(null));
    dispatch(setChatSessionId(null));
    dispatch(setGroupChatSessionId(null));
    dispatch(setFriendId(null));
    dispatch(setFriendUserInfo({}));
    navigate('/chat');
  }
  const Notification = (<>
    <IconButton size="large" color="inherit" sx={{ mr: 1 }} onClick={notificationClickHandle}>
      <Badge badgeContent={unreadCount} color="error"> 
        <ForumIcon />
      </Badge>
    </IconButton> 
  </>)

  /* User avatar & dropdown menu */
  const UserAvatar = (<>
    <Tooltip title="Open settings" enterDelay={400}>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
        <Avatar alt="" src={user.images.length > 0 ? user.images[0].mediaUrl : ""} sx={{ height: "35px", width: "35px", }} />
      </IconButton>
    </Tooltip>
    <Menu
      className='header-menu-profile'
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
      sx={{
        display: { xs: 'block', md: 'block' }, // `xs` for mobile display mode, `md` for PC display mode
      }}
      >
      {options.length && options.map((option, index) => {
        if (option.role === "user" || ( option.role === "admin" && user.role === "admin" )) {
          let clickFunction = handleCloseUserMenu;

          if (option.type === "button") {
            clickFunction = option.onClick;
          }

          return (
            <MenuItem key={option.link} component={Link} to={option.link} onClick={clickFunction}>
              <Typography textAlign="center">{option.icon}&nbsp; &nbsp;{option.text}</Typography>
            </MenuItem>
          )
        }}
      )}
    </Menu>
  </>)

  return (
    <AppBar 
      className='header'
      position="sticky" 
      elevation={0} 
      sx={
        { 
          zIndex: 1000, 
          bgcolor: 'var(--color-accent)',
          'overscroll-behavior': 'contain'
          //borderBottomLeftRadius: 'var(--border-radius-main)',
          //borderBottomRightRadius: 'var(--border-radius-main)'
        }
      }>
      {renderNavBar === true && 
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          {/* Logo / Home */}
          {NavLogo}

          {/* Navs*/}
          {NavLinks}

          {/* Search icon */}
          {SearchField}

          {/* Total site user count */}
          {UserCount}

          {/* Notification */}
          {Notification}

          {/* User avatar / Option dropdown menu */}
          {UserAvatar}

        </Toolbar>
      </Container>
      }
    </AppBar>
  );
}
