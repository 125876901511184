import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tabs, Tab, Container, ThemeProvider, createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import EventList from '../components/events/EventList';
import eventService from '../api/services/eventService';

import PopupSlideshow from '../components/popup/Slideshow/PopupSlideshow';
import D8sPageTutorial from '../components/tutorial/D8s/D8sPageTutorial';
import HelpButton from 'components/tutorial/HelpButton';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: grey[900], // Style for active tab text
          },
        },
      },
    },
  },
});

export default function EventsPageOld() {
  const claimedEvents = useSelector(state => state.events.claimedEvents);
  const [events, setEvents] = useState([]);
  const [tabValue, setTabValue] = useState(0); // State to track the current tab

  useEffect(() => {
    eventService.getAllEvents().then((res) => {
      setEvents(res);
    });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // First-time User Experience: Show tutorial popup for first-time users 
  const [tutorialVisible, setTutorialVisible] = useState(false);
  const tutorialPopup = (
      <PopupSlideshow 
        content={<D8sPageTutorial tutorialVisible={tutorialVisible} setTutorialVisible={setTutorialVisible}/>} 
        visible={tutorialVisible} 
        setVisible={setTutorialVisible}
        backdrop={true}
        association={"first-time-d8"}
      />
  );
  // Floating help button logic 
  const handleHelpButtonClick = () => {
    localStorage.setItem("first-time-d8", true);
    setTutorialVisible(true);
  }
  useEffect(() => {
    localStorage.getItem("first-time-d8") && setTutorialVisible(true);
  },[])

  return (
    <ThemeProvider theme={lightTheme}>
      <Box 
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
          
        }}
      >
        <Box mt={"80px"}></Box>

        <Box sx={{ borderBottom: 0, borderColor: 'divider', }} mb={2}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              '.MuiTab-textColorPrimary': { color: grey[900] },
              '.MuiTabs-indicator': { backgroundColor: grey[900] },
            }}
          >
            <Tab label="All D8s" sx={{ textTransform: 'none' }} />
            <Tab label="Claimed D8s" sx={{ textTransform: 'none' }} />
          </Tabs>
        </Box>

        <Box
          sx={{
            overflowY: 'scroll',
            width: '90vw',
            height: '78vh',
            border: '2px solid var(--color-accent)',
            borderRadius: '12px',
            mb: 2
          }}
        >
          {tabValue === 0 && (
            <EventList events={events} setEvents={setEvents} marginTop="25px" displayMode="Grid"/>
          )}
          {tabValue === 1 && (
            <EventList events={events.filter((event) => event._id in claimedEvents)} marginTop="25px" displayMode="Grid"/>
          )}
        </Box>
      </Box>

      {/* Help buttons */}
      {/*<HelpButton onClick={handleHelpButtonClick}/>*/}
      {/* First time user tutorial */}
      {tutorialPopup}
    </ThemeProvider>
  );
}
