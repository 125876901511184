// Essentials
import React, { useState, useRef, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Button,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import ChatMessage from '../chatMessage/ChatMessage';
import ChatInputBox from './ChatInputBox';
import ChatHeader from './ChatHeader';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import chatService from 'api/services/chatService';
import socket from 'socketio/socket';
import {
  deleteChat,
  updateChatCategory,
  setChatSessionId,
  setFriendId,
  setFriendUserInfo,
  setSelectedCategoryId,
} from 'app/slices/chat.slice';
import { delay } from 'scripts/delay';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

function preventScroll() {
  document.body.style.overflow = 'hidden';
}

function restoreScroll() {
  document.body.style.overflow = '';
}

function ChatWindow() {
  const mobileLayout = useSelector((state) => state.global.isMobile);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFocus = useSelector((state) => state.global.appOnFocus);

  // Visibility control states for mobile layout
  const [chatVisible, setChatVisible] = useState(true);

  // User & Chat data from Redux store
  const userId = useSelector((state) => state.user.userId);
  const friendId = useSelector((state) => state.chats.friendId);
  const chatId = useSelector((state) => state.chats.chatSessionId);
  const chatUserAssociationId = useSelector(
    (state) =>
      state.chats.chatUserAssociation[chatId] &&
      state.chats.chatUserAssociation[chatId]._id
  );
  const chatUserAssociationCategoryId = useSelector(
    (state) =>
      state.chats.chatUserAssociation[chatId] &&
      state.chats.chatUserAssociation[chatId].categoryId
  );
  const chatUserInfo = useSelector((state) => state.chats.friendUserInfo);
  const categories = useSelector((state) => state.chats.categories);

  const [chat, setChat] = useState();
  const [chatMessages, setChatMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [newMessage, setNewMessage] = useState('');
  const messageListRef = useRef(null);
  const inputRef = useRef(null);

  // Chat window layout
  const mobileMode = {
    width: '100%',
    height: '85vh',
    display: chatId !== null && chatVisible ? 'visible' : 'none',
  };
  const pcMode = {
    mt: 2,
    border: '2px solid var(--color-accent)',
    borderRadius: 'var(--border-radius-main)',
    width: '780px',
    height: '85vh',
    display: 'flex', // Ensure the chat window takes up the full available height and the message window can scroll
    flexDirection: 'column',
    '@media (max-height: 650px)': {
      height: '80vh',
    },
    '@media (max-height: 508px)': {
      height: '75vh',
    },
    overflow: 'hidden', // Prevent overflow from the entire chat window
  };
  
  // Make sure the message window is scrollable
  const chatMessageWindowStyle = {
    flex: 1, // This ensures the message window takes up remaining space
    overflowY: 'auto', // Enable scrolling for the messages
    mx: 1,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
    borderBottomRightRadius: 'var(--border-radius-main)',
    borderBottomLeftRadius: 'var(--border-radius-main)',
  };
  
  // Update the input container to stay fixed at the bottom
  const chatMessageInputStyle = {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    background: 'var(--color-main)',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    gap: 1,
  };
  

  // Adjust viewport height on resize and focus events
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    // Handle viewport changes to keep input at the bottom
    const handleFocus = () => {
      window.scrollTo(0, document.body.scrollHeight); // Ensure the input stays in view
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('focusin', handleFocus); // Adjusts view when input is focused

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('focusin', handleFocus);
    };
  }, []);

  // Initialize chats and user profiles based on chat ID
  useEffect(() => {
    Promise.all([
      chatService.getChatUserInfo(chatId),
      chatService.getChatMessages(chatId),
    ])
      .then(async ([userInfoRes, messagesRes]) => {
        dispatch(setFriendUserInfo(userInfoRes.data));
        setChat(messagesRes.data.chat);

        // Set the chatting user ID
        for (let i = 0; i < Object.keys(userInfoRes.data).length; i++) {
          if (userInfoRes.data[Object.keys(userInfoRes.data)[i]].userId !== userId) {
            dispatch(setFriendId(userInfoRes.data[Object.keys(userInfoRes.data)[i]].userId));
            break;
          }
        }

        setChatMessages([]);
        await delay(10);
        setChatMessages(messagesRes.data.messages);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [chatId]);

  // For scrolling to bottom of message window to show latest message
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [chatMessages]);

  // Real time chat updates
  useEffect(() => {
    const messageListener = (newChatMessage) => {
      if (newChatMessage.chatId === chatId) {
        setChatMessages((prevMessages) => [...prevMessages, newChatMessage]);
        if (messageListRef.current) {
          messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
      }
    };

    socket.on('receiveMessage', messageListener);

    return () => {
      socket.off('receiveMessage', messageListener);
    };
  }, [chatId, socket]);

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      const newChatMessage = {
        chatId,
        senderId: userId,
        receiverId: friendId,
        content: newMessage,
      };

      // Immediately update the chat messages state optimistically
      setChatMessages((prevMessages) => [...prevMessages, { ...newChatMessage, pending: true }]);

      // Emit the message to the server
      socket.emit('sendMessage', newChatMessage, (response) => {
        if (response.status === 'ok') {
          setChatMessages((prevMessages) =>
            prevMessages.map((msg) => (msg === newChatMessage ? { ...msg, pending: false } : msg))
          );
        } else {
          setChatMessages((prevMessages) => prevMessages.filter((msg) => msg !== newChatMessage));
          alert('Failed to send message. Please try again.');
        }
      });

      // Clear the input field and maintain focus
      setNewMessage('');
      inputRef.current.focus();
    }
  };

  // Chat delete handler
  const handleDeleteChat = () => {
    const categoryId = chatUserAssociationCategoryId;
    dispatch(setChatSessionId(null));
    chatService.removeChat({ chatId, chatUserAssociationId, categoryId })
      .then((res) => {
        const chatId = res.data.chatId;
        const categoryId = res.data.categoryId;
        dispatch(deleteChat({ chatId, categoryId }));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Accepting and applying chatId from URL parameters if any
  let urlParams = useParams();
  useEffect(() => {
    if (urlParams.chatId !== undefined) {
      dispatch(setChatSessionId(urlParams.chatId));
    }
  }, []);

  /* Empty chat */
  const emptyChatId = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  };

  const NoChat = (
    <Box
      className="chat-main-empty"
      sx={
        mobileLayout
          ? { width: '100%', height: '100%' }
          : {
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              height: '100%',
              fontSize: '100px',
              opacity: 1,
            }
      }
    >
      {!mobileLayout && <ForumRoundedIcon fontSize="inherit" />}
    </Box>
  );

  /* Chat messages */
  const ChatMessageWindow = (mesgRef, mesgs, usrInfo, uID, cID, onFocus) => {
    return (
      <Box
        className="chat-main-messages"
        sx={
          mobileLayout
            ? {
                mx: 1,
                flex: 1,
                maxHeight: '70vh',
                overflowY: 'auto', // Ensure scroll is enabled
                scrollbarWidth: 'none', // Hide the scrollbar in Firefox
                '&::-webkit-scrollbar': {
                  width: '0.5em',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent', // Hide the scrollbar thumb
                },
              }
            : {
                mx: 1,
                flex: 1,
                overflowY: 'auto', // Ensure scroll is enabled
                scrollbarWidth: 'none', // Hide the scrollbar in Firefox
                '&::-webkit-scrollbar': {
                  width: '0.5em',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent', // Hide the scrollbar thumb
                },
                borderBottomRightRadius: 'var(--border-radius-main)',
                borderBottomLeftRadius: 'var(--border-radius-main)',
              }
        }
        ref={mesgRef}
      >
        <>
          {mesgs.map((message, index) => (
            <ChatMessage key={index} userId={uID} chatUserInfo={usrInfo} message={message} />
          ))}
          <Box mb={2}></Box> {/* Add a bottom margin below last message to give space for Message Input Field */}
        </>
      </Box>
    );
  };

  /* Chat input field */
  const chatMessageInput = (
    <Box
      sx={
        mobileLayout
          ? {
              position: 'sticky',
              bottom: 0,
              width: '100%',
              background: 'var(--color-main)',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              gap: 1,
            }
          : {
              position: 'sticky',
              bottom: 0,
              background: 'var(--color-main)',
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              padding: 1,
              gap: 1,
            }
      }
    >
      <TextField
        fullWidth
        multiline
        variant="outlined"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type your message..."
        inputRef={inputRef}
        sx={{
          maxHeight: '120px', // Approx. 5 lines
          overflowY: 'auto',
          flexGrow: 1,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'black', // Set border color to black
            },
            '&:hover fieldset': {
              borderColor: 'black', // Keep border color black on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: 'black', // Keep border color black when focused
            },
          },
        }}
        inputProps={{
          style: {
            maxHeight: '120px',
            overflowY: 'auto',
          },
          onKeyDown: (e) => {
            preventScroll(); // Disable scrolling while a key is pressed
            if (!mobileLayout && e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          },
          onKeyUp: restoreScroll, // Re-enable scrolling when the key is released
        }}
      />
      <IconButton
        color="primary"
        onMouseDown={(e) => e.preventDefault()} // Prevent default to avoid losing focus
        onClick={handleSendMessage}
        sx={{
          alignSelf: 'flex-end',
          mb: 1,
          color: 'black', // Set the send icon color to black
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)', // Add a slight hover effect
          },
        }}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );

  /* Loading spinner */
  const LoadingSpinner = (
    <Box
      className="chat-main-empty"
      sx={
        mobileLayout
          ? { width: '100%', height: '100%' }
          : {
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              width: '100%',
              height: '100%',
              fontSize: '100px',
              opacity: 1,
            }
      }
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    </Box>
  );

  if (isLoading) {
    return (
      <Box className="chat-main" sx={mobileLayout ? mobileMode : pcMode}>
        {LoadingSpinner}
      </Box>
    );
  }

  /* Empty chat page */
  if (emptyChatId(chatId)) {
    return (
      <Box className="chat-main" sx={mobileLayout ? mobileMode : pcMode}>
        {NoChat}
      </Box>
    );
  }

  return (
    <Box className="chat-main" sx={mobileLayout ? mobileMode : pcMode}>
      {isLoading ? (
        LoadingSpinner
      ) : (
        <ThemeProvider theme={lightTheme}>
          <Box
            sx={{
              height: `100%`,
              width: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Chat header */}
            
            {chat && <ChatHeader chatUserInfo={chatUserInfo} />}

            {/* Main chat window */}
            {chatUserInfo && chatMessages && (
              <>
                {ChatMessageWindow(messageListRef, chatMessages, chatUserInfo, userId)}
                {chatMessageInput}
              </>
            )}
          </Box>
        </ThemeProvider>
      )}
    </Box>
  );
}

export default ChatWindow;
