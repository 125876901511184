import { isMobile } from "scripts/mobileCheck"

export const popupStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '80vh', 
    overflowY: 'auto',
    width: isMobile() ? 300 : 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
    position: 'relative',
}