import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/user.slice'
import settingsReducer from './slices/setting.slice'

import chatsReducer from './slices/chat.slice'
import groupChatReducer from './slices/groupchat.slice'

import eventsReducer from './slices/events.slice'

import globalReducer from './slices/global.slice'

export default configureStore({
  reducer: {
    user: userReducer,
    settings: settingsReducer,

    chats: chatsReducer,
    groupchats: groupChatReducer,

    events: eventsReducer,

    global: globalReducer
  },
})