import React, { useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';

import { Box, Container, Typography } from '@mui/material';


export default function EmptyPage() {
    const currentDate = new Date();

    const message = ( // Insert easter egg messages base on date here
        currentDate.getMonth() === 10 && (currentDate.getDate() === 16 || currentDate.getDate() === 19) ? 
            <>You're not supposed to be here, Mr. Freeman. <Link to="/"><span style={{color: 'var(--color-text)'}}>Home page</span></Link></>
        :
            <>Looks like this page doesn't exist. <Link to="/"><span style={{color: 'var(--color-text)'}}>Home page</span></Link></>
    )
    

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                overflowY: 'scroll',
                opacity: 0.3
            }}
        >
            <img
                src="..\..\logo\BM8.svg"
                alt="M8s Page"
                style={{ maxWidth: '70px', marginBottom: 70 }}
            />
            
            <Typography fontWeight={'bold'} fontSize={30} mb={1}>
                Oops!
            </Typography>
            <Typography fontWeight={'normal'} fontSize={20}>
                {message}
            </Typography>


        </Box>
    )
}