import { outlinedInputClasses } from '@mui/material/OutlinedInput';

export const outlinedInputOverrides = {
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: 'var(--color-accent)',
        opacity: '0.2'
      },
      root: {
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: 'var(--color-accent)',
          opacity: '0.3'
        },
        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: 'var(--color-accent)',
          opacity: '1'
        },
      },
    },
  },
};