import React from 'react';
import { Box, Typography, Button, Divider} from '@mui/material';
import { SectionContainer, SectionTitle, InfoItem, LongTextTypography, OutlineButton, FilledButton } from './StyledComponents';
import { isMobile } from '../../scripts/mobileCheck';

function BasicsSection({ basics, setEdit, self }) {
  const formatDate = (dateString) => {
    return dateString ? new Date(dateString).toDateString() : 'N/A';
  };
  
  return (
    <SectionContainer mb={isMobile() ? 2 : 3}>
      <Box sx={{ mb: 1, mr: 1 }} display="flex" justifyContent='space-between' alignItems='center' >
        <SectionTitle variant="h6">Basics</SectionTitle>
        {self && (
          <OutlineButton
            onClick={() => setEdit("Basics")} 
            
            variant="outlined" 
            size='small'
          >
            Edit
          </OutlineButton>
        )}
      </Box>
      <Divider variant='middle' sx={{ bgcolor: "#83838383", marginBottom: '20px' }}/>
      <InfoItem>
        <Typography variant="body2">Date of Birth</Typography>
        <Typography variant="body2">{formatDate(basics.dateOfBirth)}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Current City</Typography>
        <Typography variant="body2">{basics.currentCity || 'N/A'}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Gender Identity</Typography>
        <Typography variant="body2">{basics.genderIdentity || 'N/A'}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Stage of Life</Typography>
        <LongTextTypography variant="body2" array={basics.stageOfLife} / >
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Religion</Typography>
        <Typography variant="body2">{basics.religion || 'N/A'}</Typography>
      </InfoItem>
    </SectionContainer>
  );
}

export default BasicsSection
