import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CustomMultiSelect = ({ fieldConfig, values, setFieldValue, margin = 'normal', image }) => {
    const handleChange = (event) => {
        const { value } = event.target;
        setFieldValue(fieldConfig.name, value);
    };

    return (
        <FormControl fullWidth margin={margin} size="small" key={fieldConfig.name}>
            <InputLabel id={`${fieldConfig.name}-label`}>{fieldConfig.label}</InputLabel>
            <Select
                labelId={`${fieldConfig.name}-label`}
                label={fieldConfig.label}
                multiple
                value={values[fieldConfig.name] || []}
                onChange={handleChange}
                renderValue={(selected) => (
                    <div>
                        {selected.map((value) => (
                            <div key={value}>
                                {image ? <img height={175} src={value} alt={value} /> : value}
                            </div>
                        ))}
                    </div>
                )}
            >
                {fieldConfig.options.map(option => (
                    <MenuItem key={option} value={option} sx={{ whiteSpace: 'normal' }}>
                        {image ? <img height={175} src={option} alt={option} /> : option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomMultiSelect;