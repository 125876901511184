import React, { useState, useEffect } from 'react';
import { Avatar, IconButton, Box, Modal, CircularProgress } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import imageCompression from 'browser-image-compression';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  maxHeight: '80vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflowY: 'auto'
};

const modalImageStyle = {
  maxHeight: '100%',
  maxWidth: '100%',
  display: 'block', // ensures the image takes up the whole line
  objectFit: 'contain', // keeps the image's aspect ratio
  border: 0, // remove any border
  padding: 0, // remove any padding
  margin: 'auto' // centers the image if it's smaller than the container
};

function EditProfileMedia({ existingImages, newImages, setNewImages, setImagesToDelete }) {
  const [displayImages, setDisplayImages] = useState([]); // All images for display
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCompressing, setIsCompressing] = useState(false);

  const handleOpenModal = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    // Initialize display images with existing S3 URLs
    setDisplayImages(existingImages);
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setIsCompressing(true)
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1920, // (Compressed file maximum dimension)
        useWebWorker: true // (Use multi-threading for better performance)
      };

      // Compress the image file
      const compressedBlob = await imageCompression(file, options);
      setIsCompressing(false)

    // Convert the Blob into a File
      const compressedFile = new File([compressedBlob], file.name.replace(/\s/g, '_'), {
        type: compressedBlob.type,
        lastModified: Date.now(),
      });

      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const newImage = fileReader.result;
        setDisplayImages(prevImages => [...prevImages, { name: compressedFile.name.replace(/\s/g, '_'), mediaUrl: newImage }]);
        setNewImages(prev => [...prev, compressedFile]);
      };
      fileReader.readAsDataURL(compressedFile);
      e.target.value = ''
    } catch (error) {
      console.error('Error during image compression', error);
    } e.target.value = ''
  };


  const handleDeleteImage = (index) => {
    const imageToDelete = displayImages[index];

    if (!existingImages.includes(imageToDelete)) {
      setNewImages(prev => prev.filter(image => image.name !== imageToDelete.name));
    } else {
      setImagesToDelete(prev => [...prev, imageToDelete]);
    }

    // Update display images
    setDisplayImages(prev => prev.filter((_, idx) => idx !== index));
  };

  return (
    <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
      {displayImages.map((image, index) => (
        <Box key={index} position="relative" onClick={() => handleOpenModal(image)}>
          <Avatar
            src={image.mediaUrl}
            alt={`Profile Media ${index}`}
            variant='square'
            sx={{ width: '120px', height: '120px', cursor: 'pointer' }}
          />
          <IconButton
            size="small"
            sx={{ padding: 0, position: 'absolute', top: -7, right: -7, backgroundColor: 'rgba(255, 255, 255, 1)' }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteImage(index);
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      ))}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            component="img"
            sx={modalImageStyle}
            src={selectedImage && selectedImage.mediaUrl}
            alt="User picture"
          />
        </Box>
      </Modal>

      {displayImages.length < 6 && (
        <label htmlFor="profile-image-upload">
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="profile-image-upload"
            type={!isCompressing ? "file" : "none"}
            onChange={handleImageChange}
          />
          <Avatar
            variant="square"
            sx={{
              width: '120px',
              height: '120px',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            {!isCompressing ? (
              <PhotoCameraIcon fontSize="large" />
            ) : (
              <CircularProgress color="inherit" />
            )}
              
          </Avatar>
        </label>
      )}
    </Box>
  );
}

export default EditProfileMedia;
