// React and Redux essentials
import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI components
import { 
    Button, 
    ButtonBase,
    IconButton,
    Avatar,
    AvatarGroup, 
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Menu,
    MenuItem,
    Snackbar,
    SnackbarContent,
} from "@mui/material";

// MUI icons
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import CloseIcon from '@mui/icons-material/Close';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';

// Backend service call
import userService from "api/services/userService";
import groupChatService from "api/services/groupchatService";

// Redux state management
import { 
    updateGroupChatMemberDetail 
} from "app/slices/groupchat.slice";

// Custom styles
import { dialogButtonRegular } from "styles/dialogButtonStyles";
import { dialogButtonFocus } from "styles/dialogButtonStyles";

// Custom scripts
import { delay } from "scripts/delay";
import { Person } from "@mui/icons-material";

export default function GroupMemberList() {
    const dispatch = useDispatch();

    const userId = useSelector(state => state.user.userId);
    const groupChat = useSelector(state => state.groupchats.groupChatDetail);
    const groupChatMembers = useSelector(state => state.groupchats.groupChatMembers);

    const [targetedMember, setTargetedMember] = useState({});

    // Dialog visibility controls
    const [memberListDialogOpen, setMemberListDialogOpen] = useState(false);
    const handleMemberListButtonClick = () => {
        setMemberListDialogOpen(true);
    };
    const handleMemberListClose = () => {
        setMemberListDialogOpen(false);
    };

    // Member dropdown controls
    const [memberAnchorEl, setMembernchorEl] = useState(null);
    const memberDropdownOpen = Boolean(memberAnchorEl);
    const handleMemberClick = (event, targetMember) => {
        setMembernchorEl(event.currentTarget);
        setTargetedMember(targetMember);
    };
    const handleMemberClose = () => {
        setMembernchorEl(null);
    };

    const handleViewMemberProfileClick = () => {
        dispatch(updateGroupChatMemberDetail(targetedMember));
        handleMemberClose();
        handleMemberListClose();
    }

    /* Member dropdown menu */
    const memberMenuOptions = [
        {text: "View Profile", onClick: handleViewMemberProfileClick, icon: <AccountBoxRoundedIcon fontSize="inherit"/>},
        
    ]
    const SelectedMemberMenu = (
        <Menu
            id="groupchat-member-menu"
            anchorEl={memberAnchorEl}
            elevation={ 1 }
            keepMounted
            open={memberDropdownOpen}
            onClose={handleMemberClose}
            style={{ zIndex: "9999" }}
        >
            {memberMenuOptions.map(option => (
                <MenuItem onClick={option.onClick}>
                    {option.icon}&nbsp;&nbsp;{option.text}
                </MenuItem>
            ))}
            
        </Menu>
    )


    /* Member List dialog */
    const MemberListDialog = (
        <Dialog open={memberListDialogOpen} onClose={handleMemberListClose}>
            <Box sx={{ width: "450px", mt: 1}}>
                <DialogTitle sx={{ fontSize: "1.1rem", fontWeight: 'bold'}}>
                    Members ({groupChat.name})
                </DialogTitle>
                
                <List sx={{ pt: 0, width: "100%" }}>
                    {groupChatMembers && Object.keys(groupChatMembers).length  > 0 && Object.values(groupChatMembers).map((member, index) => (
                        <ListItem disableGutters key={member._id}>
                            <ListItemButton onClick={(e) => handleMemberClick(e, member)} sx={{ padding: "0 24px" }}>
                                <ListItemAvatar>
                                    <Avatar src={member.images.length > 0 ? member.images[0].mediaUrl : ""} alt={member.name} sx={{ width: 35, height: 35 }}> 
                                        <PersonIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{maxWidth: 'fit-content', mr: 2}} primary={member.name} />

                                {/* Add a star next to group admin/creator */}
                                {member.userId === groupChat.hostUserId && <StarIcon fontSize="small"/>}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <DialogActions>
                    <Button size="small" onClick={handleMemberListClose} sx={dialogButtonFocus}>
                        Back
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>

        
    )

    /* Member list button */
    const MemberListButton = (
        <ButtonBase
          sx={{
            height: 40,
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={handleMemberListButtonClick}
        > 
            <AvatarGroup 
                max={5}
                sx={{
                '& .MuiAvatar-root': { height: "25px", width: "25px", my: 1},
                }}
            >
                {groupChatMembers && Object.keys(groupChatMembers).length  > 0 && Object.values(groupChatMembers).map((member, index) => (
                <Avatar 
                    alt={member.name} 
                    src={member.images.length > 0 ? member.images[0].mediaUrl : ""} 
                    key={member._id}
                    //onClick={() => {setProfileDrawerVisible(true);}}
                >
                    <PersonIcon fontSize='inherit'/>
                </Avatar>
                ))}
                
            </AvatarGroup>
        </ButtonBase>
    )
    useEffect(() => {
    },[groupChatMembers])

    return <>

        {MemberListButton}

        {MemberListDialog}

        {SelectedMemberMenu}
    </>;
}