import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { SectionContainer, SectionTitle, InfoItem, LongTextTypography, OutlineButton, FilledButton } from './StyledComponents';
import { isMobile } from '../../scripts/mobileCheck';

function CareerSection({ career, setEdit, self }) {
    return (
      <>
        {Object.keys(career).length > 0 && (
          <SectionContainer mb={isMobile() ? 2 : 3}>
            <Box sx={{ mb: 1, mr: 1 }} display="flex" justifyContent='space-between' alignItems='center'>
              <SectionTitle variant="h6">Career</SectionTitle>
              {self && (
                <OutlineButton
                  onClick={() => setEdit("Career")} 
                  
                  variant="outlined" 
                  size='small'
                >
                  Edit
                </OutlineButton>
              )}
            </Box>
            <Divider variant='middle' sx={{ bgcolor: "#83838383", marginBottom: '20px'}}/>
              <InfoItem>
                  <Typography variant="body2">School(s)</Typography>
                  <LongTextTypography variant="body2" array={career.schools} />
              </InfoItem>
              <InfoItem>
                  <Typography variant="body2">Classes</Typography>
                  <LongTextTypography variant="body2" array={career.classes} />
              </InfoItem>
              <InfoItem>
                  <Typography variant="body2">Occupation</Typography>
                  <LongTextTypography variant="body2" array={career.occupation} />
              </InfoItem>
              <InfoItem>
                  <Typography variant="body2">Company</Typography>
                  <Typography variant="body2">{career.company.length ? career.company : 'N/A'}</Typography>
              </InfoItem>
              <InfoItem>
                  <Typography variant="body2">Income</Typography>
                  <Typography variant="body2">{career.income ? `$${career.income}` : 'N/A'}</Typography>
              </InfoItem>
          </SectionContainer>
        )}
      </>
    );
}

export default CareerSection