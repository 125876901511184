import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography, Button } from '@mui/material';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';

import { imageStyle, slideStyle, slideStyle_mobile } from '../TutorialStyles';
import { filledButtonStyle } from 'styles/buttonStyles';

import { isMobile } from '../../../scripts/mobileCheck';
import { resetSlide } from '../../popup/Slideshow/slideshowController';

import slide2_1 from './slide2-1.png'
import slide3_1 from './slide3-1.png'
import slide3_2 from './slide3-2.png'
import slide5_1 from './slide5-1.png'

// This is the final list of tutorials of the sequence
// The sequence goes as follows: UserProfileTutorial -> HomePageTutorial -> M8sPageTutorial & D8sPageTutorial & SearchPageTutorial

export default function SearchPageTutorial({tutorialVisible, setTutorialVisible}) {
    const mobile = useSelector(state => state.global.isMobile);

    const dots = Array(5).fill(null); // For dots below the slideshow, change number of dots HERE when you add/remove slides from return()

    return (
        <div className="tutorial-search slideshow-container">

            {/* Slide 1 */}
            <div className="popup-slide fade tutorial-search-1">
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={300} width={700}> 
                    <Box mb={4} fontSize={80}>
                        <PersonSearchRoundedIcon fontSize='inherit'/>
                    </Box>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 0 : 7} mb={2}>
                        This is your <b>Search page</b>, where you can find M8s of desired characteristics.
                    </Typography>

                    {/*
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7}>
                        Press &emsp; ▶ &emsp; to continue...
                    </Typography>
                    */}
                </Box>
            </div>

            {/* Slide 2 */}
            <div className="popup-slide fade tutorial-search-2">
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 1 : 8} mb={2}>
                        To begin searching, start with inputting the desired characteristics.

                    </Typography>
                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={mobile ? 1 : 8} mb={3}>
                        When you're done, click <b>Search</b>.
                    </Typography>
                    
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide2_1}
                            height={340}
                            style={imageStyle}
                        />
                       
                    </Box>
                </Box>
            </div>
            
            {/* Slide 3 */}
            <div className="popup-slide fade tutorial-search-3">
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={510} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={2}>
                        To check out a M8's profile, simply click on their card. 
                    </Typography>
                    
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        <img 
                            src={slide3_1}
                            height={300}
                            style={imageStyle}
                        />
                       
                    </Box>
                </Box>
            </div>

            {/* Slide 3b */}
            <div className="popup-slide fade tutorial-search-3b">
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700} overflowY={"scroll"}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        If you find a interested M8, you can send them a message from their profile.
                    </Typography>
                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        
                        <img 
                            src={slide3_2}
                            height={mobile ? 430 : 390}
                            style={imageStyle}
                        />
                    </Box>
                </Box>
            </div>
            
            {/* Slide 4 */}
            <div className="popup-slide fade tutorial-search-4">
                <Box className="" sx={mobile ? slideStyle_mobile : slideStyle} height={610} width={700}>

                    <Typography fontSize={15} fontWeight='normal' textAlign="center" mx={7} mb={4}>
                        <b>Good luck finding your M8s!</b>
                    </Typography>

                    <Box
                        sx={
                            mobile ?
                            { // Mobile layout
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                gap: 2
                            }
                            :
                            { // PC layout
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'nowrap',
                                gap: 2
                            }
                        }
                    >
                        
                        <img 
                            src={slide5_1}
                            height={mobile ? 150 : 200}
                            style={imageStyle}
                        />
                    </Box>


                    <Button 
                        onClick={() => 
                            {
                                setTutorialVisible(false);
                                localStorage.removeItem('first-time-search');
                                resetSlide();
                                //window.location.reload(true);
                            }
                        }
                        sx={[filledButtonStyle, {width: 180}]}
                        mt={mobile ? '50px' : ''}
                        >
                            Close Tutorial
                    </Button>
                </Box>
            </div>
    
            {/* The indicator dots below slides*/}
            <Box 
                className='popup-slide-dot-container' 
                sx={{textAlign: 'center'}}
            >
                {dots.map((_, index) => (
                    <span key={index} className="dot"></span>
                ))}
            </Box>

        </div>
    );
}

